import { parseHTML } from "~/js/utils/dom/elementManipulation";

export const volunteerCardMarkup = (dataObject, translations, pastEvent) => {
    const applyBeforeMarkup = !pastEvent
        ? `<div class="volunteer-card__status">
                        <p>
                            ${translations.applyBeforeLabel}
                            <span class="event-page__date-string local-datetime--not-initialized" data-async-module="local-datetime" data-date-format="event-date-time" data-utc-start="${dataObject.startDateUTC}" data-utc-end="${dataObject.endDateUTC}" data-date-fallback="${dataObject.dateString}" data-use-ksa="true"></span>
                        </p>
                    </div>`
        : "";

    return parseHTML(`
        <a class="event-list__card volunteer-card volunteer-theme--${
            dataObject.themeCssClass
        }" href="${dataObject.url}">
            <div class="volunteer-card__meta">
                <div class="volunteer-card__points">${dataObject.points} ${
        translations.pointsLabel
    }</div>
                <div class="volunteer-card__time">${dataObject.workHours} ${
        translations.hoursLabel
    }</div>
            </div>
            <div class="volunteer-card__visual">
                ${dataObject.svgContent}
            </div>
            <div class="volunteer-card__content">
                <p class="volunteer-card__date-string">
                    ${
                        dataObject.locationName &&
                        dataObject.locationName !== ""
                            ? `${dataObject.locationName}, `
                            : ""
                    } <time class="local-datetime--not-initialized" data-async-module="local-datetime" data-date-format="event-date-time" data-utc-start="${
        dataObject.startDateUTC
    }" data-utc-end="${dataObject.endDateUTC}" data-date-fallback="${
        dataObject.dateString
    }" data-use-ksa="true"></time>
                </p>
                <h3>${dataObject.teaserTitle}</h3>
                <p>
                    ${dataObject.teaserText}
                </p>
            </div>
            ${
                dataObject.isParticipating
                    ? `<div class="volunteer-card__status volunteer-card__status--contrast">
                        <p>${translations.memberStatusLabel}</p>
                    </div>`
                    : applyBeforeMarkup
            }
        </a>
    `);
};
