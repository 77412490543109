import { buildRadioInput } from "~/js/components/volunteer/signup/inputs/buildRadioInput";

const stepSixContentTemplate = ({ classes, sections }) => {
    const errorMessageStepSix = "step-six";

    return `
        <div class="${classes.rootClass}__section">
            <fieldset class="${classes.rootClass}__fieldset">
                <legend class="visuallyhidden">Step 6</legend>
                <div class="${classes.rootClass}__radio-group ${
        classes.rootClass
    }__radio-group--default">
                    <h3>${sections.title}</h3>
                    <ul class="${classes.rootClass}__vertical-list">
                        ${buildRadioInput(
                            sections.fields[0],
                            errorMessageStepSix
                        )}
                    </ul>
                    <p class="${errorMessageStepSix}"></p>
                </div>
            </fieldset>
        </div>
    `;
};

export default stepSixContentTemplate;
