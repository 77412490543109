import {
    appendElement,
    deleteElement,
    emptyElement,
    parseHTML,
    prependElement
} from "~/js/utils/dom/elementManipulation";
import {
    addEvent,
    delegateEvent,
    removeAllEvents
} from "~/js/utils/events/events";
import { searchResultsMarkup } from "./markup/searchResultsMarkup";
import fetcher from "~/js/api/fetcher";
import { forEach } from "~/js/utils/helpers/forEach";
import { setupInView } from "~/js/utils/dom/inView";
import LazyLoad from "vanilla-lazyload";
import {
    hasClass,
    addClass,
    removeClass,
    toggleClass
} from "~/js/utils/dom/classList";
import { SEARCH, SEARCH_CONFIGURATION } from "~/js/constants/api-end-points";
import { searchConfigurationMarkup } from "~/js/components/search/markup/searchConfigurationMarkup";
import anime from "animejs";
import { STANDARDCUBICBEZIER } from "~/js/constants/easings";
import { MEDIUM } from "~/js/constants/durations";
import { formatRFC3339 } from "date-fns";
import { isIE11 } from "~/js/utils/helpers/isIE11";
import objectFitImages from "object-fit-images";
import { createElement } from "~/js/utils/dom/createElement";
import { addLoader } from "../loader/loader";

export class Search {
    /**
     * Internal placeholder for cached DOM-objects and settings objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {};
    settings = {};

    /**
     *
     * @param {Element} domReference - The element to work from. -> lists-load-more
     */
    constructor(domReference) {
        this.dom.container = domReference;

        this.dom.input = this.dom.container.querySelector(
            ".search__input input"
        );

        this.dom.searchButton = this.dom.container.querySelector(
            "button[type=button].search__logo"
        );

        this.dom.resultsList =
            this.dom.container.querySelector(".search__results");

        this.dom.categoryWrapper = this.dom.container.querySelector(
            ".search__category-wrapper"
        );

        this.dom.categoryContent = this.dom.container.querySelector(
            ".search__category-content"
        );

        this.dom.topicWrapper = this.dom.container.querySelector(
            ".search__topic-wrapper"
        );

        this.dom.innerTopicWrapper = this.dom.container.querySelector(
            ".search__inner-topic-wrapper"
        );

        this.dom.applyFiltersButton = this.dom.container.querySelector(
            "button.button--clear"
        );

        // Creating our data objects/arrays for later use
        this.storedData = [];
        this.searchData = {
            topics: [],
            publications: [],
            dates: {
                from: "",
                to: ""
            },
            startIndex: 0,
            listLength: 20
        };

        this.settings = {
            categoryOpenClass: "search__topic-wrapper--open",
            categoryActiveClass: "search__category--active",
            topicCheckedClass: "search__topic--checked",
            isLoggedIn: hasClass(document.body, "logged-in"),
            searchInProgress: false,
            showLoader: false,
            loaderSuspense: 400
        };

        this.initialize();
    }

    /**
     * Fetching the search configuration and setting up the filters as well as storing the data.
     */
    fetchSearchConfiguration = () => {
        // Fetch the data
        fetcher(SEARCH_CONFIGURATION).then(({ data }) => {
            // Adding the data to an array for later use since we only call the API for getting the config ONCE (init).
            this.storedData = data;

            // We will load the correct markup based on the dataset set in the view. This needs to be done since it differs.
            forEach(data, (dataObj, itemType) => {
                if (itemType !== "dictionaryData") {
                    const loadedHtml = searchConfigurationMarkup(
                        dataObj,
                        itemType,
                        data.dictionaryData
                    );
                    appendElement(loadedHtml, this.dom.categoryWrapper);
                }
            });

            this.dom.categories =
                this.dom.container.querySelectorAll(".search__category");

            this.settings.loadMoreMarkup = parseHTML(
                `<button class="button button--primary search__load-more-button" type="button"><span>${this.storedData.dictionaryData.loadMore}</span></button>`
            );

            // Adding the events for every category
            this.addCategoryEvents();

            // Adding the events for every topic inside every category
            this.delegateTopicEvents();

            // Delegating the events for every date preset
            this.delegateDateEvents();
        });
    };

    /**
     * Fetching the search data and handles all the magic happening inside the list.
     */
    fetchSearchData = (fetchingMoreResults = false) => {
        // Resetting the startIndex to be ready for a fresh result if we have updated the value from an earlier result
        if (!fetchingMoreResults) {
            this.searchData.startIndex = 0;
        }

        // Setting the config object
        const searchConfig = {
            Publications: this.searchData.publications, // optional
            Topics: this.searchData.topics, // optional
            From: this.searchData.dates.from, // optional
            To: this.searchData.dates.to, // optional
            Query: this.dom.input.value,
            startIndex: this.searchData.startIndex,
            listLength: this.searchData.listLength
        };

        // Handling the loader suspense
        this.settings.searchInProgress = true;

        setTimeout(() => {
            if (this.settings.searchInProgress) {
                this.settings.showLoader = true;

                // Disabling button until our result is ready
                if (this.dom.loadMoreButton) {
                    this.dom.loadMoreButton.disabled = true;
                }

                // Showing our loader since the threshold from the suspense is not reached
                this.addLoader(this.dom.resultsList);
            }
        }, this.settings.loaderSuspense);

        // Fetch the data
        fetcher(SEARCH, "POST", searchConfig).then(({ data }) => {
            this.settings.searchInProgress = false;

            if (this.settings.showLoader) {
                this.settings.showLoader = false;

                this.removeLoader(this.dom.resultsList);
            }

            // Saving the total results for later use as well as parsing it as integer
            let totalResults = parseInt(data.totalNumber);

            // Initial check to see if we need to print the load more button
            if (
                totalResults > this.searchData.listLength &&
                !fetchingMoreResults
            ) {
                appendElement(this.settings.loadMoreMarkup, this.dom.container);

                this.dom.loadMoreButton = this.dom.container.querySelector(
                    "button.search__load-more-button"
                );

                this.addLoadMoreEvents();

                this.searchData.startIndex += this.searchData.listLength;
            } else {
                if (this.dom.loadMoreButton) {
                    this.killLoadMore();
                }
            }

            // If we are actually using the fetch through the load more button we need to differentiate from a fresh result
            if (fetchingMoreResults) {
                if (this.dom.loadMoreButton) {
                    // Enabling the button since we have data
                    this.dom.loadMoreButton.disabled = false;
                }

                // Incrementing the stored startIndex with our listLength for every push. For obvious reasons.
                totalResults >
                this.searchData.startIndex + this.searchData.listLength
                    ? (this.searchData.startIndex += this.searchData.listLength)
                    : (this.searchData.startIndex = totalResults);

                // Check if we have more results to add from the total. If not, hide the button and remove events.
                if (totalResults === this.searchData.startIndex) {
                    this.killLoadMore();
                }
            } else {
                // Lets empty the element to make room for the newly fetched results
                emptyElement(this.dom.resultsList);

                prependElement(
                    parseHTML(
                        `<span class="search__result-count">${totalResults} ${this.storedData.dictionaryData.results}</span>`
                    ),
                    this.dom.resultsList
                );

                // This right here will update the count on every topic according to our search results
                this.updateFilteringTopicCount(data);

                const openCategory =
                    this.dom.container.querySelector(
                        `.search__category.${this.settings.categoryOpenClass}`
                    ) || false;

                if (openCategory) {
                    this.toggleCategories(openCategory);
                }
            }

            // We will load the correct markup based on the dataset set in the view. This needs to be done since it differs.
            forEach(data.searchResultElements, dataObj => {
                const loadedHtml = searchResultsMarkup(
                    dataObj,
                    this.settings.isLoggedIn
                );
                appendElement(loadedHtml, this.dom.resultsList);
            });

            setTimeout(() => {
                // Initiating the lazyload and inview within our scope after we appended the data
                const inViewClass = ".inview";
                const inViewElements =
                    this.dom.resultsList.querySelector(inViewClass);

                if (inViewElements) {
                    setupInView(inViewClass, "inview--active", "show", 0);
                }

                // eslint-disable-next-line no-unused-vars
                let lazyloadInstance = undefined;
                const lazyClass = ".lazy";
                const lazyloadConfig = {
                    elements_selector: lazyClass,
                    class_loading: "lz-loading",
                    class_loaded: "lz-loaded"
                };

                // Check if there is any images to lazyLoad before init lazyload
                const lazyElements =
                    this.dom.resultsList.querySelector(lazyClass);
                if (lazyElements) {
                    // eslint-disable-next-line no-unused-vars
                    lazyloadInstance = new LazyLoad(lazyloadConfig);
                }

                if (isIE11) {
                    const articleTeaserImages = document.querySelectorAll(
                        ".article-teaser img"
                    );

                    // Article teaser images needs to have their src set in IE11
                    forEach(articleTeaserImages, image => {
                        const srcAttr = image.getAttribute("src");
                        const dataSrcAttr = image.getAttribute("data-src");
                        if (!srcAttr && dataSrcAttr) {
                            image.setAttribute("src", dataSrcAttr);
                        }
                    });

                    setTimeout(() => {
                        // For supporting IE 11 with object-fit: cover!
                        objectFitImages(articleTeaserImages);
                    }, 100);
                }
            }, 100); // We have to set the timeout since our inview util misses elements otherwise
        });
    };

    /**
     * Lets kill them events!
     */
    killLoadMore() {
        // Removing the load more event from the (hopefully) visibly hidden button
        removeAllEvents(this.dom.loadMoreButton);
        deleteElement(this.dom.loadMoreButton);
        this.dom.loadMoreButton = false;
    }

    /**
     * Updating our topics under each category according to the itemData passed
     *
     * @param {object} itemData - Takes the object fetched when calling the search API
     */
    updateFilteringTopicCount = itemData => {
        // A loop inside a loop is ugly, no denying that. It is very limited (MAX 2 FOR THE LOOP INSIDE!)
        // and we take no perfomance hit so everything is jolly good.
        forEach(itemData.publications, publicationItem => {
            const topicCountHolders = this.dom.container.querySelectorAll(
                `[data-item-id="${publicationItem.itemID}"] .search__topic-count`
            );

            forEach(topicCountHolders, topicCountHolder => {
                topicCountHolder.innerHTML = `(${publicationItem.resultCount})`;
            });
        });

        forEach(itemData.topics, topicItem => {
            const topicCountHolders = this.dom.container.querySelectorAll(
                `[data-item-id="${topicItem.itemID}"] .search__topic-count`
            );

            forEach(topicCountHolders, topicCountHolder => {
                topicCountHolder.innerHTML = `(${topicItem.resultCount})`;
            });
        });
    };

    /**
     * Toggles categories and sets the settings accordingly
     * Also adds the animation for opening, closing and switching the data when open
     *
     * @param target - Target passed by the clicked event.target
     */
    toggleCategories = target => {
        // Cleaning up before adding new markup.
        emptyElement(this.dom.categoryContent);

        // Toggling the open class on our target as well as the container wrapping the actual list.
        if (!hasClass(target, this.settings.categoryOpenClass)) {
            removeClass(this.dom.categories, this.settings.categoryOpenClass);
            addClass(
                [this.dom.topicWrapper, target],
                this.settings.categoryOpenClass
            );
        } else {
            anime({
                targets: this.dom.innerTopicWrapper,
                easing: STANDARDCUBICBEZIER,
                duration: MEDIUM,
                height: 0
            });

            removeClass(
                [this.dom.topicWrapper, target],
                this.settings.categoryOpenClass
            );
        }

        // If the previous condition tells us that we are indeed opening a new category and therefore need the correct markup
        if (hasClass(this.dom.topicWrapper, this.settings.categoryOpenClass)) {
            appendElement(
                parseHTML(
                    target.querySelector(".search__category-content-holder")
                        .innerHTML
                ),
                this.dom.categoryContent
            );

            // Measuring height sucks and we need to measure on the height of the actual topics AND the button
            const categoryContentHeight = this.dom.categoryContent.offsetHeight;
            const applyButtonHeight = this.dom.applyFiltersButton.offsetHeight;
            const contentHeight = categoryContentHeight + applyButtonHeight;

            anime
                .timeline({
                    easing: STANDARDCUBICBEZIER,
                    duration: 200
                })
                .add({
                    targets: this.dom.innerTopicWrapper,
                    height: contentHeight
                })
                .add({
                    targets: this.dom.categoryContent.querySelectorAll(
                        ".search__topic, .search__date-preset"
                    ),
                    opacity: [0, 1],
                    duration: 100,
                    delay: anime.stagger(50)
                })
                .add(
                    {
                        targets: this.dom.applyFiltersButton,
                        opacity: [0, 1]
                    },
                    200 // The absolute offset gives the best feel in my opinion.
                );
        }
    };

    handleDatePresetData = target => {
        const fromDate = target.dataset.timeFrom;
        const toDate = target.dataset.timeTo;
        const openCategoryNode = this.dom.container.querySelector(
            `.${this.settings.categoryOpenClass}[data-item-list]`
        );
        const targetCloneNode = openCategoryNode.querySelector(
            `[data-time-from="${fromDate}"]`
        );

        // Since we empty the element every time we load the topics from any category we need to add the checked class
        // to both the target AND its clone node we append. Handling of state, kinda.
        removeClass(
            this.dom.container.querySelectorAll(".search__date-preset"),
            this.settings.topicCheckedClass
        );
        addClass([target, targetCloneNode], this.settings.topicCheckedClass);

        if (fromDate && toDate) {
            this.searchData.dates.from = formatRFC3339(parseInt(fromDate));
            this.searchData.dates.to = formatRFC3339(parseInt(toDate));

            return;
        }

        this.searchData.dates.from = "";
        this.searchData.dates.to = "";
    };

    /**
     * Updates the data objects set initially and also handles the classes for active/clicked filters
     * Lastly this function adds/subtracts from the "total" active topics on any given category
     *
     * @param target - Target passed by the clicked event.target
     */
    handleTopicData = target => {
        const itemId = target.dataset.itemId;
        const openCategoryNode = this.dom.container.querySelector(
            `.${this.settings.categoryOpenClass}[data-item-list]`
        );
        const targetCloneNode = openCategoryNode.querySelector(
            `[data-item-id="${itemId}"]`
        );
        const openCategoryNodeCount = openCategoryNode.querySelector(
            ".search__category-count"
        );
        const itemList = openCategoryNode.dataset.itemList;
        let activeTopicCount = parseInt(openCategoryNodeCount.innerHTML) || 0;

        // Since we empty the element every time we load the topics from any category we need to add the checked class
        // to both the target AND its clone node we append. Handling of state, kinda.
        toggleClass(
            [target, targetCloneNode],
            this.settings.topicCheckedClass,
            !hasClass(target, this.settings.topicCheckedClass)
        );

        if (itemList === "publicationData") {
            if (this.searchData.publications.includes(itemId)) {
                // Removing publications from our searchData array
                this.searchData.publications.splice(
                    this.searchData.publications.indexOf(itemId),
                    1
                );

                // Subtracting on the category count from our count of active topics since we removed one
                activeTopicCount--;
            } else {
                // Adding publications to our searchData array
                this.searchData.publications.push(itemId);

                // Adding on the category count from our count of active topics since we removed one
                activeTopicCount++;
            }
        } else if (itemList === "topicData") {
            if (this.searchData.topics.includes(itemId)) {
                // Removing topics from our searchData array
                this.searchData.topics.splice(
                    this.searchData.topics.indexOf(itemId),
                    1
                );

                // Subtracting on the category count from our count of active topics since we removed one
                activeTopicCount--;
            } else {
                // Adding topics to our searchData array
                this.searchData.topics.push(itemId);

                // Adding on the category count from our count of active topics since we removed one
                activeTopicCount++;
            }
        }

        // Toggling an active class to show if we have any active elements
        toggleClass(
            openCategoryNode,
            this.settings.categoryActiveClass,
            activeTopicCount > 0
        );

        openCategoryNodeCount.innerHTML =
            activeTopicCount > 0 ? activeTopicCount : "";
    };

    addLoader = parentAppendingTo => {
        const wrapper = createElement("div", {
            className: "search__result-loader"
        });

        addLoader(parentAppendingTo, {
            wrapperElement: wrapper
        });
    };

    // eslint-disable-next-line no-unused-vars
    removeLoader = parentRemovingFrom => {
        this.dom.loaderElement = this.dom.container.querySelector(
            ".search__result-loader"
        );
        deleteElement(this.dom.loaderElement);
    };

    /**
     * Delegating the click event for our topics since we add/remove the correct topics according to clicked category
     */
    delegateTopicEvents = () => {
        delegateEvent(".search__topic", "click", event => {
            this.handleTopicData(event.target);
        });
    };

    /**
     * Delegating the click event for our date presets since we add/remove them according to clicked category
     */
    delegateDateEvents = () => {
        delegateEvent(
            ".search__category-content .search__date-preset",
            "click",
            event => {
                this.handleDatePresetData(event.target);
            }
        );
    };

    /**
     * Adding the click event for our categories
     */
    addCategoryEvents = () => {
        addEvent(this.dom.categories, "click", event => {
            this.toggleCategories(event.target);
        });
    };

    /**
     * Adding the click event for our load more button when needed
     */
    addLoadMoreEvents = () => {
        addEvent(this.dom.loadMoreButton, "click", () => {
            if (this.dom.input.value.length >= 1) {
                this.fetchSearchData(true);
            }
        });
    };

    /**
     * Setting up initial events for our input, icon/button and apply filters button
     */
    addEvents = () => {
        addEvent(
            [this.dom.searchButton, this.dom.applyFiltersButton],
            "click",
            () => {
                if (this.dom.input.value.length >= 1) {
                    this.fetchSearchData();
                }
            }
        );
        addEvent(this.dom.input, "keydown", event => {
            //checks if the pressed key is "Enter"
            if (event.keyCode === 13 && this.dom.input.value.length >= 1) {
                this.fetchSearchData();
            }
        });
    };

    initialize() {
        // Adding the actual click event for the button
        this.addEvents();

        // On initializing the class we need to get the configuration for the search (filters and such)
        this.fetchSearchConfiguration();
    }
}
