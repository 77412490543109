import { onReady } from "~/js/utils/events/onReady";
import { utcToZonedTime } from "date-fns-tz";
import { format, parseISO, differenceInHours } from "date-fns";
import {
    DATETIMEFORMAT,
    DATEFORMAT,
    TIMEFORMAT,
    EVENTDATETIMEFORMAT
} from "../../constants/date-format";
import { getLocalTimeZone } from "~/js/utils/helpers/getLocalTimeZone";
import { isRtl } from "~/js/utils/helpers/isRtl";
import { arSA } from "date-fns/locale";
import { isIE11 } from "~/js/utils/helpers/isIE11";

export class LocalDatetime {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;

        this.dom.datePlaceholder =
            this.dom.container.querySelector(".local-datetime-placeholder") ||
            false;

        this.data = {
            dateFormat: this.dom.container.dataset.dateFormat,
            utcStart: this.dom.container.dataset.utcStart,
            utcEnd: this.dom.container.dataset.utcEnd,
            fallbackDateString: this.dom.container.dataset.dateFallback,
            useKSA: this.dom.container.dataset.useKsa
        };

        onReady(() => this.initialize());
    }

    getTimeInterval = () => {
        return (
            differenceInHours(
                parseISO(this.data.utcEnd),
                parseISO(this.data.utcStart)
            ) <= 23
        );
    };

    setStartDateString = () => {
        const startDate = format(
            utcToZonedTime(
                parseISO(this.data.utcStart),
                this.settings.localTimeZone
            ),
            this.settings.dateFormat,
            this.settings.formatOptions
        );

        const dateString = `${startDate}`;

        if (this.dom.datePlaceholder) {
            this.dom.datePlaceholder.innerText = dateString;
        } else {
            this.dom.container.innerText = dateString;
        }
    };

    setStartEndDateString = () => {
        const endDateFormat = this.settings.sameDay
            ? TIMEFORMAT
            : this.settings.dateFormat;

        const startDate = format(
            utcToZonedTime(
                parseISO(this.data.utcStart),
                this.settings.localTimeZone
            ),
            this.settings.dateFormat,
            this.settings.formatOptions
        );

        const endDate = format(
            utcToZonedTime(
                parseISO(this.data.utcEnd),
                this.settings.localTimeZone
            ),
            endDateFormat,
            this.settings.formatOptions
        );

        const dateString = `${startDate} - ${endDate}`;

        if (this.dom.datePlaceholder) {
            this.dom.datePlaceholder.innerText = dateString;
        } else {
            this.dom.container.innerText = dateString;
        }
    };

    setFallbackDateString = () => {
        if (this.dom.datePlaceholder) {
            this.dom.datePlaceholder.innerText = this.data.fallbackDateString;
        } else {
            this.dom.container.innerText = this.data.fallbackDateString;
        }
    };

    setDateFormat = () => {
        switch (this.data.dateFormat) {
            case "event-date-time":
                return EVENTDATETIMEFORMAT;
            case "date":
                return DATEFORMAT;
            case "date-time":
                return DATETIMEFORMAT;
            default:
                return DATETIMEFORMAT;
        }
    };

    initialize() {
        this.settings = {
            localTimeZone: getLocalTimeZone,
            dateFormat: this.setDateFormat(),
            sameDay: undefined,
            formatOptions: isRtl ? { locale: arSA } : ""
        };

        if (this.data.utcEnd && this.data.utcEnd.length >= 2) {
            this.settings.sameDay = this.getTimeInterval();
        }

        if (
            this.settings.localTimeZone === "Etc/GMT-3" &&
            this.data.useKSA &&
            this.data.useKSA.length >= 1
        ) {
            this.settings.localTimeZone = "Asia/Riyadh";
        }

        if (
            isIE11 &&
            this.data.fallbackDateString &&
            this.data.fallbackDateString.length >= 1
        ) {
            this.setFallbackDateString();
        } else if (!this.data.utcEnd) {
            this.setStartDateString();
        } else {
            if (
                this.data.utcStart &&
                this.data.utcStart.length >= 2 &&
                this.data.utcEnd &&
                this.data.utcEnd.length >= 2
            ) {
                this.setStartEndDateString();
            }
        }
    }
}
