/*global OneTrust */
import "../scss/main.scss";
import "focus-visible";
import {
    bootstrapper,
    killInstancesFromObject,
    bootstrapConstructorOnElement,
    bootstrappedInstances,
} from "~/js/utils/bootstrapper";
import { isExperienceEditor } from "~/js/utils/helpers/isExperienceEditor";
import barba from "@barba/core";
import anime from "animejs";
import { changeDirection } from "~/js/utils/helpers/changeDirection";
import initSwitcher from "favicon-switcher";
import { VERYSLOW } from "~/js/constants/durations";
import LazyLoad from "vanilla-lazyload";
import { onReady } from "~/js/utils/events/onReady";
import { forEach } from "./utils/helpers/forEach";
import { setupInView, updateInViewElements } from "~/js/utils/dom/inView";
import { Feedback } from "./components/feedback/feedback";
import { Carousel } from "./components/carousel";
import { RelatedArticles } from "./components/related-articles";
import {
    Membership,
    customMembershipTriggers,
} from "./components/membership/membership";
import { CountDown } from "./components/countdown/countdown";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header/header";
import { YoutubeSlider } from "./components/youtube-embed/youtube-slider";
import { TopStorySlider } from "./components/top-story-slider/top-story-slider";
import { ListsLoadMore } from "~/js/components/lists-load-more/lists-load-more";
import { ArticleTeaserList } from "./components/article-teaser-list/article-teaser-list";
import { SearchModal } from "./components/search-modal/search-modal";
import { SocialBar } from "./components/social-bar/social-bar";
import { Poll } from "./components/poll/poll";
import { ImageZoom } from "./components/image-zoom/image-zoom";
import { InTheNews } from "./components/in-the-news/in-the-news";
import { VideoEpisodesUpcoming } from "~/js/components/video-episodes-upcoming/video-episodes-upcoming";
import { currentState, disableScrollLock } from "~/js/utils/dom/scrollLock";
import { LocalDatetime } from "~/js/components/local-datetime/local-datetime";
import { PollList } from "./components/poll-list/poll-list";
import { PollQuizList } from "./components/poll-list/poll-quiz-list";
import { SignupRibbon } from "./components/signup-ribbon/signup-ribbon";
import { OnetrustToggle } from "./components/onetrust-toggle/onetrust-toggle";
import { HideStreams } from "./components/hide-streams/hide-streams";
import { SocialWall } from "./components/social-wall/social-wall";
import { EventListRibbon } from "./components/volunteer/event-list-ribbon/event-list-ribbon";
import { OwnerEventTabs } from "./components/volunteer/owner-event-tabs/owner-event-tabs";
import { ForcePageChange } from "./components/force-page-change/force-page-change";
import { VolunteerSignup } from "./components/volunteer/signup/volunteer-signup";
import { Survey } from "./components/volunteer/survey/survey";
import { RestrictContent } from "./components/restrict-content/restrict-content";
import { CertificateList } from "./components/volunteer/certificate/certificate-list";
import { CertificateDownload } from "./components/volunteer/certificate/certificate-download";
import { HeaderAvatar } from "./components/header-avatar/header-avatar";
import { Greeting } from "./components/greeting/greeting";
import { PdfViewer } from "~/js/components/pdf-viewer/pdf-viewer";
import { PressReleaseList } from "~/js/components/press-release-list/press-release-list";
import { ShortcutNavigation } from "~/js/components/shortcut-navigation/shortcut-navigation";
import { TabNavigationIframe } from "~/js/components/tab-navigation-iframe/tab-navigation-iframe";
import { AiChat } from "~/js/components/ai-chat/ai-chat";
import { setupParallaxElement } from "./components/parallax/parallax";
import { PhotoCompetition } from "./components/photo-competition/photo-competition";
import { PhotoCompetitionLeaderboard } from "./components/photo-competition/photo-competition-leaderboard";
import { AudioPlayer } from "./components/audioplayer/audioplayer";

const modules = {
    carousel: Carousel,
    feedback: Feedback,
    poll: Poll,
    header: Header,
    "article-teaser-list": ArticleTeaserList,
    "related-articles": RelatedArticles,
    "youtube-slider": YoutubeSlider,
    "top-story-slider": TopStorySlider,
    "lists-load-more": ListsLoadMore,
    "search-modal": SearchModal,
    "social-bar": SocialBar,
    "image-zoom": ImageZoom,
    "in-the-news": InTheNews,
    "signup-ribbon": SignupRibbon,
    "onetrust-toggle": OnetrustToggle,
    "hide-streams": HideStreams,
    countdown: CountDown,
    "video-episodes-upcoming": VideoEpisodesUpcoming,
    "local-datetime": LocalDatetime,
    "poll-list": PollList,
    "poll-quiz-list": PollQuizList,
    "social-wall": SocialWall,
    "event-list-ribbon": EventListRibbon,
    "owner-event-tabs": OwnerEventTabs,
    "force-page-change": ForcePageChange,
    "volunteer-signup": VolunteerSignup,
    survey: Survey,
    "restrict-content": RestrictContent,
    "certificate-list": CertificateList,
    "certificate-download": CertificateDownload,
    "header-avatar": HeaderAvatar,
    "shortcut-navigation": ShortcutNavigation,
    "tab-navigation-iframe": TabNavigationIframe,
    greeting: Greeting,
    "pdf-viewer": PdfViewer,
    "press-release-list": PressReleaseList,
    "ai-chat": AiChat,
    "photo-competition": PhotoCompetition,
    "photo-competition-leaderboard": PhotoCompetitionLeaderboard,
    audioplayer: AudioPlayer,
};

function initModules(container) {
    bootstrapper(modules, undefined, container);

    // Only init membership module if not closed
    const membershipElement = document.querySelector(
        "[data-module='membership']"
    );

    if (membershipElement) {
        const shouldShowSignUp = customMembershipTriggers.some(
            (trigger) => membershipElement.getAttribute(trigger) === "true"
        );

        if (shouldShowSignUp) {
            if (!isExperienceEditor) {
                bootstrapConstructorOnElement(
                    membershipElement,
                    Membership,
                    "membership"
                );
            }
        } else {
            membershipElement.style.display = "none";
        }
    }
}

initModules(document);

// Navigation has to wait for everything to load before being launched
function initNavigation() {
    const navigationElement = document.querySelector(
        "[data-module='navigation']"
    );

    if (navigationElement) {
        bootstrapConstructorOnElement(
            navigationElement,
            Navigation,
            "navigation"
        );
    }
}

// Since the container is NOT present inside the main barba container we need magic.
function initRestrictContent() {
    const restrictContentElement = document.querySelector(
        "[data-module='restrict-content']"
    );

    bootstrapConstructorOnElement(
        restrictContentElement,
        RestrictContent,
        "restrict-content"
    );
}

//****** Lazyload  ********/

let lazyloadInstance = undefined;
const lazyClass = ".lazy";
const lazyloadConfig = {
    elements_selector: lazyClass,
    class_loading: "lz-loading",
    class_loaded: "lz-loaded",
};

//****** inView  ********/
const inViewClass = ".inview";

//****** Parallax  ********/
const parallaxClass = ".parallax";

function reloadOTBanner() {
    var otConsentSdk = document.getElementById("onetrust-consent-sdk");
    if (otConsentSdk) {
        otConsentSdk.remove();
    }

    if (window.OneTrust != null) {
        OneTrust.Init();

        setTimeout(function () {
            OneTrust.LoadBanner();

            var toggleDisplay = document.getElementsByClassName(
                "ot-sdk-show-settings"
            );

            for (var i = 0; i < toggleDisplay.length; i++) {
                toggleDisplay[i].onclick = function (event) {
                    event.stopImmediatePropagation();
                    window.OneTrust.ToggleInfoDisplay();
                };
            }
        }, 1000);
    }
}

//****** Barba.js  ********/
// We have to be careful when adding JS classes to the DOM since barba is used to replace the main container
// and leave the rest.
// When we initialize barba after changing the page with barba we also kill all the class instances in our
// bootstrapper object. This means that we will kill classes even outside our main container used by barba.
// This means in practice that modals and such should be placed carefully and tested when changing pages as well!
function setupBarba() {
    const barbaWrapper = document.querySelector("#main-container");

    if (barbaWrapper && !isExperienceEditor) {
        const pageTransitionElement =
            document.querySelector(".page-transition");
        const easingIn = "easeInOutCubic";
        const easingOut = "easeInOutCubic";
        // https://easings.net/

        barba.hooks.before(() => {
            document.body.classList.add("is-animating");
        });

        // After leave transition/view (next container is DOM ready)
        barba.hooks.afterLeave(() => {
            // Destroy lazyinstance - unsetting instance variables and removing listeners.
            lazyloadInstance.destroy();
        });

        barba.hooks.beforeEnter((data) => {
            // Especially on articles we have a problem with the afterLeave hook not getting initiated. This is the precaution.
            // I moved the scrollTo from the afterLeave hook to here
            window.scrollTo(0, 0);

            const parser = new DOMParser();
            const newlyFetchedHtml = parser.parseFromString(
                data.next.html,
                "text/html"
            );
            const updatedCarets =
                newlyFetchedHtml.querySelectorAll(".nav__caret");

            const updatedSubParents =
                newlyFetchedHtml.querySelectorAll(".nav__sub-parent");

            const existingCarets = document.querySelectorAll(".nav__caret");

            const existingSubParents =
                document.querySelectorAll(".nav__sub-parent");

            forEach(existingCarets, (existingCaret, index) => {
                const newClassSet = updatedCarets[index].className;

                existingCaret.setAttribute("class", newClassSet);
            });

            forEach(existingSubParents, (existingSubParent, index) => {
                const newClassSet = updatedSubParents[index].className;

                existingSubParent.setAttribute("class", newClassSet);
            });
        });

        // Next page enter transition
        barba.hooks.enter(() => {
            // Look for new lazyload elements after page change
            const lazyElements = document.querySelector(lazyClass);
            if (lazyElements) {
                lazyloadInstance = new LazyLoad(lazyloadConfig);
            }
        });

        // Next page after enter transition
        barba.hooks.afterEnter(() => {
            // Look for new inview elements after page change
            const inViewElements = document.body.querySelector(inViewClass);
            if (inViewElements) {
                updateInViewElements(inViewClass, "inview--active", "show", 0);
            }
        });

        // After everything
        barba.hooks.after(() => {
            document.body.classList.remove("is-animating");
            initNavigation();

            // If we have scrollLock from navigating through search we remove it.
            if (currentState) {
                disableScrollLock();
            }

            const lazyElements = document.querySelector(lazyClass);
            if (lazyElements) {
                lazyloadInstance = new LazyLoad(lazyloadConfig);
            }

            if (
                window.dataLayer !== undefined &&
                window.google_tag_manager !== undefined
            ) {
                window.dataLayer.length = 0;
                const gtmContainer = Object.keys(
                    window.google_tag_manager
                ).filter(function (v) {
                    return v.includes("GTM-");
                })[0];

                var gtmContainerReg = new RegExp(gtmContainer, "i");

                for (const gtmKey of Object.keys(window.google_tag_manager)) {
                    if (
                        gtmContainerReg.test(gtmKey) &&
                        window.google_tag_manager[gtmKey].dataLayer &&
                        window.google_tag_manager[gtmKey].dataLayer.reset
                    ) {
                        window.google_tag_manager[gtmKey].dataLayer.reset();
                        window.dataLayer.push({
                            isApp: false,
                        });

                        reloadOTBanner();
                    }
                }

                const parallaxElement =
                    document.body.querySelector(parallaxClass);
                setupParallaxElement(parallaxElement);
            }

            // If we hit an article or episode article then add metadata to the Google dataLayer
            // If not, flush the dataLayer so that data dosn't persist
            const isArticle = document.querySelector(".article-content");
            const episodeArticle = document.querySelector(
                ".main.episode-article"
            );
            const article = document.querySelector(".main.article");
            const eventArticle = document.querySelector(
                ".main.volunteer-event"
            );

            if (isArticle) {
                if (episodeArticle && !article && !eventArticle) {
                    // Get object with episode article metadata from script tag on the episode article
                    const scriptTag =
                        episodeArticle.querySelector("#objectForBarba");
                    const json = scriptTag
                        ? JSON.parse(scriptTag.textContent)
                        : null;
                    if (json) {
                        // Add episodeArticle dataLayer
                        window.dataLayer.push({
                            event: "videoView",
                            videoShow: json.videoShow,
                            videoTitle: json.videoTitle,
                            videoImageUrl: json.videoImageUrl,
                            videoUrl: json.videoUrl,
                            videoDate: json.videoDate,
                            videoId: json.videoId,
                        });
                    }
                }

                if (article && !episodeArticle && !eventArticle) {
                    // Get object with article metadata from script tag on the article
                    const scriptTag = article.querySelector("#objectForBarba");
                    const json = scriptTag
                        ? JSON.parse(scriptTag.textContent)
                        : null;
                    if (json) {
                        // Add article dataLayer
                        window.dataLayer.push({
                            event: "articleView",
                            articleTopics: json.articleTopics,
                            articlePublication: json.articlePublication,
                            articleTitle: json.articleTitle,
                            articleImageUrl: json.articleImageUrl,
                            articleVideoUrl: json.articleVideoUrl,
                            articleAuthor: json.articleAuthor,
                            articleDate: json.articleDate,
                            articleId: json.articleId,
                        });
                    }
                }

                if (eventArticle && !episodeArticle && !article) {
                    // Get object with event article metadata from script tag on the event article
                    const scriptTag =
                        eventArticle.querySelector("#objectForBarba");
                    const json = scriptTag
                        ? JSON.parse(scriptTag.textContent)
                        : null;
                    if (json) {
                        // Add event dataLayer
                        window.dataLayer.push({
                            event: "eventView",
                            eventTitle: json.eventTitle,
                            eventImageUrl: json.eventImageUrl,
                            eventDate: json.eventDate,
                            eventHours: json.eventHours,
                            eventPoints: json.eventPoints,
                            eventHasApplied: json.eventHasApplied,
                            eventId: json.eventId,
                        });
                    }
                }
            }

            window.dataLayer.push({
                event: "PageFullyLoaded",
            });

            // Trigger the list of cookie categories from One Trust viewing the /cookie page.
            // The list is injected by the One Trust script on page reload, but not when Barba controls the page.
            // One Trust searches for an element with the ID 'ot-sdk-cookie-policy'
            // The element is inserted in Rich Text in Sitecore
            if (typeof OneTrust !== "undefined") {
                const OneTrustCookiePolicyHtml = document.getElementById(
                    "ot-sdk-cookie-policy"
                );
                if (OneTrustCookiePolicyHtml) {
                    OneTrust.initializeCookiePolicyHtml();
                }
            }
        });

        barba.init({
            cacheIgnore: true, // IGNORING THE BUILT IN CACHE FROM BARBA TO ALWAYS ASK THE SERVER FOR NEW DATA!
            prefetchIgnore: true,
            transitions: [
                {
                    sync: false,
                    leave(element) {
                        const { current } = element;

                        const targets = pageTransitionElement; // target current page

                        const a = anime({
                            targets,
                            translateX: ["-100%", "0%"],
                            duration: VERYSLOW, //time in ms
                            easing: easingIn, // easing function
                            complete: () => {
                                // Close navigation when changing page
                                if (
                                    bootstrappedInstances["navigation"].isActive
                                ) {
                                    bootstrappedInstances[
                                        "navigation"
                                    ].closeNavigation(true);
                                }
                                current.container.style.display = "none";

                                // Kill all modules when changing page
                                killInstancesFromObject();
                            },
                        });

                        return a.finished;
                    },
                    enter(element) {
                        const { next } = element;
                        const targets = pageTransitionElement; // target the container of the next page

                        // If change language in footer
                        if (
                            element.trigger.classList?.contains(
                                "language-item-footer"
                            )
                        ) {
                            changeDirection();
                            window.location.reload();
                        }

                        setTimeout(() => {
                            // Reinit all modules after page changes - half the duration of the page transition
                            initModules(next.container);

                            initRestrictContent();
                        }, VERYSLOW / 2);

                        const a = anime({
                            targets,
                            translateX: ["0%", "100%"],
                            duration: VERYSLOW, // time in ms
                            easing: easingOut, // easing function
                        });

                        return a.finished;
                    },
                },
            ],
        });
    }
}

onReady(() => {
    const inViewElements = document.body.querySelector(inViewClass);
    if (inViewElements && !isExperienceEditor) {
        setupInView(inViewClass, "inview--active", "show", 0);
    }

    //Check if there is any images to lazyLoad before init lazyload
    const lazyElements = document.querySelector(lazyClass);
    if (lazyElements) {
        lazyloadInstance = new LazyLoad(lazyloadConfig);
    }

    initNavigation();
    setupBarba();
    initSwitcher();

    const parallaxElement = document.body.querySelector(parallaxClass);
    setupParallaxElement(parallaxElement);
});
