import { parseHTML } from "~/js/utils/dom/elementManipulation";
import { nameMarkup } from "./edit-name/markup";
import { passwordMarkup } from "./change-password/markup";
import { newsletterMarkup } from "./newsletter/markup";
import { dependentMarkup } from "./dependent/markup";
import { closeMarkup } from "../close";

export const profileMarkup = (
    labels,
    user,
    subscriptionLists,
    dependentsLists
) => {
    const { headerTitle, deleteAccountLabel, logoutLabel } = labels;
    const { isInternal, showManageHostDependency } = user;

    return parseHTML(`
    <div class="membership-header membership-header--profile">
        ${closeMarkup}
        <h2 class="animate-fade membership-header__title"><strong>${headerTitle}</strong></h2>
    </div>
    <div class="membership-middle membership-middle--profile">
        ${nameMarkup(labels, user)[0].innerHTML}
        ${passwordMarkup(labels)[0].innerHTML}
        ${
            isInternal || showManageHostDependency
                ? dependentMarkup(labels, user, dependentsLists)[0].innerHTML
                : ""
        }
        ${
            newsletterMarkup(labels, subscriptionLists, user.acceptSubscribe)[0]
                .outerHTML
        }
    </div>
    <div class="membership-footer membership-footer--profile">
        <button type="button" class="animate-fade button button--secondary button--secondary-white delete">${deleteAccountLabel}</button>
        <button type="button" class="animate-fade button button--secondary button--secondary-white logout">${logoutLabel}</button>
    </div>`);
};
