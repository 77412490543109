import { breakpointIndex, currentWindowWidth } from "../events/onWindowResize";
import { hasClass } from "../dom/classList";
import { disableScrollLock, enableScrollLock } from "../dom/scrollLock";

export const setScrollLock = () => {
    if (hasClass(document.body, "is-app")) {
        enableScrollLock();
    } else {
        if (currentWindowWidth <= breakpointIndex.xs) {
            const htmlTag = document.documentElement;
            htmlTag.style.position = "absolute";
            htmlTag.style.overflow = "hidden";
            htmlTag.style.height = window.innerHeight + "px";
            htmlTag.style.width = "100%";
        } else {
            enableScrollLock();
        }
    }
};

export const removeScrollLock = () => {
    if (hasClass(document.body, "is-app")) {
        disableScrollLock();
    } else {
        if (currentWindowWidth <= breakpointIndex.xs) {
            const htmlTag = document.documentElement;
            htmlTag.style.position = "";
            htmlTag.style.overflow = "";
            htmlTag.style.height = "";
            htmlTag.style.width = "";
        } else {
            disableScrollLock();
        }
    }
};
