import { appendElement } from "~/js/utils/dom/elementManipulation";
import {
    addEvent,
    addEventOnce,
    removeAllEvents
} from "~/js/utils/events/events";
import { forgotPasswordMarkup } from "./markup";
import { showError, removeErrors } from "~/js/components/input/input-errors";
import fetcher from "~/js/api/fetcher";
import { validateEmail } from "~/js/utils/helpers/validate";
import { makeButtonLoad } from "~/js/components/button/make-button-load";

export class ForgotPassword {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {};

    /**
     *
     * @param {Element} domReference - The element to work from. -> membership__content
     */
    constructor(config) {
        this.dom.membershipContent = config.membershipContent;
        this.texts = config.texts;
        this.user = config.user ? config.user : {};
        this.errorMessages = config.errorMessages;

        this.forgotPasswordApiUrl = config.forgotPasswordApiUrl;
        this.validateEmailApiUrl = config.validateEmailApiUrl;

        this.callbacks = {
            onLoginLinkClick: config.onLoginLinkClick,
            onSendEmailClick: config.onSendEmailClick
        };

        this.initialize();
    }

    kill() {
        removeAllEvents(this.dom.sendLink);
    }

    goToLogin = () => {
        this.kill();
        this.callbacks.onLoginLinkClick();
    };

    goToConfirm = () => {
        this.kill();
        this.callbacks.onSendEmailClick();
    };

    validateAndSendResetPasswordLink = () => {
        if (this.dom.emailInput.value === "") {
            showError(this.dom.emailInput, this.errorMessages.emailRequired);
            return;
        }

        if (!validateEmail(this.dom.emailInput.value)) {
            showError(this.dom.emailInput, this.errorMessages.invalideEmail);
            return;
        }

        fetcher(this.validateEmailApiUrl, "POST", {
            email: this.dom.emailInput.value
        }).then(result => {
            if (result) {
                const success = result.data.emailAlreadyExist;

                if (success) {
                    removeErrors();

                    this.user.email = this.dom.emailInput.value;

                    const buttonLoader = new makeButtonLoad(
                        this.dom.sendLink,
                        this.texts.loading
                    );
                    buttonLoader.load();

                    fetcher(this.forgotPasswordApiUrl, "POST", {
                        email: this.user.email
                    }).then(() => {
                        buttonLoader.finish().then(() => this.goToConfirm());
                    });
                } else {
                    showError(
                        this.dom.emailInput,
                        this.errorMessages.noUserWithEmail
                    );
                }
            } else {
                showError(
                    this.dom.emailInput,
                    this.errorMessages.genericMembershipError
                );
            }
        });
    };

    initialize() {
        const forgotPasswordHtml = forgotPasswordMarkup(this.texts);
        appendElement(forgotPasswordHtml, this.dom.membershipContent);

        this.dom.membershipContent.className =
            "membership__content membership__content--forgot-password";

        this.dom.loginLink = this.dom.membershipContent.querySelector(".login");
        this.dom.sendLink =
            this.dom.membershipContent.querySelector(".send-link");
        this.dom.emailInput =
            this.dom.membershipContent.querySelector("#email");

        addEventOnce(this.dom.loginLink, "click", this.goToLogin);
        addEvent(
            this.dom.sendLink,
            "click",
            this.validateAndSendResetPasswordLink
        );
    }
}
