import { parseHTML } from "~/js/utils/dom/elementManipulation";
import closeSvg from "~/js/components/close/close";
import { forEach } from "~/js/utils/helpers/forEach";

export const pollMarkup = ({
    data,
    showAnswers,
    showCloseButton = false,
    showExternalContent = false
}) => {
    let pollAnswers = "";
    let pollDesc = "";

    if (showAnswers) {
        pollDesc = data.headlineQuestion || data.headlineAnswer || "";
    }

    forEach(data.answers, answer => {
        const resultClass =
            data.userAnswer === answer.answerId && data.correctAnswer
                ? data.userAnswer === data.correctAnswer
                    ? "button--correct-answer"
                    : "button--wrong-answer"
                : "";

        const selectedClass =
            data.userAnswer === answer.answerId
                ? "answer__container--selected"
                : "";

        const percent =
            data.numberOfVotes > 0
                ? Math.round((answer.numberOfVotes / data.numberOfVotes) * 100)
                : 0;

        pollAnswers += `
        ${
            showAnswers
                ? `
                <div class="answer__container ${selectedClass} anime-el">
                    <div class="answer answer--result button ${resultClass}" data-answer-id="${
                      answer.answerId
                  }">
                        <span class="txt">${answer.answer}</span>
                        <span class="percent">${percent}%</span>
                        <div class="indicator" data-number-count="${percent}"></div>
                    </div>
                    ${
                        answer.answerId === data.correctAnswer
                            ? `
                            <span class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                    <rect x="1" y="1" width="28" height="28" rx="14" fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7275 12.9671L19.3419 11.3892L13.3609 18.2009L10.5038 14.947L9.27237 16.3495L13.515 21.1814L14.3635 20.215L14.7465 19.7789L20.7275 12.9671Z" fill="#00843D"/>
                                    <rect x="1" y="1" width="28" height="28" rx="14" stroke="white" stroke-width="2"/>
                                </svg>
                            </span>`
                            : ``
                    }
                </div>`
                : `
                <button type="button" class="answer answer--select button button--secondary button--secondary-white margin__bottom--sm anime-el" data-answer-id="${answer.answerId}">
                    <span class="txt">${answer.answer}</span>
                </button>`
        }
        `;
    });

    return parseHTML(`
        ${showCloseButton ? `<div class="poll__close">${closeSvg}</div>` : ""}
        <div class="poll__content">
            <div class="poll__desc margin__bottom--sm anime-el">
            ${pollDesc}
            </div>
            <h2 class="poll__question margin__bottom--sm anime-el">${
                !showExternalContent ? data.question : data.externalTitleLabel
            }</h2>
            ${
                "" /*<span class="total-answers margin__bottom--sm anime-el">${
                data.numberOfVotes
            } ${data.answersLabel}</span>*/
            }
            <div class="poll__answers" data-poll-id="${data.pollId}">
                ${
                    !showExternalContent
                        ? pollAnswers
                        : `<div class="poll__factbox anime-el">${data.externalBodyLabel}</div>`
                }
                ${
                    showAnswers && data.factTitle !== "" && data.factText !== ""
                        ? `<div class="poll__factbox anime-el">
                    <h4>${data.factTitle}</h4>
                    <p>${data.factText}</p>
                </div>`
                        : ""
                }
            </div>
            <button type="button" class="poll__vote-btn button button--primary ${
                showAnswers || showExternalContent || data.answers.length < 1
                    ? "hidden"
                    : ""
            }" disabled><span>${data.voteLabel}</span></button>
        </div>
    `);
};
