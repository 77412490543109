/**
 * Append a snackbar/popover to an item
 *
 * import { snackbar } from "../snackbar/snackbar";
 *
 */

import {
    appendElement,
    deleteElement
} from "~/js/utils/dom/elementManipulation";
import { createElement } from "~/js/utils/dom/createElement";
import { getElementSize } from "~/js/utils/dom/elementProperties";
import anime from "animejs";
import { STANDARDCUBICBEZIER } from "~/js/constants/easings";
import { SLOW } from "~/js/constants/durations";
import { addEvent, removeAllEvents } from "~/js/utils/events/events";

export class Snackbar {
    constructor(config) {
        const {
            element,
            autoClose = false,
            autoCloseDuration = 2000,
            position = "top"
        } = config;

        this.closeTimeout = undefined;

        this.config = {
            ...config,
            text: config.text || "",
            autoClose,
            autoCloseDuration,
            position
        };

        this.dom = {
            container: element
        };

        this.initialize();
    }

    initialize() {
        const snackbarArrowSize = 10;
        const snackbarConfig = {
            className: `snackbar snackbar--${this.config.position}`
        };

        // If snackbar has confirm, setup confirm and cancel callback
        if (
            this.config.confirm &&
            this.config.onConfirm &&
            this.config.onCancel
        ) {
            this.dom.confirmButton = createElement("button", {
                type: "button",
                className:
                    "snackbar__confirm-button button-link button-link--primary button-link--primary--black",
                text: this.config.confirmLabel
            });

            this.dom.cancelButton = createElement("button", {
                type: "button",
                className:
                    "snackbar__cancel-button button-link button-link--primary button-link--primary--black",
                text: this.config.cancelLabel
            });

            const confirmOrCancelElement = createElement("div", {
                className: "snackbar__confirm",
                html: [this.dom.confirmButton, this.dom.cancelButton]
            });

            addEvent(this.dom.confirmButton, "click", this.config.onConfirm);
            addEvent(this.dom.cancelButton, "click", this.config.onCancel);

            snackbarConfig.html = [this.config.text, confirmOrCancelElement];
        } else {
            snackbarConfig.text = this.config.text;
        }

        this.dom.snackbarElement = createElement("div", snackbarConfig);

        // Append snackbar before calculating position
        appendElement(this.dom.snackbarElement, this.dom.container.parentNode);
        const snackBarElementSize = getElementSize(this.dom.snackbarElement);

        const elementSize = getElementSize(this.dom.container);

        let snackBarElementTopPosition;
        let snackBarElementLeftPosition;

        // Check for position, standard is top - and set accordingly
        // TODO: down option

        if (this.config.position === "left") {
            snackBarElementTopPosition = `${Math.floor(
                this.dom.container.offsetTop -
                    snackBarElementSize.height / 2 +
                    elementSize.height / 2
            )}px`;

            snackBarElementLeftPosition = `${Math.floor(
                this.dom.container.offsetLeft -
                    snackBarElementSize.width -
                    snackbarArrowSize
            )}px`;
        } else if (this.config.position === "right") {
            snackBarElementTopPosition = `${Math.floor(
                this.dom.container.offsetTop -
                    snackBarElementSize.height / 2 +
                    elementSize.height / 2
            )}px`;

            snackBarElementLeftPosition = `${Math.floor(
                this.dom.container.offsetLeft +
                    elementSize.width +
                    snackbarArrowSize
            )}px`;
        } else {
            snackBarElementTopPosition = `${Math.floor(
                this.dom.container.offsetTop -
                    (snackBarElementSize.height + snackbarArrowSize)
            )}px`;

            snackBarElementLeftPosition = `${Math.floor(
                this.dom.container.offsetLeft +
                    elementSize.width / 2 -
                    snackBarElementSize.width / 2
            )}px`;
        }

        this.dom.snackbarElement.style.top = snackBarElementTopPosition;
        this.dom.snackbarElement.style.left = snackBarElementLeftPosition;

        let animeTranslate;

        if (this.config.position === "right") {
            animeTranslate = { translateX: ["10%", "5%"] };
        } else if (this.config.position === "left") {
            animeTranslate = { translateX: ["-10%", "-5%"] };
        } else {
            animeTranslate = { translateY: ["0%", "-10%"] };
        }

        // Animate in snackbar according to position
        anime({
            ...animeTranslate,
            targets: this.dom.snackbarElement,
            easing: STANDARDCUBICBEZIER,
            duration: SLOW,
            opacity: [0, 1],
            complete: () => {
                if (this.config.autoClose) {
                    this.closeTimeout = setTimeout(() => {
                        this.close();
                    }, this.config.autoCloseDuration);
                }
            }
        });
    }

    close() {
        // If user close snackbar clear timeout
        if (this.closeTimeout) {
            clearTimeout(this.closeTimeout);
        }

        // Remove confirm buttons eventlisternes
        if (this.dom.confirmButton) {
            removeAllEvents(this.dom.confirmButton);
        }

        if (this.dom.cancelButton) {
            removeAllEvents(this.dom.cancelButton);
        }

        let animeTranslate;

        if (this.config.position === "right") {
            animeTranslate = { translateX: ["5%", "10%"] };
        } else if (this.config.position === "left") {
            animeTranslate = { translateX: ["-5%", "-10%"] };
        } else {
            animeTranslate = { translateY: ["-10%", "0%"] };
        }

        // Fade out snackbar and delete element
        return anime({
            ...animeTranslate,
            targets: this.dom.snackbarElement,
            easing: STANDARDCUBICBEZIER,
            duration: SLOW,
            opacity: [1, 0],
            complete: () => {
                anime.remove(this.dom.snackbarElement);
                deleteElement(this.dom.snackbarElement);
                delete this.dom;

                if (this.config.onClose) {
                    this.config.onClose();
                }
            }
        });
    }
}
