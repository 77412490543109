import { addEvent, removeAllEvents } from "~/js/utils/events/events";
import { Snackbar } from "~/js/components/snackbar/snackbar";
import fetcher from "~/js/api/fetcher";
import { showError, removeErrors } from "~/js/components/input/input-errors";

export class EditName {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {};

    /**
     *
     * @param {Element} domReference - The element to work from. -> membership__content
     */
    constructor(config, animate) {
        this.dom.membershipContent = config.membershipContent;
        this.texts = config.texts;
        this.user = config.user ? config.user : {};
        this.errorMessages = config.errorMessages;
        this.animate = animate;

        this.updateNamesApiUrl = config.updateNamesApiUrl;

        this.initialize();
    }

    kill() {
        removeAllEvents([
            this.dom.editNameButton,
            this.dom.cancelEditNameButton
        ]);
        removeAllEvents(this.dom.saveName);
    }

    validateName = () => {
        if (this.dom.firstNameInput.value === "") {
            showError(
                this.dom.firstNameInput,
                this.errorMessages.firstNameRequired
            );
            return false;
        }

        if (this.dom.lastNameInput.value === "") {
            showError(
                this.dom.lastNameInput,
                this.errorMessages.lastNameRequired
            );
            return false;
        }

        removeErrors();
        return true;
    };

    saveName = () => {
        if (!this.validateName()) {
            return;
        }

        const user = {
            firstName: this.dom.firstNameInput.value,
            lastName: this.dom.lastNameInput.value,
            email: this.user.emailAddress
        };

        // If the new name is equal to the original name there's no need to update it
        if (
            user.firstName === this.dom.firstName.textContent &&
            user.lastName === this.dom.lastName.textContent
        ) {
            this.toggleEditName();
            return;
        }

        fetcher(this.updateNamesApiUrl, "POST", user).then(result => {
            if (result.success) {
                this.toggleEditName(undefined, true);
                this.updateName(user);
            } else {
                alert("name error");
            }
        });
    };

    // Update name without reloading
    updateName = user => {
        this.dom.firstName.textContent = user.firstName;
        this.dom.lastName.textContent = user.lastName;

        if (this.dom.profileButtonName) {
            this.dom.profileButtonName.textContent = `${user.firstName} ${user.lastName}`;
        }
    };

    toggleEditName = (_, nameChanged = false) => {
        const open = this.dom.editableName.classList.contains("visuallyhidden");

        if (open) {
            this.animate(this.dom.name, false, () =>
                this.animate(this.dom.editableName, true)
            );
        } else {
            this.animate(this.dom.editableName, false, () =>
                this.animate(this.dom.name, true, () => {
                    if (nameChanged) {
                        new Snackbar({
                            element: this.dom.name,
                            text: this.texts.profileUpdatedConfirmation,
                            autoClose: true,
                            confirm: false
                        });
                    }
                })
            );
        }

        this.closeChangePassword();
    };

    closeChangePassword = () => {
        if (!this.dom.enterPassword.classList.contains("visuallyhidden")) {
            this.animate(this.dom.enterPassword, false, () =>
                this.animate(this.dom.changePassword, true)
            );
        }
    };

    initialize() {
        this.dom.editNameButton =
            this.dom.membershipContent.querySelector(".edit-name");
        this.dom.cancelEditNameButton =
            this.dom.membershipContent.querySelector(".cancel-edit-name");
        this.dom.saveNameButton =
            this.dom.membershipContent.querySelector(".save-name");
        this.dom.profileButtonName = document.querySelector(
            ".navigation .profile .name"
        );

        this.dom.name = this.dom.membershipContent.querySelector(".name");
        this.dom.editableName =
            this.dom.membershipContent.querySelector(".editable-name");

        this.dom.changePassword =
            this.dom.membershipContent.querySelector(".change-password");
        this.dom.enterPassword =
            this.dom.membershipContent.querySelector(".enter-password");

        this.dom.firstName =
            this.dom.membershipContent.querySelector(".firstName");
        this.dom.lastName =
            this.dom.membershipContent.querySelector(".lastName");

        this.dom.firstNameInput =
            this.dom.membershipContent.querySelector("#firstName");
        this.dom.lastNameInput =
            this.dom.membershipContent.querySelector("#lastName");

        addEvent(
            [this.dom.editNameButton, this.dom.cancelEditNameButton],
            "click",
            this.toggleEditName
        );
        addEvent(this.dom.saveNameButton, "click", this.saveName);
    }
}
