import { onReady } from "~/js/utils/events/onReady";
import { addEvent } from "../../../utils/events/events";
import domtoimage from "dom-to-image";

export class CertificateDownload {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;
        this.dom.downloadButton = this.dom.container.querySelector("button");

        this.dom.nodeToBeConverted =
            this.dom.container.querySelector(".certificate__item");

        onReady(() => this.initialize());
    }

    initialize() {
        this.eventName =
            this.dom.container.dataset.eventname.replace(" ", "-") ||
            "aramco-life-event";

        // On click we send the node to be converted to an image through the domtoimage package and download it.
        addEvent(this.dom.downloadButton, "click", () => {
            domtoimage
                .toJpeg(this.dom.nodeToBeConverted, {
                    quality: 1,
                    type: "image/jpeg"
                })
                .then(dataUrl => {
                    const link = document.createElement("a");
                    link.download = `certificate-${this.eventName}.jpeg`;
                    link.href = dataUrl;
                    link.click();
                });
        });
    }
}
