import { parseHTML } from "~/js/utils/dom/elementManipulation";
import aramcoLifeLogo from "~/js/components/logo/aramco-life-pale";
import aramcoLogo from "~/js/components/logo/aramco-logo";

export const splashMarkup = () => {
    return parseHTML(`
    <div class="signup-splash animate-fade">
        ${aramcoLogo}
        ${aramcoLifeLogo}
    </div>`);
};
