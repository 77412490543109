export default function fetcher(
    url,
    type = "GET",
    data,
    appkey = "",
    token = ""
) {
    if (window.aramcolife.appkey !== "") {
        token = window.aramcolife.token;
        appkey = window.aramcolife.appkey;
    }

    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json"
    };

    if (appkey) {
        headers.AppKey = appkey;
    }

    if (token) {
        headers.TokenAuthorization = `Bearer ${token}`;
    }

    const fetchConfig = {
        method: type,
        headers
    };

    if (data) {
        fetchConfig.body = JSON.stringify(data);
    }

    return fetch(url, fetchConfig).then(response => {
        if (response.status === 200) {
            return response.json();
        } else {
            if (!response.ok) {
                console.warn(`Looks like there was a problem. Status Code ${response.status}`);
            }
        }
    });
}
