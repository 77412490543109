import { parseHTML } from "~/js/utils/dom/elementManipulation";
import { pauseIcon, playIcon } from "../icons/icons";

export const audioBar = ({
    duration,
    currentTime,
    audioFileUrl,
    isPlaying,
}) => {
    const playerIcon = isPlaying ? pauseIcon : playIcon;
    const progressWidth = duration > 0 ? (currentTime / duration) * 100 : 0;
    const remainingTime = duration > 0 ? duration - currentTime : 0;

    return parseHTML(`
        <div class="article-audioplayer__bar-container">
            <audio controls class="article-audioplayer__audio visuallyhidden">
                <source src=${audioFileUrl} type="audio/mp3">
                Your browser does not support the audio tag.
            </audio>

            <div class="article-audioplayer__time-container">
                <span class="article-audioplayer__time-current">${currentTime}</span>
                <div class="article-audioplayer__progressbar-container">
                    <span class="article-audioplayer__progressbar" style="width: ${progressWidth}%"></span>
                    <button
                        class="article-audioplayer__play-pause"
                        aria-label=${isPlaying ? "Pause audio" : "Play audio"}
                    >
                        ${playerIcon}
                    </button>
                </div>
                <span class="article-audioplayer__time-remaining">-${remainingTime}</span>
            </div>
        </div>
    `);
};
