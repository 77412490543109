import {
    appendElement,
    emptyElement
} from "~/js/utils/dom/elementManipulation";
import {
    addEvent,
    addEventOnce,
    removeAllEvents
} from "~/js/utils/events/events";
import { profileMarkup } from "./markup";
import { Snackbar } from "~/js/components/snackbar/snackbar";
import fetcher from "~/js/api/fetcher";
import { EditName } from "./edit-name/edit-name";
import { ChangePassword } from "./change-password/change-password";
import anime from "animejs";
import { STANDARDCUBICBEZIER } from "~/js/constants/easings";
import { isRtl } from "~/js/utils/helpers/isRtl";
import { Newsletter } from "./newsletter/newsletter";
import { isIE11 } from "../../../utils/helpers/isIE11";
import { Membership, membershipStartPages } from "../membership";

export class Profile {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {};

    /**
     *
     * @param {Element} domReference - The element to work from. -> membership__content
     */
    constructor(config) {
        this.config = config;
        this.dom.membershipContent = config.membershipContent;
        this.texts = config.texts;
        this.user = config.user ? config.user : {};
        this.errorMessages = config.errorMessages;
        this.subscriptionLists = config.subscriptionLists;
        this.dependentsLists = config.dependentsLists;

        this.snackbar = undefined;

        this.logoutApiUrl = config.logoutApiUrl;
        this.logOutRedirectUrl = config.logOutRedirectUrl;
        this.deleteAccountApiUrl = config.deleteAccountApiUrl;
        this.deleteAccountRedirectUrl = config.deleteAccountRedirectUrl;

        this.easing = STANDARDCUBICBEZIER;
        this.durationIn = 1500;
        this.durationOut = 500;

        this.initialize();
    }

    kill() {
        this.name.kill();
        this.password.kill();
        this.newsletter.kill();
        removeAllEvents(this.dom.deleteLink);
    }

    logout = () => {
        fetcher(this.logoutApiUrl).then(() => {
            this.kill();
            window.location.href = this.logOutRedirectUrl;
        });
    };

    delete = () => {
        // Hide snackbar so we don't create multiple instances
        if (this.snackbar) {
            this.snackbar.close();
        }

        this.snackbar = new Snackbar({
            element: this.dom.deleteLink,
            text: this.texts.confirmDeleteAccountLabel,
            autoClose: false,
            confirm: true,
            cancelLabel: this.texts.noButtonLabel,
            confirmLabel: this.texts.yesButtonLabel,
            onCancel: () => {
                this.snackbar.close();
                this.snackbar = undefined;
            },
            onConfirm: () => {
                fetcher(this.deleteAccountApiUrl, "POST", {
                    email: this.user.emailAddress
                }).then(result => {
                    if (result.success) {
                        this.kill();
                        window.location.href = this.deleteAccountRedirectUrl;
                    } else {
                        alert("delete error");
                    }
                });
            }
        });
    };

    animate = (element, transitionIn = true, callback = () => {}) => {
        const animateTranslate = element.querySelectorAll(".animate-translate");
        const animateFade = transitionIn
            ? element.querySelectorAll(".animate-fade")
            : element.querySelectorAll(
                  ".animate-fade:not(.animate-fade--once)"
              );

        const firstInput = element.querySelector("input");

        if (firstInput) {
            firstInput.focus();
        }

        const translateForwards = isRtl ? [-100, 0] : [100, 0];
        const translateBackwards = isRtl ? [0, 100] : [0, -100];

        const tl = anime.timeline({
            easing: this.easing,
            duration: transitionIn ? this.durationIn : this.durationOut,
            complete: () => callback()
        });

        if (animateTranslate.length) {
            // IE does not reach the anime before function so therefore we have to force removing the class
            if (isIE11) {
                if (transitionIn) {
                    element.classList.remove("visuallyhidden");
                }
            }
            tl.add(
                {
                    targets: animateTranslate,
                    translateX: transitionIn
                        ? translateForwards
                        : translateBackwards,
                    opacity: transitionIn ? [0, 1] : [1, 0],
                    delay: anime.stagger(100),
                    before: () => {
                        if (transitionIn) {
                            element.classList.remove("visuallyhidden");
                        }
                    },
                    complete: () => {
                        if (!transitionIn) {
                            element.classList.add("visuallyhidden");
                        }
                    }
                },
                0
            );
        }

        if (animateFade.length) {
            tl.add(
                {
                    targets: animateFade,
                    opacity: transitionIn ? [0, 1] : [1, 0],
                    delay: anime.stagger(100)
                },
                0
            );
        }
    };

    showDependency() {
        const membershipElement = document.querySelector(
            "[data-module='membership']"
        );
        if (membershipElement) {
            new Membership(membershipElement, membershipStartPages.DEPENDENT);
        }
    }

    initialize() {
        const profileHtml = profileMarkup(
            this.texts,
            this.user,
            this.subscriptionLists,
            this.dependentsLists
        );

        emptyElement(this.dom.membershipContent);
        appendElement(profileHtml, this.dom.membershipContent);

        this.dom.membershipContent.classList.add("scrollable");

        this.dom.membershipMiddle =
            this.dom.membershipContent.querySelector(".membership-middle");

        this.name = new EditName(this.config, this.animate);
        this.password = new ChangePassword(this.config, this.animate);
        this.newsletter = new Newsletter(this.config);

        this.dom.logoutLink =
            this.dom.membershipContent.querySelector(".logout");
        this.dom.deleteLink =
            this.dom.membershipContent.querySelector(".delete");

        addEvent(this.dom.deleteLink, "click", this.delete);
        addEventOnce(this.dom.logoutLink, "click", this.logout);

        this.dom.dependencyButton = this.dom.membershipContent.querySelector(
            "[data-status='dependency']"
        );

        addEvent(this.dom.dependencyButton, "click", this.showDependency);
    }
}
