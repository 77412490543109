/* eslint-disable no-constant-condition */
import { onReady } from "~/js/utils/events/onReady";
import { addEvent } from "~/js/utils/events/events";
import { addClass, removeClass } from "~/js/utils/dom/classList";
import fetcher from "~/js/api/fetcher";
import {
    appendElement,
    parseHTML,
    deleteElement
} from "~/js/utils/dom/elementManipulation";
import stepOneContentTemplate from "./templates/step-one";
import stepTwoContentTemplate from "./templates/step-two";
import stepThreeContentTemplate from "./templates/step-three";
import stepFourContentTemplate from "~/js/components/volunteer/signup/templates/step-four";
import stepFiveContentTemplate from "~/js/components/volunteer/signup/templates/step-five";
import stepSixContentTemplate from "~/js/components/volunteer/signup/templates/step-six";
import stepSevenContentTemplate from "~/js/components/volunteer/signup/templates/step-seven";
import stepEightContentTemplate from "~/js/components/volunteer/signup/templates/step-eight";
import { FormValidate } from "../../../utils/formValidate";
import {
    onClickOutside,
    removeOnClickOutside
} from "~/js/utils/events/onClickOutside";
import { forEach } from "~/js/utils/helpers/forEach";
import anime from "animejs";
import { STANDARDCUBICBEZIER } from "~/js/constants/easings";
import { MEDIUM } from "~/js/constants/durations";
import { addLoader } from "~/js/components/loader/loader";
import { scrollTo } from "~/js/utils/dom/scrollTo";
import { LocalDatetime } from "~/js/components/local-datetime/local-datetime";
import {
    setScrollLock,
    removeScrollLock
} from "../../../utils/helpers/extendedScrollLock";

export class VolunteerSignup {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    classes = {
        rootClass: "volunteer-signup"
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;

        this.dom.mainContent = document.body.querySelector(".main-container");

        this.dom.signupContainer =
            document.body.querySelector(".volunteer-signup");

        this.dom.backgroundContainer = document.body.querySelector(
            ".volunteer-signup__background"
        );

        this.dom.signupForm = this.dom.signupContainer.querySelector(
            ".volunteer-signup__form"
        );

        this.dom.stepsFooter = this.dom.signupContainer.querySelector(
            ".volunteer-signup__footer"
        );

        this.dom.stepsContainer = this.dom.signupContainer.querySelector(
            ".volunteer-signup__steps"
        );

        this.dom.stepsIndicatorContainer =
            this.dom.signupContainer.querySelector(
                ".volunteer-signup__steps-indicator"
            );

        this.dom.closeButton = this.dom.signupContainer.querySelector(
            ".volunteer-signup__close-button"
        );

        this.dom.continueButton = this.dom.signupContainer.querySelector(
            ".volunteer-signup__buttons--continue"
        );

        this.dom.backButton = this.dom.signupContainer.querySelector(
            ".volunteer-signup__buttons--back"
        );

        this.classes = {
            ...this.classes,
            openSignupClass: `${this.classes.rootClass}--open`,
            stepClass: `${this.classes.rootClass}__step`,
            activeMainClass: "main-container--show-volunteer-signup",
            formInvalidField: "form-validate__field--invalid"
        };

        onReady(() => this.initialize());
    }

    getSections = obj => {
        const result = {};
        const keys = Object.keys(obj);

        keys.forEach(key => {
            let entries = Object.entries(obj[key]).filter(
                ([, subValue]) => !subValue?.hideSection
            );
            result[key] = Object.fromEntries(entries);
        });

        return result;
    };

    // Simple function to set the correct texts according to the response to keep the logic cleaner all around
    setupButtons = () => {
        this.dom.closeButton.innerText =
            this.signupDictionary.cancelButtonLabel;
        this.dom.continueButton.innerText =
            this.signupDictionary.continueButtonLabel;
        this.dom.backButton.innerText = this.signupDictionary.backButtonLabel;
        addClass(
            this.dom.backButton,
            `${this.classes.rootClass}__buttons--hidden`
        );
    };

    updateContinueButton = currentStep => {
        // Always updating our "state" of the continue button aka the data attribute
        if (currentStep <= this.settings.totalSteps) {
            this.dom.continueButton.dataset.stepNumber = currentStep;
            removeClass(
                this.dom.backButton,
                `${this.classes.rootClass}__buttons--hidden`
            );
        }

        if (this.signupDictionary) {
            if (currentStep === this.settings.totalSteps - 1) {
                this.dom.continueButton.innerText =
                    this.signupDictionary.submitButtonLabel;
                removeClass(
                    this.dom.backButton,
                    `${this.classes.rootClass}__buttons--hidden`
                );
            }
            if (currentStep === 1) {
                addClass(
                    this.dom.backButton,
                    `${this.classes.rootClass}__buttons--hidden`
                );
            }
            if (currentStep === this.settings.totalSteps) {
                this.dom.continueButton.innerText =
                    this.signupDictionary.closeButtonLabel;
                addClass(
                    this.dom.backButton,
                    `${this.classes.rootClass}__buttons--hidden`
                );
            }
            if (
                currentStep < this.settings.totalSteps - 1 &&
                this.dom.continueButton.innerText !==
                    this.signupDictionary.continueButtonLabel
            ) {
                this.dom.continueButton.innerText =
                    this.signupDictionary.continueButtonLabel;
            }
        }
    };

    createStepIndicator = (stepNumber, currentStep) => {
        return `<div data-step-number="${stepNumber}" class="${
            this.classes.rootClass
        }__steps-indicator-step ${
            stepNumber === currentStep
                ? `${this.classes.rootClass}__steps-indicator-step--active`
                : ""
        }"></div>`;
    };

    buildStepsIndicator = () => {
        const MAX_STEPS = this.settings.totalSteps;

        this.dom.stepsIndicatorContainer.innerHTML = [
            ...Array(MAX_STEPS).keys()
        ]
            .map(i => this.createStepIndicator(i + 1, this.currentStep))
            .join("");
    };

    // BUILDER FUNCTIONS
    buildStepWrapper = (stepContent, stepNumber) => {
        return `
            <div class=${this.classes.stepClass} data-step=${stepNumber} ${
            stepNumber > 1 ? `hidden` : ``
        }>
                ${stepContent}
            </div>`;
    };

    // Move out / create 'factory'-ish func if same structure is followed
    buildStepFromSections = (sections, stepTemplateId) => {
        const model = this.shownSections;
        const classes = this.classes;
        let stepContent;

        // eslint-disable-next-line default-case
        switch (stepTemplateId) {
            case 1:
                stepContent = stepOneContentTemplate({
                    classes,
                    model,
                    sections
                });
                break;
            case 2:
                stepContent = stepTwoContentTemplate({
                    classes,
                    model,
                    sections
                });
                break;
            case 3:
                stepContent = stepThreeContentTemplate({
                    classes,
                    model,
                    sections
                });
                break;
            case 4:
                stepContent = stepFourContentTemplate({
                    classes,
                    model,
                    sections
                });
                break;
            case 5:
                stepContent = stepFiveContentTemplate({
                    classes,
                    model,
                    sections
                });
                break;
            case 6:
                stepContent = stepSixContentTemplate({
                    classes,
                    model,
                    sections
                });
                break;
            case 7:
                stepContent = stepSevenContentTemplate({
                    classes,
                    model,
                    sections
                });
                break;
            case 8:
                stepContent = stepEightContentTemplate({
                    classes,
                    model,
                    sections
                });
                break;
        }

        return this.buildStepWrapper(stepContent, stepTemplateId);
    };

    fetchData = eventId => {
        // Fetch the data
        fetcher(`${this.settings.endPoint}?eventId=${eventId}`).then(
            ({ data }) => {
                this.signupFlowLoaded = true;
                // All the sections
                this.allSections = data;
                // Filter out the sections that is hidden (hideSection: true)
                this.shownSections = this.getSections(data);
                // Maybe we need this??
                this.stepLabels = Object.keys(
                    this.shownSections.formFieldSections
                );
                // The "dictionary" part of the response
                this.signupDictionary = data.texts;

                // Adding the event id in the create volunteer object
                this.volunteerData["eventId"] = eventId;

                this.settings.signupApiUrl = data.api.signupApiUrl;

                window.dataLayer = window.dataLayer || [];

                // STEP 1 -> USER INFO
                if (this.shownSections.formFieldSections["section-details"]) {
                    appendElement(
                        parseHTML(
                            this.buildStepFromSections(
                                this.shownSections.formFieldSections[
                                    "section-details"
                                ],
                                1
                            )
                        ),
                        this.dom.stepsContainer
                    );

                    this.dom.continueButton.focus();

                    // Push event step to dataLayer
                    window.dataLayer.push({
                        event: "eventSignupFlow",
                        eventId: this.settings.eventId,
                        eventFunnelStep: 1
                    });
                }

                // STEP 2
                if (
                    this.shownSections.formFieldSections["section-age"] &&
                    this.shownSections.formFieldSections["section-gender"]
                ) {
                    appendElement(
                        parseHTML(
                            this.buildStepFromSections(
                                [
                                    this.shownSections.formFieldSections[
                                        "section-age"
                                    ],
                                    this.shownSections.formFieldSections[
                                        "section-gender"
                                    ]
                                ],
                                2
                            )
                        ),
                        this.dom.stepsContainer
                    );
                }

                // STEP 3
                if (
                    this.shownSections.formFieldSections[
                        "section-business-lines"
                    ]
                ) {
                    appendElement(
                        parseHTML(
                            this.buildStepFromSections(
                                this.shownSections.formFieldSections[
                                    "section-business-lines"
                                ],
                                3
                            )
                        ),
                        this.dom.stepsContainer
                    );
                }

                // STEP 4
                if (
                    [
                        this.shownSections.formFieldSections[
                            "section-en-language-level"
                        ],
                        this.shownSections.formFieldSections[
                            "section-ar-language-level"
                        ]
                    ]
                ) {
                    appendElement(
                        parseHTML(
                            this.buildStepFromSections(
                                [
                                    this.shownSections.formFieldSections[
                                        "section-en-language-level"
                                    ],
                                    this.shownSections.formFieldSections[
                                        "section-ar-language-level"
                                    ]
                                ],
                                4
                            )
                        ),
                        this.dom.stepsContainer
                    );
                }

                // STEP 5
                if (
                    this.shownSections.formFieldSections["section-motivation"]
                ) {
                    appendElement(
                        parseHTML(
                            this.buildStepFromSections(
                                this.shownSections.formFieldSections[
                                    "section-motivation"
                                ],
                                5
                            )
                        ),
                        this.dom.stepsContainer
                    );
                }

                // STEP 6
                if (this.shownSections.formFieldSections["section-region"]) {
                    appendElement(
                        parseHTML(
                            this.buildStepFromSections(
                                this.shownSections.formFieldSections[
                                    "section-region"
                                ],
                                6
                            )
                        ),
                        this.dom.stepsContainer
                    );
                }

                // STEP 7
                if (
                    [
                        this.shownSections.formFieldSections[
                            "section-anything-to-add"
                        ],
                        this.shownSections.formFieldSections["section-username"]
                    ]
                ) {
                    appendElement(
                        parseHTML(
                            this.buildStepFromSections(
                                [
                                    this.shownSections.formFieldSections[
                                        "section-anything-to-add"
                                    ],
                                    this.shownSections.formFieldSections[
                                        "section-username"
                                    ]
                                ],
                                7
                            )
                        ),
                        this.dom.stepsContainer
                    );
                }

                // STEP 8
                if (this.signupDictionary) {
                    appendElement(
                        parseHTML(
                            this.buildStepFromSections(this.signupDictionary, 8)
                        ),
                        this.dom.stepsContainer
                    );
                }

                this.settings.stepWidth =
                    this.dom.stepsContainer.getBoundingClientRect().width;
                this.settings.signupContainerWidth =
                    this.dom.signupContainer.getBoundingClientRect().width;

                this.dom.stepCollection =
                    this.dom.signupContainer.querySelectorAll(
                        `.${this.classes.rootClass}__step`
                    );

                this.dom.currentStepElement =
                    this.dom.signupContainer.querySelector(`[data-step="1"]`);

                this.buildStepsIndicator();
                this.setupButtons();

                anime({
                    targets: [this.dom.stepsContainer, this.dom.stepsFooter],
                    opacity: [0, 1],
                    easing: STANDARDCUBICBEZIER,
                    duration: MEDIUM
                });

                // Adding validation for form fields
                this.formValidate = new FormValidate(this.dom.signupContainer, {
                    saveDataWhenOffline: false,
                    beforeSubmit: () => {
                        // Previous errors (if any) have been removed from all fields and validation is about to run.
                        // If you have any code you wish to execute before validation, you can put it here.
                    },
                    onSubmit: fields => {
                        window.console.log(fields);
                    },
                    errorMessageTargetClass: `${this.classes.rootClass}__error-message-target`
                });

                // eslint-disable-next-line no-unused-vars
                const localDatetime = new LocalDatetime(
                    this.dom.signupContainer.querySelector(
                        '[data-async-module="local-datetime"]'
                    )
                );
            }
        );
    };

    checkValidity = stepToMoveTo => {
        forEach(
            this.dom.currentStepElement.querySelectorAll("input[required]"),
            currentStepInput => {
                this.formValidate.checkFieldFromElement(currentStepInput);
            }
        );

        const invalidField = this.dom.currentStepElement.querySelector(
            `.${this.classes.formInvalidField}`
        );

        return new Promise((resolve, reject) => {
            if (!invalidField || this.currentStep > stepToMoveTo) {
                resolve("Success validating inputs");
            } else {
                reject(invalidField);
                // Inputs did not validate!
            }
        });
    };

    collectStepData = () => {
        const checkboxValues = [];

        forEach(
            this.dom.currentStepElement.querySelectorAll("input, textarea"),
            inputField => {
                if (inputField.type === "radio") {
                    if (inputField.checked) {
                        this.volunteerData[inputField.name] = inputField.value;
                    }
                } else if (inputField.type === "checkbox") {
                    if (inputField.checked) {
                        checkboxValues.push(inputField.value);

                        this.volunteerData[inputField.name] =
                            checkboxValues.join(",");
                    }
                } else {
                    this.volunteerData[inputField.name] = inputField.value;
                }
            }
        );
    };

    applyForEvent = target => {
        this.checkValidity(parseInt(target.dataset.stepNumber) + 1)
            .then(success => { // eslint-disable-line
                // Adding the loading state to the button
                addLoader(this.dom.continueButton, { replace: false });

                // disable the button
                this.dom.continueButton.disabled = true;

                // Storing data on each step
                this.collectStepData();

                console.log(this.volunteerData);

                fetcher(
                    this.settings.signupApiUrl,
                    "POST",
                    this.volunteerData
                ).then(data => {
                    // Remove the spinner from the load button
                    deleteElement(
                        this.dom.continueButton.querySelector(".spring-spinner")
                    );

                    if (data.success) {
                        // Push final step to dataLayer
                        window.dataLayer.push({
                            event: "eventSignupComplete",
                            eventId: this.settings.eventId
                        });

                        // enable the button
                        this.dom.continueButton.disabled = false;

                        deleteElement(
                            this.dom.signupContainer.querySelector(
                                `.${this.classes.rootClass}__steps-indicator`
                            )
                        );
                        this.moveSteps(parseInt(target.dataset.stepNumber) + 1);
                    } else {
                        // Find the error element
                        const tempErrorElement =
                            this.dom.currentStepElement.querySelectorAll(
                                ".form-validate__error-message"
                            );

                        if (tempErrorElement.length > 1) {
                            forEach(tempErrorElement, element => {
                                element.parentNode.removeChild(element);
                            });
                            appendElement(
                                parseHTML(
                                    `<div class="form-validate__error-message ${data.errors.map(
                                        error => error.property
                                    )}">${data.errors.map(
                                        error => error.message
                                    )}</div>`
                                ),
                                this.dom.currentStepElement.querySelector(
                                    "fieldset"
                                )
                            );
                        } else if (tempErrorElement.length === 1) {
                            forEach(tempErrorElement, element => {
                                // Add error message
                                element.innerText = data.errors.map(
                                    error => error.message
                                );
                                // Show error message
                                element.style.display = "block";
                                element.style.visibility = "visible";
                            });
                        } else {
                            appendElement(
                                parseHTML(
                                    `<div class="form-validate__error-message ${data.errors.map(
                                        error => error.property
                                    )}">${data.errors.map(
                                        error => error.message
                                    )}</div>`
                                ),
                                this.dom.currentStepElement.querySelector(
                                    "fieldset"
                                )
                            );
                        }

                        // enable the button
                        this.dom.continueButton.disabled = false;
                    }
                });
            })
            .catch(invalidField => {
                void scrollTo(
                    invalidField.parentNode.getBoundingClientRect().top,
                    200,
                    this.dom.stepsContainer,
                    100,
                    false,
                    true
                );
            });
    };

    moveBackground = stepToMoveTo => {
        // Move the bg left by -100% per step starting from 0%.
        const value = (stepToMoveTo - 1) * this.settings.signupContainerWidth;
        this.dom.backgroundContainer.style.transform = `translateX(-${value}px)`;
    };

    moveSteps = stepToMoveTo => {
        // Checking if the fields of the current step are valid
        this.checkValidity(stepToMoveTo)
            .then(success => { // eslint-disable-line
                // Push event step to dataLayer
                window.dataLayer.push({
                    event: "eventSignupFlow",
                    eventId: this.settings.eventId,
                    eventFunnelStep: stepToMoveTo
                });

                // Updating the current step
                this.currentStep = stepToMoveTo;

                // Scrolling content
                this.animateStepContent();

                // Updated steps indicator, move background and update the continue button.
                this.buildStepsIndicator(stepToMoveTo);
                this.moveBackground(stepToMoveTo);
                this.updateContinueButton(stepToMoveTo);

                // Storing data on each step
                this.collectStepData();

                // Updating currentStepElement
                this.dom.currentStepElement =
                    this.dom.signupContainer.querySelector(
                        `[data-step="${this.currentStep}"]`
                    );
            })
            .catch(invalidField => {
                void scrollTo(
                    invalidField.parentNode.getBoundingClientRect().top,
                    200,
                    this.dom.stepsContainer,
                    100,
                    false,
                    true
                );
            });
    };

    animateStepContent = () => {
        anime
            .timeline({
                easing: STANDARDCUBICBEZIER
            })
            .add({
                targets: this.dom.stepsContainer,
                opacity: [1, 0],
                easing: STANDARDCUBICBEZIER,
                duration: MEDIUM,
                complete: () => {
                    forEach(this.dom.stepCollection, step => {
                        step.setAttribute("hidden", true);
                    });

                    this.dom.currentStepElement.removeAttribute("hidden");
                }
            })
            .add({
                targets: this.dom.stepsContainer,
                opacity: [0, 1],
                easing: STANDARDCUBICBEZIER,
                duration: MEDIUM
            });
    };

    openSignup = () => {
        // For resetting purposes - REMOVE THIS PART IF WE WANT TO REMEMBER PROGRESS
        // if (this.currentStep !== 1) {
        //     this.currentStep = 1;

        //     this.moveSteps(this.currentStep);
        // }

        // enable the button
        this.dom.continueButton.disabled = false;

        if (this.dom.currentStepElement) {
            anime({
                targets: [this.dom.stepsContainer, this.dom.stepsFooter],
                opacity: [0, 1],
                easing: STANDARDCUBICBEZIER,
                duration: MEDIUM
            });
        }

        this.updateContinueButton(this.currentStep);

        setScrollLock();

        addClass(this.dom.signupContainer, this.classes.openSignupClass);
        addClass(this.dom.mainContent, this.classes.activeMainClass);

        if (!this.signupFlowLoaded) {
            this.setupLazyEvents();
        }
    };

    closeSignup = () => {
        removeScrollLock();

        removeClass(this.dom.signupContainer, this.classes.openSignupClass);
        removeClass(this.dom.mainContent, this.classes.activeMainClass);

        removeOnClickOutside(this.dom.signupContainer);

        anime({
            targets: [this.dom.stepsContainer, this.dom.stepsFooter],
            opacity: [1, 0],
            easing: STANDARDCUBICBEZIER,
            duration: MEDIUM,
            delay: 200
        });
    };

    loadSignupOverlay = () => {
        // remove focus from apply button
        this.dom.container.blur();
        if (!this.signupFlowLoaded) {
            this.settings.endPoint && this.fetchData(this.settings.eventId);
        }
        this.openSignup();
    };

    setupLazyEvents = () => {
        // Prevent submitting the form when clicking ENTER when input fields has focus
        addEvent(this.dom.signupForm, "keypress", e => {
            const key = e.charCode || e.keyCode || 0;
            if (e.target.nodeName === "INPUT" && key === 13) {
                e.preventDefault();
            }
        });

        // Closing the signup on close button and cancel button click
        addEvent(this.dom.closeButton, "click", this.closeSignup);

        onClickOutside(
            this.dom.signupContainer,
            ({ target }) => {
                console.log(target);
                // Only close when clicking on overlay and not popups like the cookie banner
                if (target.tagName === "BODY") {
                    this.closeSignup();
                }
            },
            window,
            false
        );

        addEvent(this.dom.continueButton, "click", event => {
            if (
                parseInt(event.target.dataset.stepNumber) <
                    this.settings.totalSteps &&
                parseInt(event.target.dataset.stepNumber) !==
                    this.settings.totalSteps - 1
            ) {
                this.moveSteps(parseInt(event.target.dataset.stepNumber) + 1);
            } else if (
                parseInt(event.target.dataset.stepNumber) ===
                this.settings.totalSteps - 1
            ) {
                this.applyForEvent(event.target);
            } else if (
                parseInt(event.target.dataset.stepNumber) ===
                this.settings.totalSteps
            ) {
                this.closeSignup();

                window.location.reload();
            }
        });

        addEvent(this.dom.backButton, "click", () => {
            if (
                this.currentStep > 1 &&
                this.currentStep < this.settings.totalSteps
            ) {
                this.moveSteps(this.currentStep - 1);
            }
        });
    };

    initialize() {
        this.settings = {
            endPoint: this.dom.signupContainer.dataset.endpoint,
            eventId: this.dom.container.dataset.eventid.replace(/[{}]/g, ""),
            totalSteps: 8,
            stepWidth: null,
            signupContainerWidth: null
        };

        this.volunteerData = {};

        this.currentStep = 1;

        this.signupFlowLoaded = false;

        // Start signup flow
        addEvent(this.dom.container, "click", () => {
            this.loadSignupOverlay();
        });
    }
}
