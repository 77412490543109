import { onReady } from "~/js/utils/events/onReady";
import { forEach } from "~/js/utils/helpers/forEach";
import Flickity from "flickity";

export class EventListRibbon {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;
        this.dom.eventlistRibbonSlider = this.dom.container.querySelector(
            ".event-list-ribbon__carousel"
        );
        this.dom.eventlistRibbonSliderElements =
            this.dom.eventlistRibbonSlider.querySelectorAll(".gallery-cell");

        onReady(() => this.initialize());
    }

    // Checking if we have multiple elements for later use (page dots, buttons etc)
    multipleElementsLayout = () => {
        return this.dom.eventlistRibbonSliderElements.length > 1;
    };

    initialize() {
        const eventlistRibbonFlickity = {
            pageDots: this.multipleElementsLayout(),
            prevNextButtons: false,
            staticClick: true,
            wrapAround: false,
            cellAlign: "left",
            lazyLoad: 3,
            on: {
                ready: () => {
                    forEach(this.dom.eventlistRibbonSliderElements, cell => {
                        cell.style.height = "100%";
                    });
                },
                dragStart: () => {
                    this.dom.container.classList.add("translating");
                },
                dragEnd: () => {
                    this.dom.container.classList.remove("translating");
                },
                settle: () => {
                    this.dom.container.classList.remove("translating");
                }
            }
        };

        // eslint-disable-next-line no-unused-vars
        const eventlistRibbonSlider = new Flickity(
            this.dom.eventlistRibbonSlider,
            eventlistRibbonFlickity
        );
    }
}
