import { onReady } from "~/js/utils/events/onReady";
import { createElement } from "~/js/utils/dom/createElement";
import { addEvent } from "~/js/utils/events/events";
import { enableScrollLock, disableScrollLock } from "~/js/utils/dom/scrollLock";
import {
    appendElement,
    insertElementBefore
} from "~/js/utils/dom/elementManipulation";
import closeSvg from "~/js/components/close/close";
import zoomIcon from "./zoomIcon";
import plusIcon from "./plusIcon";
import minusIcon from "./minusIcon";
import rotationIcon from "./rotationIcon";

export class ImageZoom {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */

    constructor(domReference) {
        this.dom.image = domReference;
        onReady(() => this.initialize());
    }

    closeZoomLightbox = () => {
        this.dom.zoomLightbox.style.display = "none";
        disableScrollLock();
    };

    openZoomLightbox = imgSrc => {
        enableScrollLock();
        const lightbox = this.dom.zoomLightbox;

        if (lightbox) {
            lightbox.style.display = "flex";
            // Use previous zoom value instead of default if lightbox has been created on an earlier render
            if (!lightbox.classList.contains("created")) {
                lightbox.classList.add("created");
                let zoomValue = "100";

                const zoomImgMobile = createElement("div", {
                    className: "zoomImgMobile",
                    id: "zoomImgMobile"
                });
                appendElement(zoomImgMobile, lightbox);
                const zoomImgWrapper = createElement("div", {
                    className: "zoomImgWrapper",
                    id: "zoomImgWrapper"
                });
                appendElement(zoomImgWrapper, zoomImgMobile);
                const zoomImg = createElement("img", {
                    className: "zoomImg",
                    id: "zoomImg",
                    src: `${imgSrc}`,
                    style: `width:${zoomValue}vw`
                });
                appendElement(zoomImg, zoomImgWrapper);

                const imgElement = lightbox.querySelector(".zoomImg");

                const buttonResize = refValue => {
                    const rangeInput = lightbox.querySelector(".rangeElement");

                    const stepVal = 100;
                    let updatedVal = 100;

                    refValue === "plus"
                        ? (updatedVal = parseInt(rangeInput.value) + stepVal)
                        : (updatedVal = parseInt(rangeInput.value) - stepVal);

                    if (updatedVal < 100) {
                        updatedVal = 100;
                    }

                    rangeInput.value = updatedVal.toString();

                    if (imgElement.style.display === "none") {
                        lightbox.querySelector(
                            ".canvasZoomImg"
                        ).style.width = `${updatedVal}vw`;
                    } else {
                        imgElement.style.width = `${updatedVal}vw`;
                    }
                };

                const rangeResize = e => {
                    zoomValue = e.target.value;

                    if (imgElement.style.display === "none") {
                        lightbox.querySelector(
                            ".canvasZoomImg"
                        ).style.width = `${zoomValue}vw`;
                    } else {
                        imgElement.style.width = `${zoomValue}vw`;
                    }
                };

                const rotateView = () => {
                    const rangeInput = lightbox.querySelector(".rangeElement");
                    rangeInput.value = "100";
                    const zoomImg = lightbox.querySelector(".zoomImg");
                    if (zoomImg.style.display === "none") {
                        const canvasZoomImg =
                            lightbox.querySelector(".canvasZoomImg");
                        canvasZoomImg.remove();
                        zoomImg.style.width = "100%";
                        zoomImg.style.display = "flex";
                    } else {
                        const zoomImgWrapper =
                            lightbox.querySelector(".zoomImgWrapper");
                        const heightVal = zoomImg.naturalWidth;
                        const widthVal = zoomImg.naturalHeight;
                        zoomImg.style.display = "none";

                        const canvasZoomImg = createElement("canvas", {
                            className: "canvasZoomImg",
                            width: widthVal,
                            height: heightVal
                        });
                        appendElement(canvasZoomImg, zoomImgWrapper);

                        const ctx = canvasZoomImg.getContext("2d");

                        const rotationAngle = (90 * Math.PI) / 180;

                        const image = new Image();

                        image.src = zoomImg.src;
                        image.onload = () => {
                            ctx.save();
                            ctx.translate(
                                canvasZoomImg.width / 2,
                                canvasZoomImg.height / 2
                            );
                            ctx.rotate(rotationAngle);
                            ctx.drawImage(
                                image,
                                -image.width / 2,
                                -image.height / 2
                            );
                            ctx.restore();
                        };

                        canvasZoomImg.style.width = "100vw";
                    }
                };

                const closeZoomButtonElement = createElement("button", {
                    type: "button",
                    className: "closeZoomButton",
                    html: closeSvg
                });
                addEvent(closeZoomButtonElement, "click", () => {
                    this.closeZoomLightbox();
                });
                appendElement(closeZoomButtonElement, lightbox);

                const controlPanel = createElement("div", {
                    className: "controlPanel"
                });
                appendElement(controlPanel, lightbox);

                const controlPanelRangeWrapper = createElement("div", {
                    className: "controlPanelRangeWrapper"
                });
                appendElement(controlPanelRangeWrapper, controlPanel);

                const zoomButtonMinus = createElement("button", {
                    type: "button",
                    className: "zoomButtonMinus",
                    html: minusIcon
                });
                addEvent(zoomButtonMinus, "click", () => {
                    buttonResize("minus");
                });
                appendElement(zoomButtonMinus, controlPanelRangeWrapper);

                const rangeElement = createElement("input", {
                    className: "rangeElement",
                    type: "range",
                    min: "100",
                    max: "600"
                });
                addEvent(rangeElement, "input", e => {
                    rangeResize(e);
                });
                appendElement(rangeElement, controlPanelRangeWrapper);
                rangeElement.value = rangeElement.min;

                const zoomButtonPlus = createElement("button", {
                    type: "button",
                    className: "zoomButtonPlus",
                    html: plusIcon
                });
                addEvent(zoomButtonPlus, "click", () => {
                    buttonResize("plus");
                });
                appendElement(zoomButtonPlus, controlPanelRangeWrapper);

                const rotationButton = createElement("button", {
                    type: "button",
                    className: "rotationButton",
                    html: rotationIcon
                });
                addEvent(rotationButton, "click", () => {
                    rotateView();
                });
                appendElement(rotationButton, controlPanel);
            }
        }
    };

    initialize() {
        const src = this.dom.image.src || this.dom.image.dataset.src;
        const originalImg = src.split(".jpg", 1);
        const imgSrc = originalImg[0].toString();

        const zoomButtonElement = createElement("button", {
            type: "button",
            className: "imgZoomButton",
            id: `imgZoomButton-${imgSrc}`,
            html: zoomIcon
        });
        addEvent(zoomButtonElement, "click", () => {
            this.openZoomLightbox(imgSrc);
        });

        const zoomLightbox = createElement("div", {
            className: "zoomModal",
            id: `Lightbox-${imgSrc}`,
            style: "display:none"
        });

        this.dom.zoomLightbox = zoomLightbox;

        if (this.dom.image.closest(".imgslider")) {
            const imgslider = this.dom.image.closest(".imgslider");
            this.dom.image.parentElement.appendChild(zoomButtonElement);
            insertElementBefore(zoomLightbox, imgslider);
        } else {
            this.dom.image.parentElement.appendChild(zoomButtonElement);
            this.dom.image.parentElement.parentElement.appendChild(
                zoomLightbox
            );
        }
    }
}
