/*global grecaptcha */
import { injectGoogleApi } from "../../../helpers/reCaptcha";
import { appendElement } from "~/js/utils/dom/elementManipulation";
import { addEvent, removeAllEvents } from "~/js/utils/events/events";
import { ToggleVisiblePassword } from "~/js/components/input/toggle-visible-password";
import { passwordMarkup } from "./markup";
import {
    showError,
    removeErrors,
    removeError
} from "~/js/components/input/input-errors";
import fetcher from "~/js/api/fetcher";
import { makeButtonLoad } from "~/js/components/button/make-button-load";

export class Password {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {};

    /**
     *
     * @param {Element} domReference - The element to work from. -> membership__content
     */
    constructor(config) {
        this.dom.membershipContent = config.membershipContent;
        this.texts = config.texts;
        this.user = config.user ? config.user : {};
        this.errorMessages = config.errorMessages;
        this.isAutomatedTest = config.isAutomatedTest;
        this.signupApiUrl = config.signupApiUrl;
        this.captchaId = 0;

        this.callbacks = {
            onSignupSuccess: config.onSignupSuccess
        };

        this.initialize();
    }

    onChangeTerms(e) {
        const checkbox = e.target;

        if (checkbox.checked) {
            removeError(checkbox);
        } else {
            showError(checkbox, this.errorMessages.acceptTermsRequired);
        }
    }

    onPasswordFocus(e) {
        const password = e.target;
        removeError(password);
    }

    captchaCallback = token => {
        return new Promise((resolve, reject) => {
            this.user.reCaptchaToken = token;

            const buttonLoader = new makeButtonLoad(
                this.dom.continueButton,
                this.texts.loading
            );
            buttonLoader.load();

            fetcher(this.signupApiUrl, "POST", this.user).then(result => {
                buttonLoader.finish(true).then(() => {
                    grecaptcha.reset(this.captchaId);
                    if (result.success) {
                        this.togglePasswordVisibility.kill();
                        this.callbacks.onSignupSuccess();
                    } else {
                        result.errors.map(error =>
                            showError(this.dom.passwordInput, error.message)
                        );
                        reject();
                    }
                    resolve();
                });
            });
        });
    };

    submitSignup = () => {
        return new Promise((resolve, reject) => {
            const buttonLoader = new makeButtonLoad(
                this.dom.continueButton,
                this.texts.loading
            );
            buttonLoader.load();

            fetcher(this.signupApiUrl, "POST", this.user).then(result => {
                buttonLoader.finish(true).then(() => {
                    if (result.success) {
                        this.togglePasswordVisibility.kill();
                        this.callbacks.onSignupSuccess();
                    } else {
                        result.errors.map(error =>
                            showError(this.dom.passwordInput, error.message)
                        );
                        reject();
                    }
                    resolve();
                });
            });
        });
    };

    expiredCallback = expired => {
        //No idea what to do here yet
        console.log("expiredCallback", expired);
    };

    errorCallback = error => {
        //No idea what to do here yet
        console.log("errorCallback", error);
    };

    validatePasswordAndSignup = () => {
        if (this.dom.passwordInput.value === "") {
            showError(
                this.dom.passwordInput,
                this.errorMessages.passwordRequired
            );
            return;
        } else {
            removeErrors();
        }

        if (!this.dom.termsCheckbox.checked) {
            showError(
                this.dom.termsCheckbox,
                this.errorMessages.acceptTermsRequired
            );
            return;
        }

        this.user.password = this.dom.passwordInput.value;
        this.user.acceptTerms = true;
        this.user.acceptSubscribe = this.dom.subscribeCheckbox.checked;

        removeAllEvents(this.dom.termsCheckbox);

        if (this.isAutomatedTest) {
            this.submitSignup();
        } else {
            grecaptcha.execute(this.captchaId);
        }
    };

    initialize() {
        const passwordHtml = passwordMarkup(this.texts);

        appendElement(passwordHtml, this.dom.membershipContent);
        const passwordToggleButton = this.dom.membershipContent.querySelector(
            ".toggle-visible-password"
        );
        this.togglePasswordVisibility = new ToggleVisiblePassword(
            passwordToggleButton
        );
        this.dom.membershipContent.className =
            "membership__content membership__content--signup-password";
        this.dom.continueButton = this.dom.membershipContent.querySelector(
            ".membership-footer .button"
        );

        this.dom.passwordInput =
            this.dom.membershipContent.querySelector("#password");
        this.dom.termsCheckbox =
            this.dom.membershipContent.querySelector("#terms");
        this.dom.subscribeCheckbox =
            this.dom.membershipContent.querySelector("#subscribe");

        if (this.user.acceptTerms) {
            this.dom.termsCheckbox.checked = this.user.acceptTerms;
        }

        if (this.user.acceptSubscribe) {
            this.dom.subscribeCheckbox.checked = this.user.acceptSubscribe;
        }

        this.dom.membershipContent.autocomplete = "off";

        addEvent(
            this.dom.termsCheckbox,
            "change",
            this.onChangeTerms.bind(this)
        );
        addEvent(this.dom.passwordInput, "focus", this.onPasswordFocus);

        const captchaElement =
            this.dom.membershipContent.querySelector(".captcha");

        if (typeof grecaptcha === "undefined") {
            injectGoogleApi();
        }

        const timerId = setInterval(() => {
            if (this.isAutomatedTest) {
                this.dom.continueButton.disabled = false;

                addEvent(
                    this.dom.continueButton,
                    "click",
                    this.validatePasswordAndSignup
                );

                clearInterval(timerId);
            } else if (
                typeof grecaptcha !== "undefined" &&
                grecaptcha.render &&
                captchaElement
            ) {
                this.dom.continueButton.disabled = false;

                this.captchaId = grecaptcha.render(captchaElement, {
                    sitekey: "6Lf9a70ZAAAAAPZLngHjH6UKXN3_NqGZrkYqAe3t",
                    badge: "bottomright",
                    theme: "light",
                    size: "invisible",
                    hl: "en",
                    callback: this.captchaCallback,
                    "expired-callback": this.expiredCallback,
                    "error-callback": this.errorCallback
                });

                addEvent(
                    this.dom.continueButton,
                    "click",
                    this.validatePasswordAndSignup
                );

                clearInterval(timerId);
            }
        }, 824);
    }
}
