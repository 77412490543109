import { parseHTML } from "~/js/utils/dom/elementManipulation";
import { format, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { getLocalTimeZone } from "~/js/utils/helpers/getLocalTimeZone";
import { isRtl } from "~/js/utils/helpers/isRtl";
import { arSA } from "date-fns/locale";

/**
 * Extracts the characters between 2 characters in a string
 *
 * @param data - the string to extract characters from
 * @param firstChar
 * @param secondChar
 * @returns {string}
 */
const charExtractor = (data, firstChar, secondChar) => {
    return data.substring(
        data.indexOf(firstChar) + 1,
        data.indexOf(secondChar)
    );
};

/**
 * Formats the date string to the local timezone.
 *
 * @param dateString
 * @returns {string}
 */
const setDateString = dateString => {
    const localTimeZone = getLocalTimeZone;
    const formatOptions = isRtl ? { locale: arSA } : "";

    const formattedDate = format(
        utcToZonedTime(parseISO(dateString), localTimeZone),
        "d MMMM HH:mm",
        formatOptions
    );

    return `${formattedDate}`;
};

/**
 * Formats the datestring to include translations for day(s), hour(s) and minute(s) in given languages.
 * Also distinguishes between 1 or more units of given time.
 *
 * @param dateString
 * @param translations
 * @returns {string}
 */
const translateDateString = (dateString, translations = {}) => {
    let formattedDate;
    let totalDays = "";
    let totalHours = "";
    let totalMinutes = "";

    if (dateString.match(/([dD])/)) {
        const daysValue = charExtractor(dateString, "w", "d");
        totalDays = `${daysValue} ${
            parseInt(daysValue) === 1 ? translations.day : translations.days
        }`;
    }

    if (dateString.match(/([hH])/)) {
        const hoursValue = charExtractor(dateString, "d", "h");
        totalHours = `${hoursValue} ${
            parseInt(hoursValue) === 1 ? translations.hour : translations.hours
        }`;
    }

    if (dateString.match(/([mM])/)) {
        const minutesValue = charExtractor(dateString, "h", "m");
        totalMinutes = `${minutesValue} ${
            parseInt(minutesValue) === 1
                ? translations.minute
                : translations.minutes
        }`;
    }

    formattedDate = `${totalDays} ${totalHours} ${totalMinutes}`;

    return formattedDate;
};

/**
 * If the datestring from the feed contains a 'w' character we know the post is over a week old.
 * Our logic dictates that in such cases the date should be shown as the local datetime.
 *
 * @param data
 * @param translations
 * @returns {string}
 */
const chooseDateFormat = (data, translations) => {
    if (data.postDateString.match(/([Ww])/)) {
        return setDateString(data.postDate);
    }

    return translateDateString(data.postDateString, translations);
};

export const socialWallItemMarkup = (
    data,
    translations,
    socialIcons,
    platformLabels
) => {
    const {
        platform,
        postContent,
        postLink,
        postImage,
        postVideo,
        sourceLink
    } = data;

    let svgIcon, platformLabel;

    Object.keys(socialIcons).forEach(item => {
        if (platform === item) {
            svgIcon = socialIcons[item];
        }
    });

    Object.keys(platformLabels).forEach(label => {
        if (platform === label) {
            if (isRtl && platformLabels[label].ar !== "") {
                platformLabel = platformLabels[label].ar;
            } else {
                platformLabel = platform;
            }
        }
    });

    const postImageMarkup = `<div class="social-wall__visual__image"><img src=${postImage} /></div>`;
    const postVideoMarkup = `<div class="social-wall__visual__image social-wall__visual__image--bg" ${
        postImage ? `style="background-image: url(${postImage})"` : ""
    }><video controls><source src=${postVideo}></video></div>`;
    const contentMarkupWithVisual = `
    ${postVideo ? postVideoMarkup : postImage ? postImageMarkup : ""}
    <div class="social-wall__content">
        <div class="social-wall__content__meta details">
            <a data-element="socialMediaFeed" class="social-wall__content__source" href="${sourceLink}" target="_blank">
                ${svgIcon} <span>${platformLabel}</span>
            </a>
            <div class="social-wall__content__date">
                <time>${chooseDateFormat(data, translations)}</time>
            </div>
        </div>
        <a data-element="socialMediaFeed" href=${
            postLink ? postLink : sourceLink
        } target="_blank" class="social-wall__content__text">
            <h4 class="copy">${postContent}</h4>
        </a>
    </div>`;
    const contentMarkupWithoutVisual = `
    <div class="social-wall__content">
        <div class="social-wall__content-copy">
            <div class="social-wall__content__meta">
                <a data-element="socialMediaFeed" class="social-wall__content__source" href="${sourceLink}" target="_blank">
                    ${svgIcon} <span>${platformLabel}</span>
                </a>
                <div class="social-wall__content__date">
                    <time>${chooseDateFormat(data, translations)}</time>
                </div>
            </div>
            <a data-element="socialMediaFeed" href=${
                postLink ? postLink : sourceLink
            } target="_blank" class="social-wall__content__text">
                <h4>${postContent}</h4>
            </a>
        </div>
    </div>`;

    return parseHTML(`
        <div class="social-wall__item inview">
                
                ${
                    postImage || postVideo
                        ? contentMarkupWithVisual
                        : contentMarkupWithoutVisual
                } 

        </div>
    `);
};
