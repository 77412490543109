import { onReady } from "~/js/utils/events/onReady";
import { delegateEvent, removeAllEvents } from "../../utils/events/events";
import { hasClass } from "../../utils/dom/classList";
import fetcher from "../../api/fetcher";
import {
    appendElement,
    deleteElement,
    parseHTML
} from "../../utils/dom/elementManipulation";
import { forEach } from "../../utils/helpers/forEach";

export class ShortcutNavigation {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;

        this.isLoggedIn = hasClass(document.body, "logged-in");

        this.endPoints = {
            main: this.dom.container.dataset.mainEndpoint,
            start: this.dom.container.dataset.startEndpoint,
            save: this.dom.container.dataset.saveEndpoint
        };

        onReady(() => this.initialize());
    }

    buildShortcutContainer = () => {
        return `
            <div class="shortcut-navigation__wrapper">
                <div class="shortcut-navigation__list" data-count="${
                    this.shortcutData.menuItems.length
                }">
                    ${this.shortcutData.menuItems
                        .map(shortcutItem => {
                            return this.buildShortcutItem(shortcutItem);
                        })
                        .join("")}
                </div>
            </div>
        `;
    };

    buildShortcutItem = shortcutItem => {
        return `
            <a href="${shortcutItem.url}" title="${
            shortcutItem.title
        }" class="shortcut-navigation__item">
                ${
                    shortcutItem.isSvg
                        ? shortcutItem.svgContent
                        : `<img src="${shortcutItem.iconUrl}" alt="${shortcutItem.title}" />`
                }
                <span>${shortcutItem.title}</span>
            </a>
        `;
    };

    buildEditButton = () => {
        return `<button class="button button--secondary shortcut-navigation__item shortcut-navigation__edit-button">[EDIT]</button>`;
    };

    buildEditOverlay = () => {
        return `<div class="shortcut-navigation-overlay"><div class="shortcut-navigation-overlay__container"></div></div>`;
    };

    setupShortcutNavigation = () => {
        fetcher(this.endPoints.main).then(({ data }) => {
            this.shortcutData = data;

            if (data.menuItems.length > 0) {
                appendElement(
                    parseHTML(this.buildShortcutContainer()),
                    this.dom.container
                );

                this.dom.shortcutNavigation = this.dom.container.querySelector(
                    ".shortcut-navigation__wrapper"
                );
                this.dom.shorcutNavItems =
                    this.dom.shortcutNavigation.querySelectorAll(
                        ".shortcut-navigation__item"
                    );

                let itemWidth = 0;

                forEach(this.dom.shorcutNavItems, navItem => {
                    if (navItem.clientWidth > itemWidth) {
                        itemWidth = navItem.clientWidth;
                    }
                });

                forEach(this.dom.shorcutNavItems, navItem => {
                    navItem.style.width = `${itemWidth}px`;
                });
            }
            // USED FOR PERSONALISATION
            /*if (this.isLoggedIn) {
                appendElement(
                    parseHTML(this.buildEditButton()),
                    this.dom.container.querySelector(
                        ".shortcut-navigation__list"
                    )
                );

                this.setupEditEvents();
            }*/
        });
    };

    setupOverlay = () => {
        fetcher(this.endPoints.main).then(({ data }) => {
            this.shortcutData = data;

            appendElement(parseHTML(this.buildEditOverlay()), document.body);
        });
    };

    saveData = () => {
        this.killOverlay();
    };

    setupEditEvents = () => {
        delegateEvent(".shortcut-navigation__edit-button", "click", () => {
            this.setupOverlay();
        });
    };

    killOverlay = () => {
        const overlayNode = document.body.querySelector(
            ".shortcut-navigation-overlay"
        );

        if (overlayNode) {
            removeAllEvents(overlayNode);
            deleteElement(overlayNode);
        }
    };

    initialize() {
        this.shortcutData = {};

        this.setupShortcutNavigation();
    }
}
