import { parseHTML } from "~/js/utils/dom/elementManipulation";
import { teaserImageMarkup } from "./teaserImageMarkup";
import { hasClass } from "../../../utils/dom/classList";

export const teaserMarkup = dataObject => {
    const isLoggedInMemberInternal = hasClass(
        document.body,
        "logged-in--internal"
    );
	
	const isLoggedInMemberRetiree = hasClass(
        document.body,
        "logged-in--retiree"
    );

    return parseHTML(`
    <div class="article-teaser article-teaser--${
        dataObject.teaserSize && dataObject.teaserSize !== "small"
            ? "large"
            : "small"
    }">
        <a ${
        dataObject.isInternal ?
            isLoggedInMemberInternal || isLoggedInMemberRetiree
                ? `href="${dataObject.url}"`
                : `data-href="${dataObject.url}"`
            : `href="${dataObject.url}"`
        } class="restrict-content"
        ${
        dataObject.isInternal && !isLoggedInMemberInternal && !(isLoggedInMemberRetiree && dataObject.isTravelArticle)
            ? " data-restrict data-barba-prevent=self"
            : ""
    }>
            <div class="article-teaser__thumbnail article-teaser__thumbnail--${
                dataObject.teaserSize && dataObject.teaserSize !== "small"
                    ? "large"
                    : "small"
            } inview">
                ${teaserImageMarkup(dataObject)}
            </div>

            <div class="article-teaser__content">
                <div class="article-teaser__content-wrapper">
                    <ul class="article-list inview">
                        <li class="article-list__item">
                            ${dataObject.formattedDate}
                        </li>
                    </ul>
                    <div class="article-teaser__text-wrapper align-center">
                        <h3 class="title inview">
                            ${
                                dataObject.listTeaserTitle
                                    ? dataObject.listTeaserTitle
                                    : dataObject.title
                            }
                        </h3>
                        ${
                            dataObject.teaserSize &&
                            dataObject.teaserSize !== "small"
                                ? `<div class="txt inview">${
                                      dataObject.listTeaserText
                                          ? dataObject.listTeaserText
                                          : dataObject.teaser
                                  }</div>`
                                : ""
                        }
                    </div>
                </div>
                ${
                    dataObject.isInternal
                        ? `<div class="restrict-content__indication article-teaser__restriction">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
                        <path d="M6,7.09c-2,0-6,1.1-6,3.27V12H12V10.36C12,8.19,8,7.09,6,7.09Zm1.32-2a2.73,2.73,0,1,0-2.64,0,2.73,2.73,0,0,0,2.64,0Z"></path>
                    </svg>
                    <p></p>
                </div>`
                        : ""
                }
            </div>
        </a>
</div>`);
};
