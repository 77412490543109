import { onReady } from "~/js/utils/events/onReady";
import { removeClass, addClass } from "~/js/utils/dom/classList";
import { enableScrollLock, disableScrollLock } from "~/js/utils/dom/scrollLock";
import { addEvent, removeEvent } from "~/js/utils/events/events.js";

export class PhotoCompetitionOpenPhotoModal {
    dom = {};
    dictionary = {};
    galleryItem = null;

    likeablePhoto = false;

    constructor(
        domReference,
        dictionary,
        photoCompetitionService,
        likeablePhoto,
        listLikeElement,
        { onLike, onRemoveLike }
    ) {
        this.dictionary = dictionary;
        this.photoCompetitionService = photoCompetitionService;
        this.likeablePhoto = likeablePhoto;

        this.onLikeStyle = onLike;
        this.onRemoveLikeStyle = onRemoveLike;

        // View
        // src\Feature\PhotoCompetition\code\Areas\PhotoCompetition\Views\PhotoCompetition\PhotoCompetitionPhotoModal.cshtml

        this.dom.container = domReference;
        this.dom.modal = document.querySelector(
            "[data-photo-competition-photo-modal]"
        );
        this.dom.closeButton = this.dom.modal.querySelector(
            "[data-close-photo-modal]"
        );
        this.dom.photoImg = this.dom.modal.querySelector("[data-photo-img]");
        this.dom.photoName = this.dom.modal.querySelector("[data-photo-name]");
        this.dom.photoDescription = this.dom.modal.querySelector(
            "[data-photo-description]"
        );
        this.dom.photoLikes =
            this.dom.modal.querySelector("[data-photo-likes]");
        this.dom.listLikeElement = listLikeElement;

        this.dom.svgContent =
            '<svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="love [#1489] copy 6" fill-rule="evenodd" clip-rule="evenodd" d="M11.9923 13.7587C11.4443 14.2245 10.7403 14.4815 10.0093 14.4815C9.27934 14.4815 8.57334 14.2255 8.00334 13.7447C4.40934 10.7169 2.12134 9.11046 2.00234 5.81569C1.87734 2.329 5.69734 1.05447 8.03934 3.9194C8.51334 4.49818 9.22534 4.83006 9.99334 4.83006C10.7683 4.83006 11.4853 4.49419 11.9603 3.9084C14.2513 1.08946 18.1733 2.29001 17.9943 5.91765C17.8343 9.14445 15.4583 10.8108 11.9923 13.7587ZM10.3863 2.71985C10.2883 2.8408 10.1403 2.90178 9.99334 2.90178C9.84934 2.90178 9.70634 2.8428 9.61034 2.72585C6.06734 -1.60753 -0.188658 0.550661 0.003342 5.88267C0.156342 10.1261 3.16434 12.2293 6.70634 15.2132C7.65334 16.0109 8.83134 16.4097 10.0093 16.4097C11.1903 16.4097 12.3703 16.0089 13.3153 15.2052C16.8183 12.2253 19.7883 10.1361 19.9913 6.00862C20.2613 0.557658 13.9203 -1.63152 10.3863 2.71985Z" fill="#00a3e0"/></svg>';
        this.dom.svgContentLiked =
            '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.3863 2.81012C10.2883 2.93108 10.1403 2.99205 9.99334 2.99205C9.84934 2.99205 9.70634 2.93307 9.61034 2.81612C6.06734 -1.51726 -0.188658 0.640932 0.003342 5.97294C0.156342 10.2164 3.16434 12.3196 6.70634 15.3034C7.65334 16.1011 8.83134 16.5 10.0093 16.5C11.1903 16.5 12.3703 16.0991 13.3153 15.2955C16.8183 12.3156 19.7883 10.2263 19.9913 6.09889C20.2613 0.647929 13.9203 -1.54125 10.3863 2.81012Z" fill="white"/></svg>';

        if (this.likeablePhoto) {
            this.dom.photoLikes.style.display = "flex";
        } else {
            this.dom.photoLikes.style.display = "none";
        }

        this.dom.galleryListImg = this.dom.container.querySelector("img");
        this.dom.galleryListName = this.dom.container.querySelector(
            "[data-gallery-name]"
        );
        this.dom.galleryListDescription = this.dom.container.querySelector(
            "[data-gallery-description]"
        );

        this.galleryItem = {
            id: this.dom.container.dataset.photoId,
            name: this.dom.galleryListName.innerHTML,
            description: this.dom.galleryListDescription.innerHTML,
            srcBig: this.dom.galleryListImg.dataset.gallerySrcBig,
            liked: this.dom.container.dataset.galleryItemLiked === "true",
            likesCount: parseInt(this.dom.container.dataset.likesCount),
        };

        onReady(() => {
            this.initialize().catch((error) => {
                console.error("Error", error);
            });
        });
    }

    async initialize() {
        const onLike = async () => {
            await this.onLike();
            this.setPhotoData();
        };

        const photoLoad = () => {
            const ratio =
                this.dom.photoImg.getBoundingClientRect().width /
                this.dom.photoImg.getBoundingClientRect().height;

            if (ratio > 0.82) {
                addClass(this.dom.modal, "default-ratio");
            }

            addClass(this.dom.modal, "photo-competition__photo-modal--show");
        };

        const closeModal = () => {
            removeClass(this.dom.modal, "photo-competition__photo-modal--open");
            removeClass(this.dom.modal, "photo-competition__photo-modal--show");
            removeClass(this.dom.modal, "default-ratio");

            disableScrollLock();

            this.dom.photoImg.removeEventListener("load", photoLoad);

            if (this.likeablePhoto) {
                this.dom.photoLikes.removeEventListener("click", onLike);
            }
        };

        this.dom.galleryListImg.addEventListener("click", async () => {
            addClass(this.dom.modal, "photo-competition__photo-modal--open");

            enableScrollLock();

            this.galleryItem.liked =
                this.dom.container.dataset.galleryItemLiked === "true";
            this.galleryItem.likesCount = parseInt(
                this.dom.container.dataset.likesCount
            );

            if (this.likeablePhoto) {
                this.dom.photoLikes.addEventListener("click", onLike);
            }

            this.setPhotoData();

            this.dom.photoImg.addEventListener("load", photoLoad);

            const keyup = (e) => {
                const key = e.charCode || e.keyCode || 0;

                if (key === 27) {
                    closeModal();
                    removeEvent(document, "keyup", keyup);
                }
            };

            addEvent(document, "keyup", keyup);
        });

        this.dom.closeButton.addEventListener("click", closeModal);
    }

    async onLike() {
        if (this.galleryItem) {
            if (this.galleryItem.liked) {
                await this.photoCompetitionService.removeLikePhoto(
                    this.galleryItem.id
                );
                this.galleryItem.likesCount--;
                this.galleryItem.liked = false;

                if (typeof this.onRemoveLikeStyle === "function") {
                    this.onRemoveLikeStyle(
                        this.dom.listLikeElement,
                        this.galleryItem.likesCount
                    );
                }

                this.dom.container.dataset.galleryItemLiked = "false";
                this.dom.container.dataset.likesCount =
                    this.galleryItem.likesCount.toString();
            } else {
                await this.photoCompetitionService.likePhoto(
                    this.galleryItem.id
                );
                this.galleryItem.liked = true;
                this.galleryItem.likesCount++;

                if (typeof this.onLikeStyle === "function") {
                    this.onLikeStyle(
                        this.dom.listLikeElement,
                        this.galleryItem.likesCount
                    );
                }

                this.dom.container.dataset.galleryItemLiked = "true";
                this.dom.container.dataset.likesCount =
                    this.galleryItem.likesCount.toString();
            }
        }
    }

    setPhotoData() {
        this.dom.photoImg.setAttribute("src", this.galleryItem.srcBig);
        this.dom.photoName.innerHTML = this.galleryItem.name;
        this.dom.photoDescription.innerHTML = this.galleryItem.description;
        this.dom.photoLikes.innerHTML = `${this.galleryItem.likesCount} ${
            this.galleryItem.likesCount === 1
                ? this.dictionary.like
                : this.dictionary.likes
        } ${
            this.galleryItem.liked
                ? this.dom.svgContentLiked
                : this.dom.svgContent
        }`;
        // this.dom.photoLikes.innerHTML = `${this.galleryItem.liked ? this.dom.svgContentLiked : this.dom.svgContent}`;
        if (this.galleryItem.liked) {
            addClass(
                this.dom.photoLikes,
                "photo-competition__like-button--liked"
            );
        } else {
            removeClass(
                this.dom.photoLikes,
                "photo-competition__like-button--liked"
            );
        }
    }
}
