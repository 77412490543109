import { onReady } from "~/js/utils/events/onReady";

export class AiChat {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        iframe: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.iframe = domReference;

        onReady(() => this.initialize(domReference));
    }

    initialize(domReference) {
        // contentHeight sets an arbitrary default
        // then keeps track of the last size update
        // to avoid setting height again if nothing changes
        let contentHeight = 400;
        domReference.height = contentHeight;

        window.addEventListener(
            "message",
            function (e) {
                // return early on messages not from the expected source
                // if (!event.origin.match("*")) {
                //     console.log("not from the expected source");
                //     return;
                // }

                // message that was passed from iframe page
                let message = e.data;
                // before I update the height,
                // I check to see if it's defined
                // and if it's changed
                if (message.height > 0 && message.height !== contentHeight) {
                    domReference.height = message.height + "px";
                    contentHeight = message.height;
                }
            },
            false
        );
    }
}
