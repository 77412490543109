import { appendElement } from "~/js/utils/dom/elementManipulation";
import { addEvent, removeAllEvents } from "~/js/utils/events/events";
import { confirmationMarkup } from "./markup";
import fetcher from "~/js/api/fetcher";

export class Confirmation {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {};

    /**
     *
     * @param {Element} domReference - The element to work from. -> membership__content
     */
    constructor(config) {
        this.dom.membershipContent = config.membershipContent;
        this.texts = config.texts;
        this.user = config.user ? config.user : {};
        this.errorMessages = config.errorMessages;
        this.resendSignupEmailApiUrl = config.resendSignupEmailApiUrl;

        this.callbacks = {
            onResendEmailSuccess: config.onResendEmailSuccess
        };

        this.initialize();
    }

    initialize() {
        const confirmationHtml = confirmationMarkup(this.texts);

        appendElement(confirmationHtml, this.dom.membershipContent);
        this.dom.membershipContent.className =
            "membership__content membership__content--signup-confirmation";
        this.dom.resendEmailButton = this.dom.membershipContent.querySelector(
            ".membership-footer .button-link"
        );

        addEvent(this.dom.resendEmailButton, "click", () => {
            fetcher(this.resendSignupEmailApiUrl, "POST", this.user).then(
                // eslint-disable-next-line no-unused-vars
                result => {
                    this.callbacks.onResendEmailSuccess();
                    removeAllEvents(this.dom.resendEmailButton);
                }
            );
        });
    }
}
