import { onReady } from "~/js/utils/events/onReady";
import Flickity from "flickity";

export class Carousel {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;
        this.dom.carousel =
            this.dom.container.querySelector(".imgslider_slider");
        onReady(() => this.initialize());
    }

    initialize() {
        const carouselFlickity = {
            pageDots: true,
            //freeScroll: true,
            wrapAround: true,
            cellAlign: "center",
            prevNextButtons: false,
            lazyLoad: true
        };

        // eslint-disable-next-line no-unused-vars
        const carouselSlider = new Flickity(
            this.dom.carousel,
            carouselFlickity
        );
    }
}
