import { parseHTML } from "~/js/utils/dom/elementManipulation";
import peopleIcon from "~/js/components/people/people";

export const dependentMarkup = (labels, user, dependentsLists) => {
    const {
        manageHostDependencyButtonLabel,
        managedependentsButtonLabel,
        manageDependentsActiveLabel
    } = labels;

    if (user.showManageHostDependency) {
        return parseHTML(`
        <div>
            <hr />
            <div class="animate-translate">
                <button type="button" class="animate-fade button-link button-link--secondary button--icon" data-status="dependency">${
                    manageHostDependencyButtonLabel ? peopleIcon : ""
                } ${manageHostDependencyButtonLabel}</button>
            </div>
        </div>
        `);
    }

    return parseHTML(`
        <div>
            <hr />
            <div class="animate-translate">
                <button type="button" class="animate-fade button-link button-link--secondary button--icon" data-status="dependency">${
                    managedependentsButtonLabel ? peopleIcon : ""
                } ${managedependentsButtonLabel}</button>
                <span class="dependent__active-users">
                    ${
                        dependentsLists
                            ? manageDependentsActiveLabel.replace(
                                  "{0}",
                                  dependentsLists.length
                              )
                            : ""
                    }
                </span>
            </div>
        </div>
    `);
};
