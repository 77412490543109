import { parseHTML } from "~/js/utils/dom/elementManipulation";
import { pollStatusTypes } from "../poll-list";

export const pollListItemMarkup = (data, labels) => {
    const { id, question, status, endDate, userHasAlreadyVotedOnPoll } = data;

    return parseHTML(`
        <div class="poll-list__item inview">
        <div class="poll-list__meta">
            <span>${
                status === pollStatusTypes.OPEN ? labels.active : labels.closed
            }</span>
            ${
                endDate
                    ? `<time
                        data-async-module="local-datetime"
                        data-utc-start="${endDate}"
                        data-date-format="date"
                        class="poll-list__date">
                    </time>`
                    : ``
            }
            </div>
            <h2>${question}</h2>
            ${
                status === pollStatusTypes.OPEN && !userHasAlreadyVotedOnPoll
                    ? `<button type="button" class="button button--primary" data-poll-id="${id}"><span>${labels.clickVote}</span></button>`
                    : `
                    <button type="button" class="button button--secondary" data-poll-id="${id}"><span>${labels.viewResults}</span></button>
                    `
            }
        </div>
    `);
};
