import { onReady } from "~/js/utils/events/onReady";
import { addEvent } from "~/js/utils/events/events";
import { hasClass } from "~/js/utils/dom/classList";

export class ForcePageChange {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;

        onReady(() => this.initialize());
    }

    initialize() {
        addEvent(this.dom.container, "click", event => {
            if (!hasClass(document.body, "logged-in")) {
                event.preventDefault();

                window.location.href = event.target.href;
            }
        });
    }
}
