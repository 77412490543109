import { parseHTML } from "~/js/utils/dom/elementManipulation";
import viewPasswordSvg from "~/js/components/view-password/view-password";
import lockIcon from "~/js/components/lock/lock";

export const passwordMarkup = labels => {
    const {
        oldPasswordLabel,
        newPasswordLabel,
        changePasswordLabel,
        saveButtonLabel,
        cancelButtonLabel,
        passwordHelpText
    } = labels;

    return parseHTML(`
    <div>
        <div class="enter-password visuallyhidden">
            <div class="animate-translate input">
                <input autocomplete="off" required type="password" id="oldPassword" class="password" />
                <label for="oldPassword">${oldPasswordLabel}</label>
                <span class="error"></span>
                <button class="toggle-visible-password" type="button">${viewPasswordSvg}</button>
            </div>
            <div class="animate-translate input">
                <input autocomplete="off" required type="password" id="newPassword" class="password" />
                <label for="newPassword">${newPasswordLabel}</label>
                <span class="error"></span>
                <button class="toggle-visible-password" type="button">${viewPasswordSvg}</button>
            </div>
            <div class="animate-translate password-guide">
                ${passwordHelpText}
            </div>
            <div class="animate-translate buttons">
                <button type="button" class="animate-fade button button--secondary button--secondary-white cancel-change-password">${cancelButtonLabel}</button>
                <button type="button" class="animate-fade button button--secondary button--secondary-white save-password">${saveButtonLabel}</button>
            </div>
        </div>
        <span class="change-password">
            <button type="button" class="animate-translate change-password-button button-link button-link--secondary button--icon">
            ${lockIcon} ${changePasswordLabel}</button>
        </span>
    </div>`);
};
