import { parseHTML } from "~/js/utils/dom/elementManipulation";

/**
 * String parsed as HTML for the filters. The data and translations are parsed from an API response.
 *
 * @param {json} data - JSON object from API response containing the data from Sitecore
 * @param {json} translations - JSON object from API response containing the translations from the dictionary in Sitecore
 * @returns {HTML} String parsed as HTML
 */
export const filterMarkup = (data, translations) => {
    const filters = data[Object.keys(data)[0]];
    const arrowSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.84 4.76"><path d="M8.46,1.59,4.54,5.75h0L.61,1.59,1.19,1,4.54,4.53,7.88,1Z" transform="translate(-0.61 -0.98)" /></svg>`;

    return parseHTML(
        `<section class="admin-filters">
            <div class="admin-filters__button-wrapper admin-filters__button-wrapper--row">
                <button class="button button--secondary admin-filters__button">${
                    filters.title
                } <span class="admin-filters__filter-count"></span>${arrowSvg}</button>
                <button class="admin-filters__action-button admin-filters__action-button--remove admin-filters__action-button--hide">${
                    translations.removeFiltersButtonLabel
                }</button>
                <div class="admin-filters__list-count"></div>
            </div>
            <div class="admin-filters__container" hidden="hidden">
                <div class="admin-filters__inner">
                    ${filters.fields
                        .map(filter => {
                            return `
                            <div class="admin-filters__filter-group">
                                <h4>${filter.label}</h4>
                                ${buildFilterGroup(filter.options, filter.key)}
                            </div>`;
                        })
                        .join("")}
                </div>
                <div class="admin-filters__button-wrapper">
                    <button class="admin-filters__action-button admin-filters__action-button--apply">${
                        translations.applyFiltersButtonLabel
                    }</button>
                </div>
            </div>
        </section>`
    );
};

/**
 * Generic filter options loop that creates HTML ready string with every filter parsed as parameter and grouping them by the [key] parameter
 *
 * @param {json} options - Object containing all the filter options
 * @param {json} key - Object containing the keys for matching the radio groups
 * @returns {string} Every filter set up in Sitecore as HTML ready string
 */
const buildFilterGroup = (options, key) => {
    return `
            ${options
                .map(option => {
                    return `
                    <div class="admin-filters__filter-item">
                        <label>
                            <input type="checkbox"
                                name="${option.value}"
                                data-section="${key}"
                                data-value="${option.value}"
                            />
                            <span>${option.label}</span>
                        </label>
                    </div>`;
                })
                .join("")}`;
};
