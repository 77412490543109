import { onReady } from "~/js/utils/events/onReady";
import {
    addEvent,
    delegateEvent,
    removeAllEvents
} from "~/js/utils/events/events";
import {
    addClass,
    hasClass,
    removeClass,
    toggleClass
} from "~/js/utils/dom/classList";
import fetcher from "~/js/api/fetcher";
import { getParameter } from "~/js/utils/helpers/queryString";
import { forEach } from "~/js/utils/helpers/forEach";
import {
    prependElement,
    parseHTML,
    deleteElement,
    appendElement
} from "~/js/utils/dom/elementManipulation";
import anime from "animejs";
import { format, parseISO } from "date-fns";
import { STANDARDCUBICBEZIER } from "~/js/constants/easings";
import { MEDIUM } from "~/js/constants/durations";
import { addLoader } from "~/js/components/loader/loader";
import { applicantOverviewMarkup } from "~/js/components/volunteer/owner-event-tabs/markup/applicantOverviewMarkup";
import {
    removeScrollLock,
    setScrollLock
} from "../../../utils/helpers/extendedScrollLock";
import {
    removeAttributes,
    setAttributes
} from "../../../utils/dom/elementProperties";
import { filterMarkup } from "./markup/filterMarkup";
import { arSA } from "date-fns/locale";
import { isRtl } from "../../../utils/helpers/isRtl";
import { Snackbar } from "../../snackbar/snackbar";
import { scrollTo } from "../../../utils/dom/scrollTo";

export class OwnerEventTabs {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    classes = {
        rootClass: "owner-event-tabs"
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;

        // Root elements more or less
        this.dom.tabsNavigationCollection = this.dom.container.querySelectorAll(
            `.${this.classes.rootClass}__tab-navigation-item`
        );

        this.dom.ownerContainer = this.dom.container.querySelector(
            `.${this.classes.rootClass}__container`
        );

        this.dom.eventInfoTab = this.dom.container.querySelector(
            "[data-tab='event-info']"
        );

        this.dom.applicantTab = this.dom.container.querySelector(
            "[data-tab='applicants']"
        );

        this.dom.volunteerTab = this.dom.container.querySelector(
            "[data-tab='volunteers']"
        );

        this.dom.tabContainer = this.dom.container.querySelector(
            `.${this.classes.rootClass}__tabs-wrapper`
        );

        this.dom.tabContentList = this.dom.container.querySelectorAll(
            `.${this.classes.rootClass}__tab-content`
        );

        // Paging elements - applicant list
        this.dom.applicantPaging = this.dom.applicantTab.querySelector(
            `.${this.classes.rootClass}__paging`
        );

        this.dom.applicantPagingPrev = this.dom.applicantTab.querySelector(
            `.${this.classes.rootClass}__paging-button--prev`
        );

        this.dom.applicantPagingNext = this.dom.applicantTab.querySelector(
            `.${this.classes.rootClass}__paging-button--next`
        );

        this.dom.applicantTotalPages = this.dom.applicantTab.querySelector(
            `.${this.classes.rootClass}__total-pages`
        );

        this.dom.applicantCurrentPage = this.dom.applicantTab.querySelector(
            `.${this.classes.rootClass}__current-page`
        );

        // Paging elements - volunteer list
        this.dom.volunteerPaging = this.dom.volunteerTab.querySelector(
            `.${this.classes.rootClass}__paging`
        );

        this.dom.volunteerPagingPrev = this.dom.volunteerTab.querySelector(
            `.${this.classes.rootClass}__paging-button--prev`
        );

        this.dom.volunteerPagingNext = this.dom.volunteerTab.querySelector(
            `.${this.classes.rootClass}__paging-button--next`
        );

        this.dom.volunteerTotalPages = this.dom.volunteerTab.querySelector(
            `.${this.classes.rootClass}__total-pages`
        );

        this.dom.volunteerCurrentPage = this.dom.volunteerTab.querySelector(
            `.${this.classes.rootClass}__current-page`
        );

        // Buttons to handle basically CRUD and email stuff
        this.dom.moveToVolunteerButton = this.dom.container.querySelector(
            `.${this.classes.rootClass}__move-applicant`
        );

        this.dom.generateEmailButtons = this.dom.container.querySelectorAll(
            `.${this.classes.rootClass}__generate-email`
        );

        this.dom.sendEvaluationButton = this.dom.container.querySelector(
            `.${this.classes.rootClass}__send-evaluation`
        );

        this.dom.rejectApplicantButton = this.dom.container.querySelector(
            `.${this.classes.rootClass}__reject-applicant`
        );

        this.dom.registerAttendanceButton = this.dom.container.querySelector(
            `.${this.classes.rootClass}__register-attendance`
        );

        this.dom.assignRewardsButton = this.dom.container.querySelector(
            `.${this.classes.rootClass}__assign-rewards`
        );

        this.dom.sendEvaluationButton = this.dom.container.querySelector(
            `.${this.classes.rootClass}__send-evaluation`
        );

        // Applicant tab buttons wrapper
        this.dom.eventTabsButtonsApplicants = this.dom.container.querySelector(
            `.${this.classes.rootClass}__applicants .${this.classes.rootClass}__buttons`
        );

        // Volunteer tab buttons wrapper
        this.dom.eventTabsButtonsVolunteers = this.dom.container.querySelector(
            `.${this.classes.rootClass}__volunteers .${this.classes.rootClass}__buttons`
        );

        // Setting variables for later use
        this.dom.applicantOverviewId = undefined;
        this.dom.activeTab = undefined;

        // Collection of classes commonly used throughout the class
        this.classes = {
            ...this.classes,
            activeTabClass: `${this.classes.rootClass}__tab-content--active`,
            activeButtonClass: `${this.classes.rootClass}__tab-navigation-item--active`,
            disabledNavItem: `${this.classes.rootClass}__tab-navigation-item--disabled`,
            disabledContent: `${this.classes.rootClass}__tab-content--disabled`,
            activePagingButton: `${this.classes.rootClass}__paging-button--active`,
            adminFilters: "admin-filters"
        };

        onReady(() => this.initialize());
    }

    // BUILDER FUNCTIONS THAT PARSES STRINGS READY FOR HTML OR STRINGS ALREADY PARSED AS HTML

    /**
     * Generic checkbox builder which includes functionality that creates a "simple" checkbox that acts either as
     * a bulk checkbox defined later on or a checkbox that holds a user id to handle a specific set.
     *
     * @param {boolean} checked - Should the checkbox be checked upon rendering
     * @param {boolean} bulkCheck - If true a class and functionality is ready for treating the checkbox as a Bulk checkbox for checking multiple checkboxes
     * @param {string} userId - Most likely a number but could be a string. Used for functionality on a specific set parsed when checkbox is checked
     * @returns {string} - String ready for parsing as HTML containing the checkbox markup for this specific scope
     */
    buildCheckbox = (
        checked = false,
        bulkCheck = false,
        userId = undefined
    ) => {
        const showUserId = !bulkCheck && userId ? ` data-id="${userId}"` : "";

        return `<div class="owner-event-tabs__checkbox${
            bulkCheck ? " owner-event-tabs__checkbox--bulk" : ""
        }"><label><input type="checkbox" ${
            checked ? " checked" : ""
        } ${showUserId}><span></span></label></div>`;
    };

    // Applicant tab functionality

    /**
     * HTML for the applicant tables' headers. Uses the already cached object retrieved from an API response.
     *
     * @returns {HTML}
     */
    buildApplicantHeaders = () => {
        return parseHTML(
            `<div class="owner-event-tabs__table owner-event-tabs__table--applicant">
                <div class="owner-event-tabs__table-header">
                    ${this.buildCheckbox(false, true)}
                    <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--applied" data-sort-key="${
                        this.sortData.createdDateKey
                    }" data-sort-direction="descending">
                        ${this.dictionary.tableHeaderAppliedDateLabel}
                        <figure class="owner-event-tabs__header-arrow"></figure>
                    </div>
                    <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--name" data-sort-key="${
                        this.sortData.nameKey
                    }" data-sort-direction="descending">
                        ${this.dictionary.tableHeaderApplicantNameLabel}
                        <figure class="owner-event-tabs__header-arrow"></figure>
                    </div>
                    <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--language" data-sort-key="${
                        this.sortData.languageKey
                    }" data-sort-direction="descending">
                        ${this.dictionary.tableHeaderLanguageLabel}
                        <figure class="owner-event-tabs__header-arrow"></figure>
                    </div>
                    <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--region" data-sort-key="${
                        this.sortData.regionKey
                    }" data-sort-direction="descending">
                        ${this.dictionary.tableHeaderRegionLabel}
                        <figure class="owner-event-tabs__header-arrow"></figure>
                    </div>
                    <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--time">
                        ${this.dictionary.tableHeaderVolunteeredLabel}
                    </div>
                    <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--age" data-sort-key="${
                        this.sortData.ageIntervalKey
                    }" data-sort-direction="descending">
                        ${this.dictionary.tableHeaderAgeLabel}
                        <figure class="owner-event-tabs__header-arrow"></figure>
                    </div>
                    <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--gender" data-sort-key="${
                        this.sortData.genderKey
                    }" data-sort-direction="descending">
                        ${this.dictionary.tableHeaderGenderLabel}
                        <figure class="owner-event-tabs__header-arrow"></figure>
                    </div>
                </div>
            </div>`
        );
    };

    /**
     * Iterating through a set retrieved by an API and builds the HTML for every row of applicant data.
     *
     * @returns {HTML}
     */
    buildApplicantRows = () => {
        const applicantMarkup = [];

        forEach(this.applicantData.results, applicant => {
            applicantMarkup.push(`
                            <div class="owner-event-tabs__table-row" data-id="${
                                applicant.id
                            }">
                                ${this.buildCheckbox(
                                    false,
                                    false,
                                    applicant.id
                                )}
                                <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--applied">
                                    ${format(
                                        parseISO(applicant.createdDate),
                                        "MMM d",
                                        isRtl ? { locale: arSA } : ""
                                    )}
                                </div>
                                <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--name user-info">
                                    ${applicant.firstName}
                                    ${applicant.lastName}
                                </div>
                                <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--language">
                                    ${applicant.language}
                                </div>
                                <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--region">
                                    ${applicant.region}
                                </div>
                                <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--time">
                                    ${applicant.volunteeredCount} ${
                this.dictionary.hoursLabel
            }
                                </div>
                                <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--age">
                                    ${applicant.ageInterval}
                                </div>
                                <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--gender">
                                    ${applicant.gender}
                                </div>
                            </div>`);
        });

        return parseHTML(applicantMarkup.join(""));
    };

    /**
     * Updating the rows in the applicant table with a condition checking if any rows is already present.
     * If so they will be deleted to make room for updated ones.
     * Lastly it will update the corresponding buttons' state to reflect of any rows are checked.
     */
    updateApplicantMarkup = () => {
        const dataRows = this.dom.applicantTab.querySelectorAll(
            ".owner-event-tabs__table-row"
        );

        if (dataRows) {
            deleteElement(dataRows);
        }

        appendElement(
            this.buildApplicantRows(),
            this.dom.applicantTab.querySelector(
                ".owner-event-tabs__table--applicant"
            )
        );

        this.toggleButtonsState();
    };

    /**
     * Updating the applicant pagination and the different cached objects class-wide as well as the actual text in the DOM.
     * Note: this also activates/deactivates the correct pagination buttons according to results.
     */
    updateApplicantPagination = () => {
        this.settings.applicant.totalPages = Math.ceil(
            this.applicantData.totalNumber / this.settings.listLength
        );

        if (this.applicantData.totalNumber <= this.settings.listLength) {
            this.settings.applicant.showPagination = false;
            setAttributes(this.dom.applicantPaging, "hidden");
        } else {
            this.dom.applicantTotalPages.innerText =
                this.settings.applicant.totalPages;
        }

        if (
            this.settings.applicant.currentPage >
            this.settings.applicant.totalPages
        ) {
            this.settings.applicant.currentPage =
                this.settings.applicant.totalPages;
        }

        // Deactivating the previous page button if first page is reached
        if (this.settings.applicant.currentPage === 1) {
            removeClass(
                this.dom.applicantPagingPrev,
                this.classes.activePagingButton
            );
        } else if (
            !hasClass(
                this.dom.applicantPagingPrev,
                this.classes.activePagingButton
            )
        ) {
            addClass(
                this.dom.applicantPagingPrev,
                this.classes.activePagingButton
            );
        }

        // Deactivating the next page button if last page is reached otherwise we make sure it is there
        if (
            this.settings.applicant.currentPage ===
            this.settings.applicant.totalPages
        ) {
            removeClass(
                this.dom.applicantPagingNext,
                this.classes.activePagingButton
            );
        } else if (
            !hasClass(
                this.dom.applicantPagingNext,
                this.classes.activePagingButton
            )
        ) {
            addClass(
                this.dom.applicantPagingNext,
                this.classes.activePagingButton
            );
        }

        this.dom.applicantCurrentPage.innerText =
            this.settings.applicant.currentPage;
    };

    /**
     * Changing the page of the applicant table while also updating the class-wide objects and scrolling to the top of the table.
     *
     * @param {boolean} nextPage - Used in a condition to update the class-wide objects according to if its next or previous page.
     */
    changeApplicantPage = (nextPage = true) => {
        if (nextPage) {
            this.settings.applicant.currentPage =
                this.settings.applicant.currentPage + 1;

            this.settings.applicant.startIndex =
                this.settings.applicant.startIndex + this.settings.listLength;
        } else {
            this.settings.applicant.currentPage =
                this.settings.applicant.currentPage - 1;

            this.settings.applicant.startIndex =
                this.settings.applicant.startIndex - this.settings.listLength;
        }

        void scrollTo(
            this.dom.container.querySelector(".admin-filters"),
            200,
            window,
            -50
        );

        this.getApplicants();
    };

    // Volunteer tab functionality

    /**
     * HTML for the volunteer tables' headers. Uses the already cached object retrieved from an API response.
     *
     * @returns {HTML}
     */
    buildVolunteerHeaders = () => {
        return parseHTML(
            `<div class="owner-event-tabs__table owner-event-tabs__table--volunteer">
                <div class="owner-event-tabs__table-header">
                    ${this.buildCheckbox(false, true)}
                    <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--applied" data-sort-key="${
                        this.sortData.approvedDateKey
                    }" data-sort-direction="descending">
                        ${this.dictionary.tableHeaderApprovedDateLabel}
                        <figure class="owner-event-tabs__header-arrow"></figure>
                    </div>
                    <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--name" data-sort-key="${
                        this.sortData.nameKey
                    }" data-sort-direction="descending">
                        ${this.dictionary.tableHeaderVolunteerNameLabel}
                        <figure class="owner-event-tabs__header-arrow"></figure>
                    </div>
                    <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--language" data-sort-key="${
                        this.sortData.languageKey
                    }" data-sort-direction="descending">
                        ${this.dictionary.tableHeaderLanguageLabel}
                        <figure class="owner-event-tabs__header-arrow"></figure>
                    </div>
                    <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--attendance" data-sort-key="${
                        this.sortData.attendanceKey
                    }" data-sort-direction="descending">
                        ${this.dictionary.tableHeaderAttendanceLabel}
                        <figure class="owner-event-tabs__header-arrow"></figure>
                    </div>
                    <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--point" data-sort-key="${
                        this.sortData.pointsKey
                    }" data-sort-direction="descending">
                        ${this.dictionary.tableHeaderPointsLabel}
                        <figure class="owner-event-tabs__header-arrow"></figure>
                    </div>
                    <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--certificate" data-sort-key="${
                        this.sortData.certificateKey
                    }" data-sort-direction="descending">
                        ${this.dictionary.tableHeaderCertificateLabel}
                        <figure class="owner-event-tabs__header-arrow"></figure>
                    </div>
                    <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--evaluation" data-sort-key="${
                        this.sortData.evaluationKey
                    }" data-sort-direction="descending">
                        ${this.dictionary.tableHeaderEvaluationLabel}
                        <figure class="owner-event-tabs__header-arrow"></figure>
                    </div>
                </div>
            </div>`
        );
    };

    /**
     * Iterating through a set retrieved by an API and builds the HTML for every row of volunteer data.
     *
     * @returns {HTML}
     */
    buildVolunteerRows = () => {
        const volunteerMarkup = [];

        const checkmarkMarkup =
            "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><path class='path' fill='none' stroke='#00A3E0' stroke-width='13' d='M12.1 52.1l24.4 24.4 53-53'/></svg>";

        forEach(this.volunteerData.results, volunteer => {
            volunteerMarkup.push(
                `<div class="owner-event-tabs__table-row" data-id="${
                    volunteer.id
                }">
                ${this.buildCheckbox(false, false, volunteer.id)}
                <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--applied">
                    ${format(
                        parseISO(volunteer.approvedDate),
                        "MMM d",
                        isRtl ? { locale: arSA } : ""
                    )}
                </div>
                <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--name user-info">
                    ${volunteer.firstName}
                    ${volunteer.lastName}
                </div>
                <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--language">
                    ${volunteer.language}
                </div>
                <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--attendance">
                    ${volunteer.attendance ? checkmarkMarkup : "-"}
                </div>
                <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--point">
                    ${volunteer.points ? volunteer.points : "-"}
                </div>
                <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--certificate">
                    ${volunteer.certificate ? checkmarkMarkup : "-"}
                </div>
                <div class="owner-event-tabs__table-cell owner-event-tabs__table-cell--evaluation">
                    ${volunteer.evaluation ? checkmarkMarkup : "-"}
                </div>
            </div>`
            );
        });

        return parseHTML(volunteerMarkup.join(""));
    };

    /**
     * Updating the rows in the volunteer table with a condition checking if any rows is already present.
     * If so they will be deleted to make room for updated ones.
     * Lastly it will update the corresponding buttons' state to reflect of any rows are checked.
     */
    updateVolunteerMarkup = () => {
        const dataRows = this.dom.volunteerTab.querySelectorAll(
            ".owner-event-tabs__table-row"
        );

        if (dataRows) {
            deleteElement(dataRows);
        }

        appendElement(
            this.buildVolunteerRows(),
            this.dom.volunteerTab.querySelector(
                ".owner-event-tabs__table--volunteer"
            )
        );

        this.toggleButtonsState();
    };

    /**
     * Updating the volunteer pagination and the different cached objects class-wide as well as the actual text in the DOM.
     * Note: this also activates/deactivates the correct pagination buttons according to results.
     */
    updateVolunteerPagination = () => {
        this.settings.volunteer.totalPages = Math.ceil(
            this.volunteerData.totalNumber / this.settings.listLength
        );

        if (this.volunteerData.totalNumber <= this.settings.listLength) {
            this.settings.volunteer.showPagination = false;
            setAttributes(this.dom.volunteerPaging, "hidden");
        } else {
            this.dom.volunteerTotalPages.innerText =
                this.settings.volunteer.totalPages;
        }

        // Deactivating the previous page button if first page is reached
        if (this.settings.volunteer.currentPage === 1) {
            removeClass(
                this.dom.volunteerPagingPrev,
                this.classes.activePagingButton
            );
        } else if (
            !hasClass(
                this.dom.volunteerPagingPrev,
                this.classes.activePagingButton
            )
        ) {
            addClass(
                this.dom.volunteerPagingPrev,
                this.classes.activePagingButton
            );
        }

        // Deactivating the next page button if last page is reached otherwise we make sure it is there
        if (
            this.settings.volunteer.currentPage ===
            this.settings.volunteer.totalPages
        ) {
            removeClass(
                this.dom.volunteerPagingNext,
                this.classes.activePagingButton
            );
        } else if (
            !hasClass(
                this.dom.volunteerPagingNext,
                this.classes.activePagingButton
            )
        ) {
            addClass(
                this.dom.volunteerPagingNext,
                this.classes.activePagingButton
            );
        }

        this.dom.volunteerCurrentPage.innerText =
            this.settings.volunteer.currentPage;
    };

    /**
     * Changing the page of the volunteer table while also updating the class-wide objects and scrolling to the top of the table.
     *
     * @param {boolean} nextPage - Used in a condition to update the class-wide objects according to if its next or previous page.
     */
    changeVolunteerPage = (nextPage = true) => {
        if (nextPage) {
            this.settings.volunteer.currentPage =
                this.settings.volunteer.currentPage + 1;

            this.settings.volunteer.startIndex =
                this.settings.volunteer.startIndex + this.settings.listLength;
        } else {
            this.settings.volunteer.currentPage =
                this.settings.volunteer.currentPage - 1;

            this.settings.volunteer.startIndex =
                this.settings.volunteer.startIndex - this.settings.listLength;
        }

        this.dom.volunteerCurrentPage.innerText =
            this.settings.volunteer.currentPage;

        void scrollTo(
            this.dom.container.querySelector(".admin-filters"),
            200,
            window,
            -50
        );

        this.getVolunteers();
    };

    /**
     * Resetting the class-wide objects to get a clean list from the parameters set.
     *
     * @param {boolean} includeFilters - Resetting filters if true
     * @param {boolean} resetApplicantSettings - Resetting applicant tab settings if true
     * @param {boolean} resetVolunteerSettings - Resetting volunteer tab settings if true
     */
    resetListSettings = (
        includeFilters = true,
        resetApplicantSettings = true,
        resetVolunteerSettings = true
    ) => {
        if (resetApplicantSettings) {
            this.settings.applicant.startIndex = 0;
            this.settings.applicant.currentPage = 1;
            this.settings.applicant.showPagination = false;
        }

        if (resetVolunteerSettings) {
            this.settings.volunteer.startIndex = 0;
            this.settings.volunteer.currentPage = 1;
            this.settings.volunteer.showPagination = false;
        }

        if (includeFilters) {
            this.collectedApplicantFilters = undefined;
            this.collectedVolunteerFilters = undefined;
        }
    };

    // UNUSED FOR NOW.
    /*animateEventTabsButtons = () => {
        if (
            hasClass(
                this.dom.eventTabsButtonsVolunteers,
                "owner-event-tabs__buttons--show"
            )
        ) {
            anime({
                targets: this.dom.eventTabsButtonsVolunteers,
                translateY: [300, 0],
                easing: "linear",
                duration: MEDIUM
            });
        } else {
            anime({
                targets: this.dom.eventTabsButtonsVolunteers,
                translateY: [0, 300],
                easing: "linear",
                duration: MEDIUM
            });
        }
        if (
            hasClass(
                this.dom.eventTabsButtonsApplicants,
                "owner-event-tabs__buttons--show"
            )
        ) {
            anime({
                targets: this.dom.eventTabsButtonsApplicants,
                translateY: [200, 0],
                easing: "linear",
                duration: MEDIUM
            });
        } else {
            anime({
                targets: this.dom.eventTabsButtonsApplicants,
                translateY: [0, 200],
                easing: "linear",
                duration: MEDIUM
            });
        }
    };

    toggleEventTabs = () => {
        toggleClass(
            this.dom.toggleEventTabsButton[0],
            "owner-event-tabs__toggle-event-tabs--rotate",
            !hasClass(
                this.dom.toggleEventTabsButton[0],
                "owner-event-tabs__toggle-event-tabs--rotate"
            )
        );
        toggleClass(
            this.dom.toggleEventTabsButton[1],
            "owner-event-tabs__toggle-event-tabs--rotate",
            !hasClass(
                this.dom.toggleEventTabsButton[1],
                "owner-event-tabs__toggle-event-tabs--rotate"
            )
        );
        // toggle owner-event-tabs__buttons--show class to show and hide this.dom.eventTabsButtons
        toggleClass(
            this.dom.eventTabsButtonsApplicants,
            "owner-event-tabs__buttons--show",
            !hasClass(
                this.dom.eventTabsButtonsApplicants,
                "owner-event-tabs__buttons--show"
            )
        );
        toggleClass(
            this.dom.eventTabsButtonsVolunteers,
            "owner-event-tabs__buttons--show",
            !hasClass(
                this.dom.eventTabsButtonsVolunteers,
                "owner-event-tabs__buttons--show"
            )
        );
        this.animateEventTabsButtons();
    };*/

    /**
     * Getting the Sitecore dictionaries from API response and assigning them to a class-wide object.
     * Note: Used with a promise to make sure we have set the data before actually using the data.
     *
     * @returns {Promise}
     */
    getDictionary = () => {
        return new Promise((resolve, reject) => {
            fetcher(
                `${this.endpoints.startEndpoint}?eventId=${this.settings.eventId}`
            ).then(response => {
                if (response.success) {
                    this.dictionary = response.data.texts;
                    resolve("Success initializing");
                } else {
                    reject("Error getting a response from start API call");
                }
            });
        });
    };

    /**
     * Fetching the applicants for the applicant tab while taking into account if this is the first fetch,
     * or we are replacing the current table-row data.
     * If this is the first fetch we set up the filters and the table headers.
     */
    getApplicants = () => {
        // Disabling the tabs when fetching new data
        addClass(
            this.dom.tabsNavigationCollection,
            this.classes.disabledNavItem
        );

        addClass(this.dom.tabContentList, this.classes.disabledContent);

        // Constructing the filter and sorting querystrings for the API call
        let filterQuerystring = "";

        if (this.collectedApplicantFilters) {
            forEach(this.collectedApplicantFilters, (valueArray, key) => {
                filterQuerystring += `&${key}=${valueArray.join("|")}`;
            });
        }

        let sortingQuerystring = "";

        if (this.collectedSorting) {
            sortingQuerystring = `&sortby=${this.collectedSorting.sortKey}&sortdirection=${this.collectedSorting.sortDirection}`;
        }

        return fetcher(
            `${this.endpoints.applicantListEndpoint}?eventId=${this.settings.eventId}&startIndex=${this.settings.applicant.startIndex}&length=${this.settings.listLength}${filterQuerystring}${sortingQuerystring}`
        ).then(response => {
            if (response.success) {
                this.applicantData = response.data;

                // Checking if we have table headers present
                const excludeHeaders = this.dom.applicantTab.querySelector(
                    ".owner-event-tabs__table-header"
                );

                // If we do not have headers we should build them
                if (!excludeHeaders) {
                    prependElement(
                        this.buildApplicantHeaders(),
                        this.dom.applicantTab
                    );

                    this.setupSortingEvents(this.dom.applicantTab);
                }

                // Actually updating the rows with fetched data
                this.updateApplicantMarkup();

                // If we are currently hiding the pagination
                if (!this.settings.applicant.showPagination) {
                    if (response.data.totalNumber > this.settings.listLength) {
                        this.settings.applicant.showPagination = true;

                        removeAttributes(this.dom.applicantPaging, "hidden");
                    }
                }

                this.updateApplicantPagination();

                // Building the filters once
                if (!this.settings.applicant.filtersShown) {
                    this.settings.applicant.filtersShown = true;
                    prependElement(
                        filterMarkup(
                            this.applicantData.filterSections,
                            this.dictionary
                        ),
                        this.dom.applicantTab
                    );

                    // Setting up the filter events
                    this.setupFilterEvents(this.dom.applicantTab);
                }

                // Setting the number of fetched applicants shown in the list
                this.dom.applicantTab.querySelector(
                    `.${this.classes.adminFilters}__list-count`
                ).innerText = `${this.applicantData.totalNumber} ${this.dictionary.applicantsLabel}`;

                // If the bulk checkbox is checked we will uncheck it
                if (
                    this.dom.activeTab.querySelector(
                        ".owner-event-tabs__checkbox--bulk input"
                    ).checked
                ) {
                    this.dom.activeTab.querySelector(
                        ".owner-event-tabs__checkbox--bulk input"
                    ).checked = false;
                }

                // Loading is complete and we enable the tab navigation and list
                removeClass(
                    this.dom.tabsNavigationCollection,
                    this.classes.disabledNavItem
                );

                removeClass(
                    this.dom.tabContentList,
                    this.classes.disabledContent
                );
            }
        });
    };

    /**
     * Fetching the volunteers for the volunteer tab while taking into account if this is the first fetch,
     * or we are replacing the current table-row data.
     * If this is the first fetch we set up the filters and the table headers.
     */
    getVolunteers = () => {
        // Disabling the tabs when fetching new data
        addClass(
            this.dom.tabsNavigationCollection,
            this.classes.disabledNavItem
        );

        addClass(this.dom.tabContentList, this.classes.disabledContent);

        // Constructing the filter and sorting querystrings for the API call
        let filterQuerystring = "";

        if (this.collectedVolunteerFilters) {
            forEach(this.collectedVolunteerFilters, (valueArray, key) => {
                filterQuerystring += `&${key}=${valueArray.join("|")}`;
            });
        }

        let sortingQuerystring = "";

        // Do we have any sorting active?
        if (this.collectedSorting) {
            sortingQuerystring = `&sortby=${this.collectedSorting.sortKey}&sortdirection=${this.collectedSorting.sortDirection}`;
        }

        return fetcher(
            `${this.endpoints.volunteerListEndpoint}?eventId=${this.settings.eventId}&startIndex=${this.settings.volunteer.startIndex}&length=${this.settings.listLength}${filterQuerystring}${sortingQuerystring}`
        ).then(response => {
            if (response.success) {
                this.volunteerData = response.data;

                const excludeHeaders = this.dom.volunteerTab.querySelector(
                    ".owner-event-tabs__table-header"
                );

                // If we do not already have the headers rendered we will add them
                if (!excludeHeaders) {
                    prependElement(
                        this.buildVolunteerHeaders(),
                        this.dom.volunteerTab
                    );

                    this.setupSortingEvents(this.dom.volunteerTab);
                }

                // Updating the actual rows that is holding all the data
                this.updateVolunteerMarkup();

                if (!this.settings.volunteer.showPagination) {
                    if (response.data.totalNumber > this.settings.listLength) {
                        this.settings.volunteer.showPagination = true;

                        removeAttributes(this.dom.volunteerPaging, "hidden");
                    }
                }

                this.updateVolunteerPagination();

                // Building the filters once
                if (!this.settings.volunteer.filtersShown) {
                    this.settings.volunteer.filtersShown = true;
                    prependElement(
                        filterMarkup(
                            this.volunteerData.filterSections,
                            this.dictionary
                        ),
                        this.dom.volunteerTab
                    );

                    this.setupFilterEvents(this.dom.volunteerTab);
                }

                // Setting up the number of actual volunteers in the list from the parameters and showing it
                this.dom.volunteerTab.querySelector(
                    `.${this.classes.adminFilters}__list-count`
                ).innerText = `${this.volunteerData.totalNumber} ${this.dictionary.volunteersLabel}`;

                // If the bulk checkbox is checked we will uncheck it
                if (
                    this.dom.activeTab.querySelector(
                        ".owner-event-tabs__checkbox--bulk input"
                    ).checked
                ) {
                    this.dom.activeTab.querySelector(
                        ".owner-event-tabs__checkbox--bulk input"
                    ).checked = false;
                }

                // Loading is complete and we will enable the tab navigation and content again
                removeClass(
                    this.dom.tabsNavigationCollection,
                    this.classes.disabledNavItem
                );

                removeClass(
                    this.dom.tabContentList,
                    this.classes.disabledContent
                );
            }
        });
    };

    /**
     * For cases where we want to update the active list only we will let this function decide on what list to update
     * from a condition based on a class wide variable and its dataset
     *
     * If no list is active or the function cannot read it we will warn via the console
     */
    getActiveList = () => {
        if (this.dom.activeTab.dataset.tab === "applicants") {
            this.getApplicants();
        } else if (this.dom.activeTab.dataset.tab === "volunteers") {
            this.getVolunteers();
        } else {
            console.warn("No active list to update!");
        }
    };

    /**
     * Getting the applicant overview from a user id.
     * The applicant overview data is fetched, cached and rendered.
     * Lastly we add some events for handling the user.
     *
     * @param target - Unused parameter for now
     * @param userId - The user id used throughout the applicant overview for the specific user
     * @returns {Promise}
     */
    getApplicantOverview = (target, userId) => {
        // Adding the user id to a class wide object
        this.singleUser = {
            EventId: this.settings.eventId,
            EventApplicationIds: [userId]
        };

        // Disabling the content when fetching the applicant overview to avoid interruption
        addClass(
            this.dom.container.querySelectorAll(
                ".owner-event-tabs__tab-content"
            ),
            this.classes.disabledContent
        );

        return fetcher(
            `${this.endpoints.applicantDetailsEndpoint}/${userId}`
        ).then(response => {
            if (response.success) {
                this.applicantOverviewData = response.data;
                this.dom.applicantOverviewId = userId;

                appendElement(
                    applicantOverviewMarkup(
                        this.applicantOverviewData,
                        this.settings.eventName,
                        this.dom.activeTab.dataset.tab === "volunteers"
                    ),
                    this.dom.container
                );

                // Caching the applicant overview to avoid re-rendering
                this.dom.applicantOverviewContainer =
                    this.dom.container.querySelector(".applicant-overview");

                // To keep logic in one place we will setup eventlisteners for applicant overview here
                this.setupApplicantOverview();

                removeClass(
                    this.dom.container.querySelectorAll(
                        ".owner-event-tabs__tab-content"
                    ),
                    this.classes.disabledContent
                );
            }
        });
    };

    /**
     * Parsed a list we will iterate it and find the email from a given ID.
     *
     * @param dataList - Set of data to iterate. Must include both ID and email keys.
     * @param id - The user id to match in the list to find the exact email
     * @returns {string} userEmail
     */
    getEmailFromUsers = (dataList, id) => {
        let userEmail;

        forEach(dataList, item => {
            if (item.id === id) {
                userEmail = item.email;
            }
        });

        return userEmail;
    };

    /**
     * More or less generic function that fetches data based on a set of parameters.
     *
     * @param endpoint - The endpoint to fetch data from
     * @param loaderTarget - Node to show/hide loader in
     * @param fromApplicantOverview - Indicates of the fetch is done from the applicant overview to then update more data since it affects multiple sets of data
     * @param reloadAllLists - Simple boolean that will reload both the applicant and volunteer tab data
     */
    postUsersToApi = (
        endpoint,
        loaderTarget,
        fromApplicantOverview = false,
        reloadAllLists = false
    ) => {
        addLoader(loaderTarget, false);

        fetcher(
            endpoint,
            "POST",
            fromApplicantOverview ? this.singleUser : this.collectedUsers
        ).then(response => {
            deleteElement(loaderTarget.querySelector(".spring-spinner"));

            if (response.success) {
                // Removing filter settings since we are fetching completely new set(s) of data
                this.removeFilters();

                // If the bulk input field is checked we will reset it for the same reason as above - new data!
                if (
                    this.dom.activeTab.querySelector(
                        ".owner-event-tabs__checkbox--bulk input"
                    ).checked
                ) {
                    this.dom.activeTab.querySelector(
                        ".owner-event-tabs__checkbox--bulk input"
                    ).checked = false;
                }

                // If we have parsed either the reloadAllLists or fromApplicantOverview we will reload both the applicant and volunteer list data
                // In the case we do not get parsed those parameters we will look for the active list and update that accordingly
                if (reloadAllLists || fromApplicantOverview) {
                    this.getVolunteers();
                    this.getApplicants();
                } else {
                    this.getActiveList();
                }

                // If the fromApplicantOverview parameter is parsed we will remove the scroll-lock and close the applicant overview to show the updated lists
                if (fromApplicantOverview) {
                    removeScrollLock();
                    this.killApplicantOverview();
                }
            }
        });
    };

    /**
     * Looking through the active list of data to then push the checked users into an array and
     * also letting the class wide object of this.collectedUsers get its data from the most recent set.
     *
     * @returns {Promise} - returned as a promise to utilise the power of callbacks
     */
    collectUsers = () => {
        const userArray = [];

        // Iterating the active list for checked rows
        this.dom.checkboxRowCollection = this.dom.activeTab.querySelectorAll(
            `.${this.classes.rootClass}__table-row .${this.classes.rootClass}__checkbox input`
        );

        forEach(this.dom.checkboxRowCollection, checkbox => {
            if (checkbox.checked) {
                userArray.push(checkbox.dataset.id);
            }
        });

        return new Promise((resolve, reject) => {
            if (userArray.length) {
                this.collectedUsers = {
                    EventId: this.settings.eventId,
                    EventApplicationIds: userArray
                };

                resolve("Success getting users");
            } else {
                reject("No users checked");
            }
        });
    };

    /**
     * Collecting the filters on the applicant tab that are checked.
     * Pushing each section of filters to its own key
     *
     * @returns {Promise} - returned as a promise to utilise the power of callbacks
     */
    collectApplicantFilters = () => {
        this.collectedApplicantFilters = {};

        this.dom.checkboxFilterCollection = this.dom.activeTab.querySelectorAll(
            ".admin-filters__filter-item input"
        );

        forEach(this.dom.checkboxFilterCollection, checkbox => {
            if (checkbox.checked) {
                // Creating the object key with an empty array value if not present
                if (!this.collectedApplicantFilters[checkbox.dataset.section]) {
                    this.collectedApplicantFilters[checkbox.dataset.section] =
                        [];
                }

                // Pushing the data for later use
                this.collectedApplicantFilters[checkbox.dataset.section].push(
                    checkbox.dataset.value
                );
            }
        });

        return new Promise((resolve, reject) => {
            if (this.collectedApplicantFilters) {
                resolve("Success getting applicant filters");
            } else {
                reject("No filters checked");
            }
        });
    };

    /**
     * Collecting the filters on the applicant tab that are checked.
     * Pushing each section of filters to its own key
     *
     * @returns {Promise} - returned as a promise to utilise the power of callbacks
     */
    collectVolunteerFilters = () => {
        this.collectedVolunteerFilters = {};

        this.dom.checkboxFilterCollection = this.dom.activeTab.querySelectorAll(
            ".admin-filters__filter-item input"
        );

        forEach(this.dom.checkboxFilterCollection, checkbox => {
            if (checkbox.checked) {
                // Creating the object key with an empty array value if not present
                if (!this.collectedVolunteerFilters[checkbox.dataset.section]) {
                    this.collectedVolunteerFilters[checkbox.dataset.section] =
                        [];
                }

                // Pushing the data for later use
                this.collectedVolunteerFilters[checkbox.dataset.section].push(
                    checkbox.dataset.value
                );
            }
        });

        return new Promise((resolve, reject) => {
            if (this.collectedVolunteerFilters) {
                resolve("Success getting volunteer filters");
            } else {
                reject("No filters checked");
            }
        });
    };

    /**
     * Setting up the class wide object that holds sorting settings including direction and key/value for sorting
     *
     * @param target
     * @returns {Promise} - returned as a promise to utilise the power of callbacks
     */
    collectSorting = target => {
        // Building/updating the class wide object
        this.collectedSorting = {
            sortKey: target.dataset.sortKey,
            sortDirection: target.dataset.sortDirection
        };

        return new Promise((resolve, reject) => {
            if (this.collectedSorting) {
                resolve("Success getting sorting");

                if (
                    hasClass(
                        target,
                        `${this.classes.rootClass}__table-cell--sorting-active`
                    )
                ) {
                    // Reversing the sorting direction if we already have one on our target
                    this.collectedSorting.sortDirection =
                        this.collectedSorting.sortDirection === "descending"
                            ? "ascending"
                            : "descending";

                    target.dataset.sortDirection =
                        this.collectedSorting.sortDirection;
                } else {
                    removeClass(
                        this.dom.activeTab.querySelector(
                            `.${this.classes.rootClass}__table-cell--sorting-active`
                        ),
                        `${this.classes.rootClass}__table-cell--sorting-active`
                    );
                    addClass(
                        target,
                        `${this.classes.rootClass}__table-cell--sorting-active`
                    );
                }
            } else {
                reject("Error getting sorting");
            }
        });
    };

    /**
     * Visibly/functionally removing the filters.
     * This includes removing the active filters in the class wide object and also the visible checkmarks
     */
    removeFilters = () => {
        removeClass(
            this.dom.tabContainer.querySelector(
                `.${this.classes.adminFilters}__button`
            ),
            `${this.classes.adminFilters}__button--active`
        );

        this.dom.checkboxFilterCollection = this.dom.activeTab.querySelectorAll(
            ".admin-filters__filter-item input"
        );

        forEach(this.dom.checkboxFilterCollection, checkbox => {
            checkbox.checked = false;
        });

        // Resetting the list settings
        this.resetListSettings();

        // Updating the count of the filters to dynamically remove it
        this.setFilterStatus();

        // Updating the active list if any
        this.getActiveList();
    };

    /**
     * Getting the applicant overview from this.getApplicantOverview() which is getting the clicked target and its userId parsed
     * If we already have an applicant overview present we will remove the events for it to be ready to receive new ones.
     *
     * @param target
     * @param userId
     */
    showApplicantOverview = (target, userId) => {
        if (
            this.dom.applicantOverviewContainer &&
            this.dom.applicantOverviewId
        ) {
            // Remove current overview events
            this.killApplicantOverview();
        }

        this.getApplicantOverview(target, userId);
    };

    /**
     * Fetching the applicant list if we have an empty object from the class instantiation.
     */
    showApplicants = () => {
        if (Object.keys(this.applicantData).length === 0) {
            this.getApplicants();
        }
    };

    /**
     * Fetching the volunteer list if we have an empty object from the class instantiation.
     */
    showVolunteers = () => {
        if (Object.keys(this.volunteerData).length === 0) {
            this.getVolunteers();
        }
    };

    /**
     * Toggling the checkboxes' checkmark. If checked we reverse and vice versa.
     * Using the state to indicate if we can interact with buttons or not.
     *
     * @param event - Used for getting the actual target clicked which should be our bulk checkbox
     */
    bulkCheck = event => {
        const bulkIsChecked = event.target.checked;

        // Getting the active tabs checkboxes
        this.dom.checkboxRowCollection = this.dom.activeTab.querySelectorAll(
            `.${this.classes.rootClass}__table-row .${this.classes.rootClass}__checkbox input`
        );

        forEach(this.dom.checkboxRowCollection, checkbox => {
            // Reverting whatever state we are in
            checkbox.checked = !!bulkIsChecked;
        });

        this.toggleButtonsState();
    };

    /**
     * Updating the state and text of the filter count based on the count.
     * If we have any filters active we will show the count and the node. If not we hide the node.
     */
    setFilterStatus = () => {
        let filterCount = 0;
        const filterCountNode = this.dom.activeTab.querySelector(
            `.${this.classes.adminFilters}__filter-count`
        );

        if (this.dom.activeTab.dataset.tab === "applicants") {
            if (this.collectedApplicantFilters) {
                forEach(this.collectedApplicantFilters, filter => {
                    filterCount += filter.length;
                });
            }
        } else if (this.dom.activeTab.dataset.tab === "volunteers") {
            if (this.collectedVolunteerFilters) {
                forEach(this.collectedVolunteerFilters, filter => {
                    filterCount += filter.length;
                });
            }
        }

        filterCountNode.innerText = filterCount;

        toggleClass(
            filterCountNode,
            `${this.classes.adminFilters}__filter-count--active`,
            filterCount > 0
        );

        toggleClass(
            this.dom.activeTab.querySelector(
                `.${this.classes.adminFilters}__action-button--remove`
            ),
            `${this.classes.adminFilters}__action-button--hide`,
            filterCount === 0
        );
    };

    /**
     * Animation and accessibility for switching between the tabs.
     * Simple fade animation done with the anime library
     */
    animateTabContent = () => {
        anime
            .timeline({
                complete: () => {
                    anime({
                        before: () => {
                            // Before actually animating the new tab in we make sure the hidden attribute is set on all tabs
                            forEach(this.dom.tabContentList, step => {
                                step.setAttribute("hidden", true);
                            });

                            this.dom.activeTab.removeAttribute("hidden");
                        },
                        targets: this.dom.tabContainer,
                        opacity: [0, 1],
                        easing: STANDARDCUBICBEZIER,
                        duration: MEDIUM
                    });
                }
            })
            .add({
                targets: this.dom.tabContainer,
                opacity: [1, 0],
                easing: STANDARDCUBICBEZIER,
                duration: MEDIUM
            });
    };

    toggleFilters = () => {
        const filterContainer = this.dom.activeTab.querySelector(
            `.${this.classes.adminFilters}__container`
        );
        const shouldOpen = filterContainer.getAttribute("hidden");

        if (shouldOpen) {
            filterContainer.removeAttribute("hidden");
        }

        anime
            .timeline({
                complete: () => {
                    if (!shouldOpen) {
                        setAttributes(filterContainer, "hidden", "hidden");
                    }
                }
            })
            .add({
                targets: filterContainer,
                opacity: shouldOpen ? [0, 1] : [1, 0],
                easing: STANDARDCUBICBEZIER,
                duration: MEDIUM
            });
    };

    toggleEvaluationConfirmation = () => {
        // Hide snackbar so we don't create multiple instances
        if (this.snackbarEvaluation) {
            this.snackbarEvaluation.close();
        }

        this.snackbarEvaluation = new Snackbar({
            element: this.dom.sendEvaluationButton,
            text: this.dom.sendEvaluationButton.dataset.snackText,
            autoClose: false,
            confirm: true,
            cancelLabel: this.dom.sendEvaluationButton.dataset.snackCancel,
            confirmLabel: this.dom.sendEvaluationButton.dataset.snackConfirm,
            onCancel: () => {
                this.snackbarEvaluation.close();
                this.snackbarEvaluation = undefined;
            },
            onConfirm: () => {
                this.collectUsers().then(() => {
                    fetcher(
                        this.dom.sendEvaluationButton.dataset.snackEndpoint,
                        "POST",
                        this.collectedUsers
                    ).then(result => {
                        if (result.success) {
                            this.snackbarEvaluation.close();
                            this.snackbarEvaluation = undefined;
                            this.getVolunteers();
                        } else {
                            console.warn("Confirmation API error");
                        }
                    });
                });
            }
        });
    };

    toggleButtonsState = () => {
        this.dom.checkboxRowCollection = this.dom.activeTab.querySelectorAll(
            `.${this.classes.rootClass}__table-row .${this.classes.rootClass}__checkbox input`
        );

        const checkedItems = [];

        forEach(this.dom.checkboxRowCollection, checkbox => {
            if (checkbox.checked) {
                checkedItems.push(checkbox);
            }
        });

        toggleClass(
            this.dom.activeTab.querySelector(
                `.${this.classes.rootClass}__buttons`
            ),
            `${this.classes.rootClass}__buttons--active`,
            checkedItems.length >= 1
        );
    };

    setActiveTab = tabName => {
        if (
            this.dom.activeTab !==
            this.dom.ownerContainer.querySelector(`[data-tab=${tabName}]`)
        ) {
            this.dom.activeTab = this.dom.ownerContainer.querySelector(
                `[data-tab=${tabName}]`
            );
            removeClass(this.dom.tabContentList, this.classes.activeTabClass);
            addClass(this.dom.activeTab, this.classes.activeTabClass);

            this.animateTabContent();

            switch (tabName) {
                case "applicants":
                    this.showApplicants();
                    break;
                case "volunteers":
                    this.showVolunteers();
                    break;
                case "event-info":
                default:
            }
        }
    };

    navigateToTab = (target = null) => {
        const node = target
            ? target
            : this.dom.ownerContainer.querySelector(`[data-nav-tab]`);
        const tabName = node.dataset.navTab;

        removeClass(
            this.dom.tabsNavigationCollection,
            this.classes.activeButtonClass
        );

        addClass(node, this.classes.activeButtonClass);

        this.setActiveTab(tabName);
    };

    setupEvents = () => {
        addEvent(this.dom.tabsNavigationCollection, "click", event => {
            this.navigateToTab(event.target);
        });

        addEvent(this.dom.applicantPagingPrev, "click", () => {
            this.changeApplicantPage(false);
        });

        addEvent(this.dom.applicantPagingNext, "click", () => {
            this.changeApplicantPage(true);
        });

        addEvent(this.dom.volunteerPagingPrev, "click", () => {
            this.changeVolunteerPage(false);
        });

        addEvent(this.dom.volunteerPagingNext, "click", () => {
            this.changeVolunteerPage(true);
        });

        addEvent(this.dom.moveToVolunteerButton, "click", event => {
            this.collectUsers().then(() => {
                this.postUsersToApi(
                    this.endpoints.approveApplicantEndpoint,
                    event.target,
                    false,
                    true
                );
            });
        });

        addEvent(this.dom.assignRewardsButton, "click", event => {
            this.collectUsers().then(() => {
                this.postUsersToApi(
                    this.endpoints.assignRewardsEndpoint,
                    event.target
                );
            });
        });

        addEvent(this.dom.registerAttendanceButton, "click", event => {
            this.collectUsers().then(() => {
                this.postUsersToApi(
                    this.endpoints.registerAttendanceEndpoint,
                    event.target
                );
            });
        });

        addEvent(this.dom.rejectApplicantButton, "click", event => {
            event.preventDefault();

            this.collectUsers().then(() => {
                this.postUsersToApi(
                    this.endpoints.rejectApplicantsEndpoint,
                    event.target,
                    false,
                    true
                );
            });
        });

        addEvent(
            this.dom.container.querySelectorAll(
                ".owner-event-tabs__generate-email"
            ),
            "click",
            event => {
                event.preventDefault();

                const bccList = [];

                this.collectUsers().then(() => {
                    forEach(this.collectedUsers.EventApplicationIds, userId => {
                        bccList.push(
                            this.getEmailFromUsers(
                                event.target.dataset.context === "volunteer"
                                    ? this.volunteerData.results
                                    : this.applicantData.results,
                                userId
                            )
                        );
                    });

                    window.location.href = `mailto:?bcc=${bccList.join(";")}`;
                });
            }
        );

        addEvent(this.dom.sendEvaluationButton, "click", event => {
            event.preventDefault();

            this.toggleEvaluationConfirmation();

            // Setting the actual bcc list from users
            const bccList = [];

            this.collectUsers().then(() => {
                forEach(this.collectedUsers.EventApplicationIds, userId => {
                    bccList.push(
                        this.getEmailFromUsers(
                            this.volunteerData.results,
                            userId
                        )
                    );
                });

                window.location.href = event.target.href.replace(
                    "{EMAILS}",
                    bccList.join(";")
                );
            });
        });

        delegateEvent(
            ".owner-event-tabs__checkbox--bulk input",
            "change",
            event => {
                this.bulkCheck(event);
            }
        );

        delegateEvent(
            ".owner-event-tabs__table-cell--name.user-info",
            "click",
            event => {
                setScrollLock();

                this.showApplicantOverview(
                    event.target,
                    event.target.parentNode.dataset.id
                );
            }
        );

        delegateEvent(
            `.${this.classes.rootClass}__table-row .${this.classes.rootClass}__checkbox input`,
            "click",
            () => {
                this.toggleButtonsState();
            }
        );
    };

    setupFilterEvents = container => {
        addEvent(
            container.querySelector(`.${this.classes.adminFilters}__button`),
            "click",
            e => {
                toggleClass(
                    e.target,
                    `${this.classes.adminFilters}__button--active`,
                    !hasClass(
                        e.target,
                        `${this.classes.adminFilters}__button--active`
                    )
                );
                this.toggleFilters();
            }
        );

        addEvent(
            container.querySelector(
                `.${this.classes.adminFilters}__action-button--apply`
            ),
            "click",
            () => {
                this.toggleFilters();

                if (this.dom.activeTab.dataset.tab === "applicants") {
                    this.collectApplicantFilters().then(success => {
                        if (success) {
                            this.resetListSettings(false, true, false);

                            this.setFilterStatus();
                            this.getApplicants();
                        }
                    });
                } else if (this.dom.activeTab.dataset.tab === "volunteers") {
                    this.collectVolunteerFilters().then(success => {
                        if (success) {
                            this.resetListSettings(false, false, true);

                            this.setFilterStatus();
                            this.getVolunteers();
                        }
                    });
                }
            }
        );

        addEvent(
            container.querySelector(
                `.${this.classes.adminFilters}__action-button--remove`
            ),
            "click",
            () => {
                this.removeFilters();
            }
        );
    };

    setupSortingEvents = container => {
        addEvent(
            container.querySelectorAll(
                `.${this.classes.rootClass}__table-header .${this.classes.rootClass}__table-cell[data-sort-key]`
            ),
            "click",
            e => {
                this.collectSorting(e.target).then(success => {
                    if (success) {
                        this.getActiveList();
                    }
                });
            }
        );
    };

    setupApplicantOverview = () => {
        addEvent(
            this.dom.applicantOverviewContainer.querySelector(
                ".applicant-overview__close-button"
            ),
            "click",
            () => {
                removeScrollLock();

                addClass(
                    this.dom.applicantOverviewContainer,
                    "applicant-overview--hide"
                );
            }
        );

        addEvent(
            this.dom.applicantOverviewContainer.querySelectorAll(
                ".applicant-overview__buttons button[data-endpoint]"
            ),
            "click",
            event => {
                this.postUsersToApi(
                    event.target.dataset.endpoint,
                    event.target,
                    true,
                    event.target.dataset.button === "reject"
                );
            }
        );

        addEvent(
            this.dom.applicantOverviewContainer.querySelector(
                ".applicant-overview__delete-applicant"
            ),
            "click",
            event => {
                // Hide snackbar so we don't create multiple instances
                if (this.snackbarDelete) {
                    this.snackbarDelete.close();
                }

                this.snackbarDelete = new Snackbar({
                    element: event.target,
                    text: event.target.dataset.deleteConfirmText,
                    autoClose: false,
                    confirm: true,
                    cancelLabel: event.target.dataset.noButtonLabel,
                    confirmLabel: event.target.dataset.yesButtonLabel,
                    onCancel: () => {
                        this.snackbarDelete.close();
                        this.snackbarDelete = undefined;
                    },
                    onConfirm: () => {
                        this.postUsersToApi(
                            event.target.dataset.deleteEndpoint,
                            event.target,
                            true
                        );

                        this.snackbarDelete.close();
                        this.snackbarDelete = undefined;
                    }
                });
            }
        );
    };

    killApplicantOverview = () => {
        removeAllEvents([
            this.dom.applicantOverviewContainer.querySelector(
                ".applicant-overview__close-button"
            ),
            this.dom.applicantOverviewContainer.querySelectorAll(
                ".applicant-overview__buttons button[data-endpoint]"
            )
        ]);

        deleteElement(this.dom.applicantOverviewContainer);

        this.dom.applicantOverviewContainer = undefined;
    };

    initialize() {
        this.endpoints = {
            applicantListEndpoint:
                this.dom.container.dataset.applicantListEndpoint,
            volunteerListEndpoint:
                this.dom.container.dataset.volunteerListEndpoint,
            approveApplicantEndpoint:
                this.dom.container.dataset.approveApplicantEndpoint,
            rejectApplicantsEndpoint:
                this.dom.container.dataset.rejectApplicantsEndpoint,
            registerAttendanceEndpoint:
                this.dom.container.dataset.registerAttendanceEndpoint,
            assignRewardsEndpoint:
                this.dom.container.dataset.assignRewardsEndpoint,
            startEndpoint: this.dom.container.dataset.startEndpoint,
            applicantDetailsEndpoint:
                this.dom.container.dataset.applicantDetailsEndpoint
        };

        this.settings = {
            eventId: this.dom.container.dataset.eventId,
            queryStringTab: getParameter("tabView"),
            eventName: this.dom.container.dataset.eventName,
            listLength: this.dom.container.dataset.listLength || 30,
            applicant: {
                startIndex: 0,
                showPagination: false,
                currentPage: 1,
                totalPages: 1,
                filtersShown: false
            },
            volunteer: {
                startIndex: 0,
                showPagination: false,
                currentPage: 1,
                totalPages: 1,
                filtersShown: false
            }
        };

        this.snackbarEvaluation = undefined;
        this.snackbarDelete = undefined;

        this.getDictionary().then(() => {
            this.navigateToTab();
            /*if (this.settings.queryStringTab) {
                this.navigateToTab(
                    this.dom.ownerContainer.querySelector(
                        `[data-nav-tab="${this.settings.queryStringTab}"]`
                    )
                );
            } else {
                this.navigateToTab();
            }*/
        });

        this.applicantData = {};
        this.volunteerData = {};
        this.applicantOverviewData = {};

        this.sortData = {
            createdDateKey: "createdDate",
            lastUpdatedDateKey: "lastUpdatedDate",
            nameKey: "name",
            regionKey: "region",
            ageIntervalKey: "ageInterval",
            genderKey: "gender",
            languageKey: "language",
            englishLevelKey: "englishLevel",
            arabicLevelKey: "arabicLevel",
            attendanceKey: "attendance",
            certificateKey: "certificate",
            approvedDateKey: "approvedDate",
            pointsKey: "points",
            evaluationKey: "evaluation"
        };

        this.collectedUsers = {};
        this.singleUser = {};

        this.setupEvents();
    }
}
