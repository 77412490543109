import { onReady } from "~/js/utils/events/onReady";
import { getTime, parseISO } from "date-fns";
import fetcher from "~/js/api/fetcher";
import { getPoll } from "./api-helpers";
import { isRtl } from "../../utils/helpers/isRtl";
import {
    appendElement,
    deleteElement,
    emptyElement
} from "~/js/utils/dom/elementManipulation";
import { pollMarkup } from "./markup/pollMarkup";
import { pollErrorMarkup } from "./markup/pollErrorMarkup";
import { addEvent, removeAllEvents } from "~/js/utils/events/events";
import {
    hasClass,
    toggleClass,
    removeClass,
    addClass
} from "~/js/utils/dom/classList";
import { forEach } from "~/js/utils/helpers/forEach";
import anime from "animejs";
import { POLL_GLOBAL, POLL_ANSWER } from "~/js/constants/api-end-points";
import { setCookie, getCookie } from "~/js/utils/helpers/cookie";
import { makeButtonLoad } from "~/js/components/button/make-button-load";
import { isExperienceEditor } from "~/js/utils/helpers/isExperienceEditor";
import { enableScrollLock, disableScrollLock } from "~/js/utils/dom/scrollLock";
import {
    onClickOutside,
    removeOnClickOutside
} from "~/js/utils/events/onClickOutside";
import { pollLoginMarkup } from "./markup/pollLoginMarkup";
import {
    Membership,
    membershipStartPages
} from "~/js/components/membership/membership";
import { STANDARDCUBICBEZIER } from "../../constants/easings";
import { utcToZonedTime } from "date-fns-tz";

export class Poll {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;
        this.dom.pollCookie = "pollCookie";
        this.dom.pollErrorCookieValue = "error-poll";
        this.dom.pollCookieValue = getCookie(
            this.dom.pollCookie,
            this.dom.pollCookieValue
        );

        this.dom.pollContainer =
            this.dom.container.querySelector(".poll__container");
        this.dom.mainContent = document.body.querySelector(".main-container");

        this.activeMainClass = "main-container--show-poll";

        this.dom.globalPoll = false;

        window.dataLayer = window.dataLayer || [];

        onReady(() => this.initialize());
    }
    /**
     * @param e
     * Toggle through answers and select it
     * @memberof Poll
     */
    toggleAnswer = e => {
        //toggle classes
        removeClass(this.dom.answersSelect, "button--selected");
        toggleClass(e.target, "button--selected");

        // enable vote btn
        this.dom.voteBtn.removeAttribute("disabled", false);
        this.dom.voteBtn.style.opacity = 1;
    };

    /**
     * Closing the Poll event
     * @memberof Poll
     */
    closeBtnEvent = () => {
        anime({
            targets: this.dom.container,
            opacity: [1, 0],
            duration: 200,
            complete: () => {
                addClass(this.dom.container, "hidden");
            }
        });

        const answerIsSubmitted = window.dataLayer.filter(
            obj => obj.event === "pollSubmit"
        );
        const isListPage = document.querySelector(".poll-quiz-list-page");

        if (isListPage && answerIsSubmitted.length > 0) {
            //reload page by clicking active filter if poll status is changed to display poll status correct after answer is submitted
            const activeFilter = document.querySelector(
                ".poll-list__filter-item.active"
            );
            if (activeFilter) {
                activeFilter.click();
            }
        }

        const pollAnswers = this.dom.container.querySelector(".poll__answers");
        if (pollAnswers) {
            this.dom.pollId = pollAnswers.getAttribute("data-poll-id");
            this.dom.pollCookieValue = this.dom.pollId;
            setCookie(this.dom.pollCookie, this.dom.pollCookieValue);
        } else {
            // We set it to error poll cookie value if errorMarkup is executed becuase we don't have pollId
            this.dom.pollCookieValue = this.dom.pollErrorCookieValue;
            setCookie(this.dom.pollCookie, this.dom.pollCookieValue);
        }

        // Disable events
        this.kill();
    };

    logIn = () => {
        const membershipElement = document.querySelector(
            "[data-module='membership']"
        );
        if (membershipElement) {
            new Membership(membershipElement, membershipStartPages.LOGIN);
        }
    };

    /**
     * Add events to Poll when it loads
     * @memberof Poll
     */
    addEventsToPoll = () => {
        this.dom.answersSelect =
            this.dom.container.querySelectorAll(".answer--select");
        this.dom.voteBtn = this.dom.container.querySelector(".poll__vote-btn");
        this.dom.close = this.dom.container.querySelector(".poll__close");
        this.dom.indicators = this.dom.container.querySelectorAll(".indicator");
        this.dom.totalAnswers =
            this.dom.container.querySelector(".total-answers");
        this.dom.logInButton = this.dom.container.querySelector(
            ".poll__login .button"
        );

        if (this.dom.logInButton) {
            addEvent(this.dom.logInButton, "click", () => {
                if (document.querySelector(".is-app")) {
                    window.location = "aramcolife://openlogin";
                } else {
                    this.logIn();
                }
            });
        }

        // Event for close btn
        if (this.dom.close) {
            addEvent(this.dom.close, "click", this.closeBtnEvent);
        }

        if (this.dom.answersSelect.length > 0) {
            // Event for each answer radio btn
            forEach(this.dom.answersSelect, answerSelect => {
                addEvent(answerSelect, "click", e => this.toggleAnswer(e));
            });

            // Only show total answers on result
            addClass(this.dom.totalAnswers, "hidden");
        }

        // Add event on VoteBtn with loadAnswerMarkup to get answers
        addEvent(this.dom.voteBtn, "click", () => {
            this.loadAnswersMarkup();
        });

        // Animating the poll content
        // Create a timeline with default parameters
        const tl = anime.timeline({
            easing: STANDARDCUBICBEZIER
        });

        tl.add({
            targets: this.dom.container,
            opacity: 1,
            duration: 200
        });

        tl.add({
            targets: ".anime-el",
            opacity: [0, 1],
            translateY: [100, 0],
            delay: anime.stagger(50)
        });

        tl.add(
            {
                targets: this.dom.voteBtn,
                opacity: [0, 0.7],
                translateY: [100, 0]
            },
            400
        );

        if (this.dom.indicators.length > 0) {
            tl.add(
                {
                    targets: this.dom.indicators,
                    width: element => {
                        return `${element.getAttribute("data-number-count")}%`;
                    },
                    delay: anime.stagger(250)
                },
                750
            );
        }
    };

    /**
     * Load AnswerMarkup from API to (pollMarkup.js)
     * @memberof Poll
     */
    loadAnswersMarkup = () => {
        this.dom.pollId =
            this.dom.container
                .querySelector(".poll__answers")
                ?.getAttribute("data-poll-id") ||
            this.dom.container.getAttribute("data-poll-id");

        this.dom.answerId = this.dom.container
            .querySelector(".button--selected")
            .getAttribute("data-answer-id");

        this.dom.pollContent =
            this.dom.container.querySelector(".poll__content");

        const buttonLoader = new makeButtonLoad(
            this.dom.voteBtn,
            isRtl ? "تحميل" : "Loading"
        );

        buttonLoader.load();

        fetcher(`/${isRtl ? "ar" : "en"}${POLL_ANSWER}`, "POST", {
            pollId: this.dom.pollId,
            answerId: this.dom.answerId
        }).then(({ data, isAuthenticated, success, errors }) => {
            buttonLoader.finish(true).then(() => {
                if (success) {
                    let pollAnswerFromId = "";

                    forEach(data.answers, answer => {
                        if (answer.answerId === this.dom.answerId) {
                            pollAnswerFromId = answer.answer;
                        }
                    });

                    window.dataLayer.push({
                        event: "pollSubmit",
                        pollId: data.pollId,
                        pollTitle: data.question,
                        pollAnswer: pollAnswerFromId,
                        pollGlobal: this.dom.globalPoll, // true or false
                        pollAccess: data.isInternal ? "internal" : "external" // Internal or external
                    });

                    deleteElement(this.dom.pollContent);
                    this.loadPollMarkup({ data, showAnswers: isAuthenticated });
                } else {
                    deleteElement(this.dom.pollContent);
                    const pollErrorHtml = pollErrorMarkup(errors);
                    appendElement(pollErrorHtml, this.dom.pollContainer);
                }
            });
        });
    };

    /**
     * load PollMarkup from API (pollMarkup.js)
     * @memberof Poll
     */
    loadPollMarkup = ({
        data,
        showAnswers,
        showCloseButton,
        showExternalContent = false
    }) => {
        const pollHtml = pollMarkup({
            data,
            showAnswers,
            showCloseButton,
            showExternalContent
        });
        appendElement(pollHtml, this.dom.pollContainer);

        if (data.quizGroupBGImageSrc !== "") {
            this.dom.container.style.backgroundImage = `url('${data.quizGroupBGImageSrc}')`;
        }

        this.addEventsToPoll();
    };

    loadOverlay = () => {
        enableScrollLock();
        addClass(this.dom.mainContent, this.activeMainClass);
        onClickOutside(
            this.dom.pollContainer,
            ({ target }) => {
                // Only close when clicking on overlay and not popups like the cookie banner
                if (target.tagName === "BODY") {
                    this.closeBtnEvent();
                }
            },
            window,
            false
        );
    };

    kill = () => {
        removeAllEvents(this.dom.close);
        removeAllEvents(this.dom.answersSelect);
        removeAllEvents(this.dom.voteBtn);

        removeClass(this.dom.mainContent, this.activeMainClass);
        disableScrollLock();
        removeOnClickOutside(this.dom.pollContainer);

        emptyElement(this.dom.pollContainer);
    };

    initialize() {
        const pollId = this.dom.container.getAttribute("data-poll-id");
        let isOutdated = false;
        const unixTimeNow = getTime(
            utcToZonedTime(new Date(Date.now()), "Asia/Riyadh")
        );

        // ************* ARCHIVE POLLS *****************
        if (hasClass(this.dom.container, "poll-archive")) {
            removeClass(this.dom.container, "hidden");

            this.dom.pollId = this.dom.container.getAttribute("data-poll-id");
            this.dom.pollContainer =
                this.dom.container.querySelector(".poll__container");

            const loggedInExternal =
                !hasClass(document.body, "logged-in--internal") &&
                hasClass(document.body, "logged-in");

            getPoll(this.dom.pollId).then(
                ({ data, isAuthenticated, success, errors }) => {
                    if (success) {
                        this.dom.globalPoll = false;

                        isOutdated =
                            getTime(
                                utcToZonedTime(
                                    parseISO(data.endDateUTC),
                                    "Asia/Riyadh"
                                )
                            ) -
                                unixTimeNow <
                            0;
                        if (isAuthenticated) {
                            window.dataLayer.push({
                                event: "pollView",
                                pollId: data.pollId,
                                pollTitle: data.question,
                                pollGlobal: false, // true or false
                                pollAccess: data.isInternal
                                    ? "internal"
                                    : "external" // Internal or external
                            });

                            this.loadPollMarkup({
                                data,
                                showAnswers:
                                    isOutdated ||
                                    data.userHasAlreadyVotedOnPoll,
                                showCloseButton: true,
                                showExternalContent:
                                    data.isInternal && loggedInExternal
                                        ? true
                                        : false
                            });
                        } else {
                            deleteElement(this.dom.pollContent);
                            const pollLoginHtml = pollLoginMarkup(data);
                            appendElement(
                                pollLoginHtml,
                                this.dom.pollContainer
                            );
                            this.addEventsToPoll();
                        }
                        this.loadOverlay();
                    } else {
                        console.warn(
                            "error fetching data from poll",
                            errors.message
                        );
                    }
                }
            );
        } else if (hasClass(this.dom.container, "global-poll")) {
            // ************* GLOBAL POLL *********************
            // Check if a cookie is set before calling the api for votes and if a new poll was created
            if (this.dom.pollCookieValue !== pollId) {
                if (isExperienceEditor) {
                    addClass(this.dom.container, "hidden");
                }

                removeClass(this.dom.container, "hidden");

                fetcher(POLL_GLOBAL).then(
                    // eslint-disable-next-line no-unused-vars
                    ({ data, isAuthenticated, success, errors }) => {
                        if (success) {
                            this.dom.globalPoll = true;
                            isOutdated =
                                getTime(
                                    utcToZonedTime(
                                        parseISO(data.endDateUTC),
                                        "Asia/Riyadh"
                                    )
                                ) -
                                    unixTimeNow <
                                0;
                            if (!isOutdated) {
                                window.dataLayer.push({
                                    event: "pollView",
                                    pollId: data.pollId,
                                    pollTitle: data.question,
                                    pollGlobal: true, // true or false
                                    pollAccess: data.isInternal
                                        ? "internal"
                                        : "external" // Internal or external
                                });

                                this.loadPollMarkup({
                                    data,
                                    showAnswers: data.userHasAlreadyVotedOnPoll,
                                    showCloseButton: true
                                });
                                this.loadOverlay();
                            } else {
                                addClass(this.dom.container, "hidden");
                            }
                        } else {
                            addClass(this.dom.container, "hidden");
                            console.warn(
                                "error fetching data from poll",
                                errors.message
                            );
                        }
                    }
                );
            } else {
                addClass(this.dom.container, "hidden");
            }
        } else {
            // ************** POLL ON ARTICLE *****************
            this.dom.pollId = this.dom.container.getAttribute("data-poll-id");
            this.dom.pollContainer =
                this.dom.container.querySelector(".poll__container");
            const loggedInExternal =
                !hasClass(document.body, "logged-in--internal") &&
                hasClass(document.body, "logged-in");

            getPoll(this.dom.pollId).then(
                ({ data, isAuthenticated, success, errors }) => {
                    if (success) {
                        isOutdated =
                            getTime(
                                utcToZonedTime(
                                    parseISO(data.endDateUTC),
                                    "Asia/Riyadh"
                                )
                            ) -
                                unixTimeNow <
                            0;
                        if (isAuthenticated) {
                            if (!isOutdated) {
                                window.dataLayer.push({
                                    event: "pollView",
                                    pollId: data.pollId,
                                    pollTitle: data.question,
                                    pollGlobal: false, // true or false
                                    pollAccess: data.isInternal
                                        ? "internal"
                                        : "external" // Internal or external
                                });
                                this.loadPollMarkup({
                                    data,
                                    showAnswers: data.userHasAlreadyVotedOnPoll,
                                    showCloseButton: false,
                                    showExternalContent:
                                        !isExperienceEditor &&
                                        data.isInternal &&
                                        loggedInExternal
                                            ? true
                                            : false
                                });
                            } else if (!isExperienceEditor) {
                                addClass(this.dom.container, "hidden");
                            }
                        } else {
                            deleteElement(this.dom.pollContent);
                            const pollLoginHtml = pollLoginMarkup(data, true);
                            appendElement(
                                pollLoginHtml,
                                this.dom.pollContainer
                            );
                            this.addEventsToPoll();
                        }
                    } else {
                        console.warn(
                            "error fetching data from poll",
                            errors.message
                        );
                    }
                }
            );
        }
    }
}
