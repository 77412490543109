/**
 * Make your button load
 *
 * import { makeButtonLoad } from "../button/make-button-load";
 *
 * const buttonLoader = new makeButtonLoad(this.dom.continueButton, "Loading");
 * buttonLoader.load();
 *
 * buttonLoader.finish().then(() => {
 *
 * });
 *
 *

 */

import {
    parseHTML,
    appendElement,
    deleteElement
} from "~/js/utils/dom/elementManipulation";
import checkmark from "../checkmark/checkmark";

import anime from "animejs";

export class makeButtonLoad {
    constructor(button, loadingLabel) {
        this.loadingLabel = loadingLabel;
        this.dom = {
            button
        };

        this.dom.targetText = this.dom.button.querySelector("span");
        this.currentProgressAnim = undefined;
    }

    load = () => {
        this.dom.button.classList.remove("success");
        const successCheckmark = parseHTML(checkmark);
        const targetText = this.dom.button.querySelector("span");
        this.dom.loadingHtml = parseHTML(
            `<span class="loading-text">${this.loadingLabel}<span class="progress"></span></span>`
        );
        appendElement(this.dom.loadingHtml, this.dom.button);
        this.dom.button.disabled = true;
        const loadingProgress = this.dom.button.querySelector(".progress");

        const animateProgress = () => {
            return anime({
                targets: loadingProgress,
                duration: 2500,
                width: ["0%", "100%"],
                easing: "linear",
                complete: () => {
                    setTimeout(() => {
                        this.currentProgressAnim = animateProgress();
                    }, 500);
                }
            });
        };

        anime
            .timeline({
                easing: "linear",
                begin: () => {
                    this.dom.button.classList.add("loading");
                },
                complete: () => {
                    appendElement(successCheckmark, this.dom.button);
                    this.currentProgressAnim = animateProgress();
                }
            })
            .add({
                targets: targetText,
                duration: 300,
                translateY: -10,
                opacity: 0
            })
            .add(
                {
                    targets: this.dom.loadingHtml,
                    duration: 300,
                    translateY: [10, 0],
                    opacity: 1
                },
                50
            );
    };

    finish = (repeatedLoading = false) => {
        return new Promise(resolve => {
            this.dom.button.classList.remove("loading");
            this.dom.button.classList.add("success");

            if (this.currentProgressAnim && this.currentProgressAnim.pause) {
                this.currentProgressAnim.pause();
            }

            anime
                .timeline({
                    easing: "easeOutCubic",
                    duration: 300,
                    complete: () => {
                        setTimeout(() => {
                            this.dom.button.disabled = false;
                            resolve();

                            if (repeatedLoading) {
                                deleteElement(
                                    this.dom.button.querySelector("svg")
                                );
                                deleteElement(this.dom.loadingHtml);
                                this.dom.targetText.style.transform =
                                    "translateY(0)";
                                this.dom.targetText.style.opacity = 1;
                            }
                        }, 100);
                    }
                })
                .add({
                    targets: this.dom.loadingHtml,
                    translateY: -10,
                    opacity: 0
                })
                .add(
                    {
                        targets: this.dom.button.querySelector("svg"),
                        scale: [0.2, 1]
                    },
                    150
                );
        });
    };
}
