import { buildCheckboxInput } from "~/js/components/volunteer/signup/inputs/buildCheckboxInput";

const stepFiveContentTemplate = ({ classes, sections }) => {
    const errorMessageStepFive = "step-five";

    return `
        <div class="${classes.rootClass}__section">
            <fieldset class="${classes.rootClass}__fieldset">
                <legend class="visuallyhidden">Step 5</legend>
                <div class="${classes.rootClass}__checkbox-group ${
        classes.rootClass
    }__checkbox-group--default">
                    <h3>${sections.title}</h3>
                    <ul class="${classes.rootClass}__vertical-list">
                        ${buildCheckboxInput(
                            sections.fields[0],
                            errorMessageStepFive
                        )}
                    </ul>

                    <p class="${errorMessageStepFive}"></p>
                </div>
            </fieldset>
        </div>
    `;
};

export default stepFiveContentTemplate;
