import { appendElement } from "~/js/utils/dom/elementManipulation";
import { enterEmailMarkup } from "./markup";
import { addEvent, removeAllEvents } from "~/js/utils/events/events";
import { showError, removeErrors } from "~/js/components/input/input-errors";
import { validateEmail } from "~/js/utils/helpers/validate";
import fetcher from "~/js/api/fetcher";
import { makeButtonLoad } from "~/js/components/button/make-button-load";
import {
    Membership,
    membershipStartPages
} from "~/js/components/membership/membership";

export class EnterEmail {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {};

    /**
     *
     * @param {Element} domReference - The element to work from. -> membership__content
     */
    constructor(config) {
        this.dom.membershipContent = config.membershipContent;
        this.texts = config.texts;
        this.user = config.user ? config.user : {};
        this.errorMessages = config.errorMessages;
        this.verifyEmailApiUrl = config.verifyEmailApiUrl;

        this.callbacks = {
            onContinue: config.onContinue
        };

        this.initialize();
    }

    validateEmail = () => {
        if (this.dom.emailInput.value === "") {
            showError(this.dom.emailInput, this.errorMessages.emailRequired);
            return;
        }

        if (!validateEmail(this.dom.emailInput.value)) {
            showError(this.dom.emailInput, this.errorMessages.invalideEmail);
            return;
        }

        const buttonLoader = new makeButtonLoad(
            this.dom.continueButton,
            this.texts.loading
        );
        buttonLoader.load();

        fetcher(this.verifyEmailApiUrl, "POST", {
            email: this.dom.emailInput.value
        }).then(result => {
            if (result.success) {
                removeErrors();

                removeAllEvents(this.dom.continueButton);
                buttonLoader.finish().then(() => {
                    this.callbacks.onContinue(this.dom.emailInput.value);
                });
            } else {
                buttonLoader.finish(true).then(() => {
                    showError(this.dom.emailInput, result.errors[0].message);
                });

                if (result.data.emailAlreadyExist && result.data.isVerified) {
                    const membershipElement = document.querySelector(
                        "[data-module='membership']"
                    );
                    new Membership(
                        membershipElement,
                        membershipStartPages.LOGIN,
                        {
                            email: this.dom.emailInput.value,
                            message: this.errorMessages.accountAlreadyExists
                        }
                    );
                }
            }
        });
    };

    initialize() {
        const enterEmailHtml = enterEmailMarkup(this.texts);

        appendElement(enterEmailHtml, this.dom.membershipContent);

        this.dom.membershipContent.className =
            "membership__content membership__content--signup-email";

        this.dom.emailInput =
            this.dom.membershipContent.querySelector("#email");

        if (this.user.email) {
            this.dom.emailInput.value = this.user.email;
        }

        this.dom.continueButton = this.dom.membershipContent.querySelector(
            ".membership-footer .button"
        );
        this.dom.continueButton.classList.add("animate-scale");

        addEvent(this.dom.continueButton, "click", this.validateEmail);
    }
}
