import fetcher from "~/js/api/fetcher";
import { POLL, POLL_LIST, POLL_QUIZ_LIST } from "~/js/constants/api-end-points";
import { isRtl } from "~/js/utils/helpers/isRtl";

/**
 * @param {*} pollId
 * @returns promise with poll
 * @memberof Poll
 */

export function getPoll(pollId) {
    const getPollUrl = `${POLL}?pollId=${pollId}`;

    return fetcher(`/${isRtl ? "ar" : "en"}${getPollUrl}`, "GET");
}

/**
 * @param {*} status
 * @param {*} startIndex
 * @param {*} length
 * @returns promise with polls
 * @memberof Poll
 */

export function getPolls(status, startIndex, length) {
    const getPollListUrl = `${POLL_LIST}?model.startIndex=${startIndex}&model.length=${length}&model.status=${status}`;

    return fetcher(`/${isRtl ? "ar" : "en"}${getPollListUrl}`, "GET");
}

/**
 * @param {*} status
 * @param {*} startIndex
 * @param {*} length
 * @returns promise with polls
 * @memberof Poll
 */

export function getPollQuizzes(quizGroupId, startIndex, length) {
    const getPollQuizzesListUrl = `${POLL_QUIZ_LIST}?quizGroupId=${quizGroupId}&startIndex=${startIndex}&length=${length}`;

    return fetcher(`/${isRtl ? "ar" : "en"}${getPollQuizzesListUrl}`, "GET");
}
