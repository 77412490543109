import { onReady } from "~/js/utils/events/onReady";
import { forEach } from "~/js/utils/helpers/forEach";
import Flickity from "flickity";

export class CertificateList {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;
        this.dom.certificateListSlider =
            this.dom.container.querySelector(".certificate__list");
        this.dom.certificateListSliderElements =
            this.dom.certificateListSlider.querySelectorAll(".gallery-cell");

        onReady(() => this.initialize());
    }

    // Checking if we have multiple elements for later use (page dots, buttons etc)
    multipleElementsLayout = () => {
        return this.dom.certificateListSliderElements.length > 2;
    };

    initialize() {
        const certificateListFlickity = {
            pageDots: this.multipleElementsLayout(),
            prevNextButtons: false,
            staticClick: true,
            wrapAround: false,
            cellAlign: "left",
            lazyLoad: 2,
            on: {
                ready: () => {
                    forEach(this.dom.certificateListSliderElements, cell => {
                        cell.style.height = "100%";
                    });
                },
                dragStart: () => {
                    this.dom.container.classList.add("translating");
                },
                dragEnd: () => {
                    this.dom.container.classList.remove("translating");
                },
                settle: () => {
                    this.dom.container.classList.remove("translating");
                }
            }
        };

        // eslint-disable-next-line no-unused-vars
        const certificateListSlider = new Flickity(
            this.dom.certificateListSlider,
            certificateListFlickity
        );
    }
}
