import { parseHTML } from "~/js/utils/dom/elementManipulation";
import { closeMarkup } from "../../../close";

export const enterNameMarkup = labels => {
    const { headerTitle, firstNameLabel, lastNameLabel, continueLabel } =
        labels;

    return parseHTML(`
    <div class="membership-header">
        ${closeMarkup}
        <h2 class="animate-fade animate-fade--once membership-header__title"><strong>${headerTitle}</strong></h2>
    </div>
    <div class="membership-middle membership-middle--signup-name">
        <div class="animate-translate input">
            <input required type="text" id="firstName" name="firstName" />
            <label for="firstName">${firstNameLabel}</label>
            <span class="error"></span>
        </div>
        <div class="animate-translate input">
            <input required type="text" id="lastName" />
            <label for="lastName">${lastNameLabel}</label>
            <span class="error"></span>
        </div>
    </div>
    <div class="membership-footer membership-footer--signup-name">
        <button class="animate-scale animate-scale--once button button--primary" type="submit">
            <span>${continueLabel}</span>
        </button>
        <div class="animate-fade animate-fade--once signup-pagination">
            <button type="button" id="1" class="signup-pagination__item signup-pagination__item--current"></button>
            <button type="button" id="2" class="signup-pagination__item"></button>
            <button type="button" id="3" class="signup-pagination__item"></button>
        </div>
    </div>`);
};
