import { onReady } from "~/js/utils/events/onReady";
import {
    Membership,
    membershipStartPages
} from "~/js/components/membership/membership";
import { addEvent, delegateEvent } from "../../utils/events/events";

export class SignupRibbon {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;

        // If the 'data-module' attribute is added directly to a link or a button
        if (
            this.dom.container.tagName === "A" ||
            this.dom.container.tagName === "BUTTON"
        ) {
            // signupButton is now the domReference
            this.dom.signupButton = this.dom.container;

            // Check if it is a link and then remove the href to prevent following the link
            if (this.dom.signupButton.hasAttribute("href")) {
                this.dom.signupButton.href = "";
            }
        } else {
            this.dom.signupButton = this.dom.container.querySelector(
                ".signup-ribbon__signup button"
            );
            this.dom.loginButton = ".signup-ribbon__link";
        }

        onReady(() => this.initialize());
    }

    signUp = () => {
        const membershipElement = document.querySelector(
            "[data-module='membership']"
        );
        if (membershipElement) {
            new Membership(membershipElement, membershipStartPages.SIGNUP);
        }
    };

    logIn = () => {
        const membershipElement = document.querySelector(
            "[data-module='membership']"
        );
        if (membershipElement) {
            new Membership(membershipElement, membershipStartPages.LOGIN);
        }
    };

    initialize() {
        addEvent(this.dom.signupButton, "click", e => {
            e.preventDefault();
            if (document.querySelector(".is-app")) {
                window.location = "aramcolife://opensignup";
            } else {
                this.signUp();
            }
        });

        delegateEvent(this.dom.loginButton, "click", () => {
            if (document.querySelector(".is-app")) {
                window.location = "aramcolife://openlogin";
            } else {
                this.logIn();
            }
        });
    }
}
