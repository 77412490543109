import { parseHTML } from "~/js/utils/dom/elementManipulation";
import { closeMarkup } from "../../../../close";

export const addDependentMarkup = labels => {
    const {
        addDependentIntroTitle,
        addDependentIntroText,
        addDependentButtonLabel,
        emailLabel,
        cancelButtonLabel
    } = labels;

    return parseHTML(`
    <div class="membership-header">
        ${closeMarkup}
        <h2 class="membership-header__title"><strong>${addDependentIntroTitle}</strong></h2>
        <div class="membership-header__intro">${addDependentIntroText}</div>
    </div>
    <div class="animate-fade membership-middle dependent">
        <div class="animate-translate input">
            <input autocomplete="off" required type="email" id="email" class="email" />
            <label for="email">${emailLabel}</label>
            <span class="error"></span>
        </div>
    </div>
    <div class="membership-footer">
        <button class="animate-scale animate-scale--once button button--primary button--continue" type="submit">
            <span>${addDependentButtonLabel}</span>
        </button>
        <button type="button" class="button-link button-link--secondary button--cancel">${cancelButtonLabel}</button>
    </div>`);
};
