import { onReady } from "~/js/utils/events/onReady";
import { breakpointMin } from "~/js/utils/events/onWindowResize";
import Flickity from "flickity";

export class YoutubeSlider {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;
        this.dom.youtubeSlider = this.dom.container.querySelector(
            ".youtube-slider__carousel"
        );
        onReady(() => this.initialize());
    }

    initialize() {
        const YTFlickity = {
            pageDots: true,
            freeScroll: true,
            wrapAround: true
        };

        if (breakpointMin("xs")) {
            YTFlickity.cellAlign = "center";
        }

        if (breakpointMin("lg")) {
            YTFlickity.cellAlign = "left";
        }

        // eslint-disable-next-line no-unused-vars
        const ytSlider = new Flickity(this.dom.youtubeSlider, YTFlickity);
    }
}
