import { addEvent, removeAllEvents } from "~/js/utils/events/events";
import { ToggleVisiblePassword } from "../../../input/toggle-visible-password";
import fetcher from "~/js/api/fetcher";
import {
    showError,
    removeErrors,
    removeError
} from "~/js/components/input/input-errors";
import { Snackbar } from "~/js/components/snackbar/snackbar";

export class ChangePassword {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {};

    /**
     *
     * @param {Element} domReference - The element to work from. -> membership__content
     */
    constructor(config, animate) {
        this.config = config;
        this.dom.membershipContent = config.membershipContent;
        this.texts = config.texts;
        this.user = config.user ? config.user : {};
        this.errorMessages = config.errorMessages;
        this.animate = animate;

        this.changePasswordApiUrl = config.changePasswordApiUrl;

        this.initialize();
    }

    kill() {
        removeAllEvents([
            this.dom.changePasswordButton,
            this.dom.cancelChangePasswordButton
        ]);
        removeAllEvents([
            this.inputs.NewPassword,
            this.inputs.OldPassword,
            this.inputs.RepeatPassword
        ]);
        removeAllEvents(this.dom.savePassword);
    }

    validatePassword = () => {
        if (this.inputs.OldPassword.value === "") {
            showError(
                this.inputs.OldPassword,
                this.errorMessages.oldPasswordRequired
            );
            return false;
        }

        if (this.inputs.NewPassword.value === "") {
            showError(
                this.inputs.NewPassword,
                this.errorMessages.newPasswordRequired
            );
            return false;
        }

        removeErrors();
        return true;
    };

    savePassword = () => {
        if (!this.validatePassword()) {
            return;
        }

        fetcher(this.changePasswordApiUrl, "POST", {
            oldPassword: this.inputs.OldPassword.value,
            newPassword: this.inputs.NewPassword.value,
            repeatPassword: this.inputs.NewPassword.value
        }).then(result => {
            if (result.success) {
                this.toggleChangePassword(undefined, true);

                this.inputs.OldPassword.value = "";
                this.inputs.NewPassword.value = "";
            } else {
                result.errors.map(error =>
                    showError(this.inputs[error.property], error.message)
                );
            }
        });
    };

    toggleChangePassword = (_, passwordChanged) => {
        const open =
            this.dom.enterPassword.classList.contains("visuallyhidden");

        if (open) {
            this.animate(this.dom.changePassword, false, () =>
                this.animate(this.dom.enterPassword, true)
            );
        } else {
            this.animate(this.dom.enterPassword, false, () =>
                this.animate(this.dom.changePassword, true, () => {
                    if (passwordChanged) {
                        new Snackbar({
                            element: this.dom.changePassword,
                            text: this.texts.changePasswordConfirmation,
                            autoClose: true,
                            confirm: false
                        });
                    }
                })
            );
        }

        this.closeEditName();
    };

    closeEditName = () => {
        if (!this.dom.editableName.classList.contains("visuallyhidden")) {
            this.animate(this.dom.editableName, false, () =>
                this.animate(this.dom.name, true)
            );
        }
    };

    onPasswordFocus(e) {
        const password = e.target;
        removeError(password);
    }

    initialize() {
        const passwordToggleButtons =
            this.dom.membershipContent.querySelectorAll(
                ".toggle-visible-password"
            );
        this.togglePasswordVisibility = passwordToggleButtons.forEach(
            button => new ToggleVisiblePassword(button)
        );

        this.inputs = {
            OldPassword:
                this.dom.membershipContent.querySelector("#oldPassword"),
            NewPassword:
                this.dom.membershipContent.querySelector("#newPassword"),
            RepeatPassword:
                this.dom.membershipContent.querySelector("#newPassword")
        };

        this.dom.changePassword =
            this.dom.membershipContent.querySelector(".change-password");
        this.dom.changePasswordButton =
            this.dom.membershipContent.querySelector(".change-password-button");
        this.dom.cancelChangePasswordButton =
            this.dom.membershipContent.querySelector(".cancel-change-password");
        this.dom.savePasswordButton =
            this.dom.membershipContent.querySelector(".save-password");

        this.dom.enterPassword =
            this.dom.membershipContent.querySelector(".enter-password");

        this.dom.name = this.dom.membershipContent.querySelector(".name");
        this.dom.editableName =
            this.dom.membershipContent.querySelector(".editable-name");

        addEvent(
            [
                this.inputs.NewPassword,
                this.inputs.OldPassword,
                this.inputs.RepeatPassword
            ],
            "focus",
            this.onPasswordFocus
        );
        addEvent(
            [
                this.dom.changePasswordButton,
                this.dom.cancelChangePasswordButton
            ],
            "click",
            this.toggleChangePassword
        );
        addEvent(this.dom.savePasswordButton, "click", this.savePassword);
    }
}
