import { parseHTML } from "~/js/utils/dom/elementManipulation";
import { hasClass } from "../../../utils/dom/classList";

export const travelDestinationMarkup = (dataObject) => {
    const isLoggedInMemberInternal = hasClass(
        document.body,
        "logged-in--internal"
    );

    const isLoggedInMemberRetiree = hasClass(
        document.body,
        "logged-in--retiree"
    );

    return parseHTML(`
        <div class="travel-destination__list">
            <div class="travel-destination__list-inner-wrapper">
                    ${isLoggedInMemberInternal || isLoggedInMemberRetiree
        ? `<a href="${dataObject.url}" class="restrict-content" aria-label="${dataObject.title}">
                    <picture>
                        <source data-srcset="${dataObject.coverImage?.mediumImageUrl}" />
                        <img alt="${dataObject.title}" src="${dataObject.coverImage?.mediumImageUrl}" />
                    </picture>
            </a>`
        : `<button
                data-href="${dataObject.url}" class="restrict-content" ${
                dataObject.isInternal
                    ? "data-restrict"
                    : ""
            } aria-label="${dataObject.title}">
                <picture>
                    <img alt="${dataObject.title}" src="${dataObject.coverImage?.mediumImageUrl}"/>
                </picture>
            </button>
            `}

            </div>
            <div class="travel-destination__title">
                ${dataObject.formattedDate}
                ${
                    dataObject.isInternal
                        ? `
                        <div class="restrict-content__indication travel-destination__restriction">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
                                <path d="M6,7.09c-2,0-6,1.1-6,3.27V12H12V10.36C12,8.19,8,7.09,6,7.09Zm1.32-2a2.73,2.73,0,1,0-2.64,0,2.73,2.73,0,0,0,2.64,0Z"></path>
                            </svg>
                        </div>
                    `
                        : ""
                }
            </div>
             <h4 class="travel-destination__title travel-destination__title--large">
                ${dataObject.title}
            </h4>
        </div>
    `);
};
