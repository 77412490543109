import { parseHTML } from "~/js/utils/dom/elementManipulation";
import { closeMarkup } from "../../../../close";

export const confirmAddMarkup = (labels, errorMessages, data) => {
    const {
        confirmAddDependentIntroTitle,
        confirmAddDependentIntroText,
        confirmAddDependentButtonLabel,
        cancelButtonLabel
    } = labels;

    const { name, email } = data;

    return parseHTML(`
    <div class="membership-header">
        ${closeMarkup}
        <h2 class="membership-header__title"><strong>${confirmAddDependentIntroTitle}</strong></h2>
        <div class="membership-header__intro">${confirmAddDependentIntroText}</div>
    </div>
    <div class="animate-fade membership-middle dependent">
        <p>${name ? name : ""}</p>
        <p>${email}</p>
        <hr />
        ${
            data.accountWithEmailExists
                ? ""
                : `<p>${errorMessages.dependencyWithEmailNoAccount}</p>`
        }
    </div>
    <div class="membership-footer">
        <span class="error"></span>
        <button class="animate-scale animate-scale--once button button--primary button--confirm" type="submit">
            <span>${confirmAddDependentButtonLabel}</span>
        </button>
        <button type="button" class="button-link button-link--secondary button--cancel">${cancelButtonLabel}</button>
    </div>`);
};
