import { buildTextareaInput } from "~/js/components/volunteer/signup/inputs/buildTextareaInput";
import { buildTextInput } from "~/js/components/volunteer/signup/inputs/buildTextInput";

const stepSevenContentTemplate = ({ classes, sections }) => {
    const [sectionAnythingToAdd, sectionUsername] = sections;

    return `
        <div class="${classes.rootClass}__section">
            <fieldset class="${classes.rootClass}__fieldset">
                <legend class="visuallyhidden">Step 6</legend>

                <div class="${classes.rootClass}__textarea-group ${
        classes.rootClass
    }__textarea-group--default">
                    <h3>${sectionAnythingToAdd.title}</h3>
                    ${buildTextareaInput(sectionAnythingToAdd.fields[0])}
                </div>

                <div class="${classes.rootClass}__username-section">
                <h3>${sectionUsername.title}</h3>
                <p>${sectionUsername.description}</p>
                ${sectionUsername.fields
                    .map(field => `${buildTextInput(field, classes)}`)
                    .join("")}
                </div>
            </fieldset>
        </div>
    `;
};

export default stepSevenContentTemplate;
