import { onReady } from "~/js/utils/events/onReady";
import {
    appendElement,
    deleteElement
} from "../../utils/dom/elementManipulation";
import { addEvent, removeAllEvents } from "../../utils/events/events";
import { inTheNewsItemMarkup } from "./markup/inTheNewsItemMarkup";
import { setupInView } from "~/js/utils/dom/inView";
import { removeClass } from "~/js/utils/dom/classList";
import fetcher from "../../api/fetcher";
import { isRtl } from "../../utils/helpers/isRtl";
import { addLoader } from "../loader/loader";
import { createElement } from "../../utils/dom/createElement";

export class InTheNews {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;

        this.endPoint = this.dom.container.dataset.endpoint;
        this.loadMoreAmount = parseInt(this.dom.container.dataset.listLength);
        this.loadedAmount = this.loadMoreAmount;
        this.currentCountIndex = 0;
        this.totalNewsItems = undefined;

        this.settings = {
            searchInProgress: false,
            showLoader: false,
            loaderSuspense: 400
        };

        onReady(() => this.initialize());
    }

    addLoader = parentAppendingTo => {
        const wrapper = createElement("div", {
            className: "in-the-news__loader"
        });

        addLoader(parentAppendingTo, {
            wrapperElement: wrapper
        });
    };

    removeLoader = () => {
        this.dom.loaderElement = this.dom.container.querySelector(
            ".in-the-news__loader"
        );
        deleteElement(this.dom.loaderElement);
    };

    loadInitialItems = () => {
        // Handling the loader suspense
        this.settings.searchInProgress = true;

        setTimeout(() => {
            if (this.settings.searchInProgress) {
                this.settings.showLoader = true;

                // Showing our loader since the threshold from the suspense is not reached
                this.addLoader(this.dom.inTheNewsList);
            }
        }, this.settings.loaderSuspense);

        fetcher(
            `${this.endPoint}?language=${isRtl ? "arabic" : "english"}`,
            "GET"
        ).then(response => {
            this.settings.searchInProgress = false;

            if (this.settings.showLoader) {
                this.settings.showLoader = false;

                this.removeLoader(this.dom.inTheNewsList);
            }

            if (response?.success) {
                // Storing the data for load more use
                this.newsData = response.data;
                this.totalNewsItems = this.newsData.length;

                this.newsData.forEach((item, count) => {
                    if (count <= this.loadMoreAmount - 1) {
                        this.loadMarkup(item);
                    }
                });

                if (this.totalNewsItems > this.loadedAmount) {
                    removeClass(
                        this.dom.loadMoreButton,
                        "in-the-news__load-more--hide"
                    );
                }
            } else {
                appendElement(
                    `${
                        isRtl
                            ? `يبدو أن هناك مشكلة في الحصول على الأخبار. الرجاء المحاولة في وقت لاحق `
                            : `Looks like there was a problem getting the news. Please try again later.`
                    }`,
                    this.dom.inTheNewsList
                );
            }
        });
    };

    loadMore() {
        if (this.loadedAmount < this.totalNewsItems) {
            // Disable button while getting more polls
            this.dom.loadMoreButton.disabled = true;

            this.currentCountIndex =
                this.currentCountIndex + this.loadMoreAmount;

            this.newsData.forEach((item, count) => {
                if (
                    count > this.loadedAmount - 1 &&
                    count < this.loadMoreAmount + this.loadedAmount
                ) {
                    this.loadMarkup(item);
                }
            });

            this.loadedAmount = this.currentCountIndex + this.loadMoreAmount;

            // If no more polls remove listeners
            if (this.loadedAmount >= this.totalNewsItems) {
                this.kill();
                this.dom.loadMoreButton.style.display = "none";
            } else {
                this.dom.loadMoreButton.disabled = false;
            }
        }
    }

    loadMarkup(item) {
        const inTheNewsListHtml = inTheNewsItemMarkup(item);
        appendElement(inTheNewsListHtml, this.dom.inTheNewsList);

        const inViewClass = ".inview";
        const inViewElements =
            this.dom.inTheNewsList.querySelector(inViewClass);

        if (inViewElements) {
            setupInView(inViewClass, "inview--active", "show", 0);
        }
    }

    kill() {
        removeAllEvents(this.dom.loadMoreButton);
    }

    initialize() {
        this.dom.inTheNewsList =
            this.dom.container.querySelector(".in-the-news__list");
        this.dom.loadMoreButton = this.dom.container.querySelector(
            ".in-the-news__load-more"
        );

        this.loadInitialItems();

        addEvent(this.dom.loadMoreButton, "click", () => {
            this.loadMore();
        });
    }
}
