import { parseHTML } from "~/js/utils/dom/elementManipulation";
import { isIE11 } from "~/js/utils/helpers/isIE11";
import { differenceInHours, format, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { getLocalTimeZone } from "~/js/utils/helpers/getLocalTimeZone";
import { isRtl } from "~/js/utils/helpers/isRtl";
import { arSA } from "date-fns/locale";

const setDateString = dataObject => {
    const localTimeZone = getLocalTimeZone;
    const formatOptions = isRtl ? { locale: arSA } : "";

    const endDateFormat =
        differenceInHours(
            parseISO(dataObject.startDateUTC),
            parseISO(dataObject.endDateUTC)
        ) <= 23
            ? "HH:mm"
            : "d MMMM HH:mm";

    const startDate = format(
        utcToZonedTime(parseISO(dataObject.startDateUTC), localTimeZone),
        "d MMMM HH:mm",
        formatOptions
    );

    const endDate = format(
        utcToZonedTime(parseISO(dataObject.endDateUTC), localTimeZone),
        endDateFormat,
        formatOptions
    );

    return `${startDate} - ${endDate}`;
};

export const videoMarkup = dataObject => {
    const formattedLocalDate = isIE11
        ? dataObject.dateString
        : setDateString(dataObject);
    return parseHTML(`
        <div class="video-episode-teaser">
            <a href="${
                dataObject.url
            }" class="video-episode-teaser__link-wrapper">
                <div class="video-episode-teaser__thumbnail inview">
                    ${
                        dataObject.image.hasValue
                            ? `<svg id="f4a18abe-a3eb-44e8-aeeb-c6f6946cc06a" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.99 29.83">
                                <rect width="47.99" height="29.83" rx="1.3" fill="#f05f41" />
                                <path d="M19.9,20.86V10.79a1.3,1.3,0,0,1,1.84-1.18l10.07,4.65a1.3,1.3,0,0,1,.07,2.32L21.81,22A1.29,1.29,0,0,1,19.9,20.86Z" transform="translate(-0.44 -0.98)" fill="#fff" />
                            </svg>
        
                            <img alt="${dataObject.title}" class="lazy" data-src="${dataObject.image.smallImageUrl}" />
        
                            <noscript>
                                <img src="${dataObject.image.smallImageUrl}" alt="${dataObject.title}">
                            </noscript>`
                            : ""
                    }                    
                </div>
                <div class="video-episode-teaser__content">
                    <div class="video-episode-teaser__date inview">
                        ${formattedLocalDate}
                    </div>
                    <div class="video-episode-teaser__text-wrapper">
                        <h3 class="title inview">
                            <span class="video-episode-teaser__show">${
                                dataObject.show
                            }</span>
                            ${dataObject.title}
                        </h3>
                    </div>
                </div>
            </a>
        </div>
     `);
};
