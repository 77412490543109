import { parseHTML } from "~/js/utils/dom/elementManipulation";

export const hostMarkup = (labels, host) => {
    const { myDependencyDisconnectLabel } = labels;

    const { hostName, hostEmail } = host;

    return parseHTML(`
    <div class="dependent__host-wrapper">
        <div class="dependent__user">
            <p>${hostName}</p>
            <p>${hostEmail}</p>
        </div>
        <button type="button" class="button-link button-link--secondary button--icon button--disconnect">${myDependencyDisconnectLabel}</button>
    </div>
    `);
};
