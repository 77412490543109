import { onReady } from "~/js/utils/events/onReady";
import { removeClass, addClass } from "~/js/utils/dom/classList";
import { enableScrollLock, disableScrollLock } from "~/js/utils/dom/scrollLock";
import { addEvent, removeEvent } from "~/js/utils/events/events.js";

function createScriptElement(src, isAsync, container = document.body) {
    return new Promise((resolve) => {
        const scriptTag = document.createElement("script");
        scriptTag.setAttribute("src", src);
        if (isAsync) {
            scriptTag.setAttribute("async", "true");
        }
        container.appendChild(scriptTag);

        scriptTag.addEventListener("load", () => {
            resolve();
        });
    });
}

export class PhotoCompetitionOpenUploadModal {

    dom = {};

    constructor(domReference, competitionId, termsLink, dictionary) {
        // View
        // src\Feature\PhotoCompetition\code\Areas\PhotoCompetition\Views\PhotoCompetition\PhotoCompetitionUploadModal.cshtml

        this.competitionId = competitionId;
        this.dictionary = dictionary;

        this.dom.container = domReference;
        this.dom.modal = document.querySelector("[data-photo-competition-upload-modal]");
        this.dom.closeButton = this.dom.modal.querySelector("[data-close-upload-modal]");
        this.dom.modalContent = this.dom.modal.querySelector("[data-modal-content]");
        this.dom.terms = this.dom.modal.querySelector(".photo-competition__terms");

        if (this.dom.terms) {
            this.dom.terms.innerHTML = termsLink;
        }

        onReady(() => {
            this.initialize()
            .catch(error => {
                console.error("Error", error);
            });
        });
    }

    async initialize() {
        const closeModal = () => {
            removeClass(this.dom.modal, "photo-competition__upload-modal--open");
            disableScrollLock();

            if (this.dom.modalContent) {
                this.dom.modalContent.innerHTML = "";
            }
        }

        const keyup = e => {
            const key = e.charCode || e.keyCode || 0;

            if (key === 27) {
                closeModal();
                removeEvent(document, "keyup", keyup);
            }
        }

        addEvent(document, "keyup", keyup);

        addEvent(this.dom.container, "click", () => {
            const uploadDisabled = this.dom.container.dataset.uploadDisabled === "true";

            addClass(this.dom.modal, "photo-competition__upload-modal--open");
            enableScrollLock();

            if (this.dom.modalContent) {

                if (!uploadDisabled) {
                    const gleamTag = document.createElement("a");
                    gleamTag.setAttribute("class", "e-widget no-button generic-loader");
                    gleamTag.setAttribute("href", `https://gleam.io/${this.competitionId}/competition`);
                    gleamTag.setAttribute("rel", "nofollow");

                    this.dom.modalContent.appendChild(gleamTag);

                    createScriptElement("https://widget.gleamjs.io/e.js", true, this.dom.modalContent);
                } else {
                    this.dom.modalContent.innerHTML = `<div class="photo-competition__restrict-upload-content"><p>${this.dictionary.restrictedUploads}</p></div>`;
                }
            }
        });

        this.dom.closeButton.addEventListener("click", closeModal);
    }
}
