import { parseHTML } from "~/js/utils/dom/elementManipulation";

export const pollContainerMarkup = data => {
    const { id } = data;
    return parseHTML(`
        <div class="poll poll-archive global-poll hidden" data-poll-id="${id}">
            <div class="poll__container"></div>
        </div>
    `);
};
