import { onReady } from "~/js/utils/events/onReady";
import {
    Membership,
    membershipStartPages
} from "~/js/components/membership/membership";
import { addEvent, delegateEvent } from "../../utils/events/events";
import fetcher from "../../api/fetcher";
import { addClass, hasClass, removeClass } from "../../utils/dom/classList";
import { forEach } from "../../utils/helpers/forEach";
import { appendElement, parseHTML } from "../../utils/dom/elementManipulation";
import {
    setScrollLock,
    removeScrollLock
} from "../../utils/helpers/extendedScrollLock";
import {
    onClickOutside,
    removeOnClickOutside
} from "../../utils/events/onClickOutside";
import anime from "animejs";
import { STANDARDCUBICBEZIER } from "../../constants/easings";
import { MEDIUM } from "../../constants/durations";

export class RestrictContent {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    data = {};

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(domReference) {
        this.dom.container = domReference;

        this.dom.restrictContentList = document.querySelectorAll(
            ".restrict-content[data-restrict]"
        );

        this.dom.mainContent = document.body.querySelector(".main-container");

        this.dom.restrictContentOverlay = document.body.querySelector(
            ".restrict-content__overlay"
        );

        this.data.endpoint = this.dom.container.dataset.endpoint;

        this.activeMainClass = "main-container--show-overlay";

        onReady(() => this.initialize());
    }

    signUp = () => {
        const membershipElement = document.querySelector(
            "[data-module='membership']"
        );
        if (membershipElement) {
            new Membership(membershipElement, membershipStartPages.SIGNUP);
        }
    };

    logIn = () => {
        const membershipElement = document.querySelector(
            "[data-module='membership']"
        );
        if (membershipElement) {
            new Membership(membershipElement, membershipStartPages.LOGIN);
        }
    };

    spawnLogin = () => {
        const externalUser =
            hasClass(document.body, "logged-in") &&
            !hasClass(document.body, "logged-in--internal");

        if (this.dom.restrictContentOverlay) {
            removeClass(this.dom.restrictContentOverlay, "hidden");
        } else {
            const overlayMarkup = parseHTML(`
            <div class="restrict-content__overlay">
                <div class="restrict-content__overlay-inner">
                    <div class="restrict-content__close">
                        <svg xmlns="http://www.w3.org/2000/svg" focusable="false" width="31" height="31" viewBox="0 0 31 31">
                            <g fill="none" fill-rule="evenodd">
                                <g fill="#FFF">
                                    <path d="M9.545 0H11.454V21H9.545z" transform="rotate(-45 19.036 6.964)"></path>
                                    <path d="M9.545 0L11.455 0 11.455 21 9.545 21z" transform="rotate(-45 19.036 6.964) rotate(90 10.5 10.5)"></path>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div class="restrict-content__content">
                        <h2 class="restrict-content__title margin__bottom--sm">${
                            externalUser
                                ? this.data.response.externalTitle
                                    ? this.data.response.externalTitle
                                    : ""
                                : this.data.response.title
                                ? this.data.response.title
                                : ""
                        }</h2>
                        ${
                            externalUser
                                ? ""
                                : `<div class="restrict-content__buttons">
                            <button class="button button--secondary button--secondary-white restrict-content__login">
                                ${
                                    this.data.response.loginButtonLabel
                                        ? this.data.response.loginButtonLabel
                                        : ""
                                }
                            </button>
                            <button class="button button--link button--link-white restrict-content__signup">${
                                this.data.response.signupButtonLabel
                                    ? this.data.response.signupButtonLabel
                                    : ""
                            }</button>
                        </div>`
                        }

                        <div class="restrict-content__factbox">
                            ${
                                externalUser
                                    ? this.data.response.externalText
                                        ? this.data.response.externalText
                                        : ""
                                    : this.data.response.text
                                    ? this.data.response.text
                                    : ""
                            }
                        </div>
                    </div>
                </div>
            </div>`);

            appendElement(overlayMarkup, document.body);

            this.dom.restrictContentOverlay = document.body.querySelector(
                ".restrict-content__overlay"
            );
        }

        anime({
            targets: this.dom.restrictContentOverlay,
            opacity: [0, 1],
            easing: STANDARDCUBICBEZIER,
            duration: MEDIUM,
            before: () => {
                setScrollLock();
                addClass(this.dom.mainContent, this.activeMainClass);

                onClickOutside(
                    this.dom.restrictContentOverlay,
                    ({ target }) => {
                        // Only close when clicking on overlay and not popups like the cookie banner
                        if (target.tagName === "BODY") {
                            this.closeOverlay();
                        }
                    },
                    window,
                    false
                );
            }
        });
    };

    closeOverlay = () => {
        anime({
            targets: this.dom.restrictContentOverlay,
            opacity: [1, 0],
            easing: STANDARDCUBICBEZIER,
            duration: MEDIUM,
            complete: () => {
                addClass(this.dom.restrictContentOverlay, "hidden");
                removeClass(this.dom.mainContent, this.activeMainClass);
                removeScrollLock();

                removeOnClickOutside(this.dom.restrictContentOverlay);
            }
        });
    };

    updateRestrictionText = (nodeList = this.dom.restrictContentList) => {
        // Setting the text to match the dictionary one from Sitecore
        forEach(nodeList, restrictNode => {
            const forAramconsNode = restrictNode.querySelector(
                ".restrict-content__indication p"
            );

            if (forAramconsNode) {
                forAramconsNode.innerText = this.data.response.forAramconLabel;
            }
        });
    };

    setupEvents = () => {
        addEvent(this.dom.restrictContentList, "click", event => {
            event.preventDefault();
            this.data.targetHref = event.target.dataset.href;

            this.spawnLogin();
        });

        delegateEvent(".restrict-content__close", "click", this.closeOverlay);

        // eslint-disable-next-line no-unused-vars
        delegateEvent(".restrict-content__login", "click", e => {
            // Pushing the URL to be entered when logged in successfully
            window.history.pushState("", "", this.data.targetHref);

            if (document.querySelector(".is-app")) {
                if (window.location.search.includes("?download=")) {
                    window.location = `aramcolife://openlogin?url=${encodeURIComponent(
                        `${window.location.origin}${window.location.pathname}`
                    )}?download=${encodeURIComponent(
                        this.data.targetHref.split("=")[1]
                    )}`;
                } else {
                    window.location = `aramcolife://openlogin?url=${encodeURIComponent(
                        this.data.targetHref
                    )}`;
                }
            } else {
                this.logIn();
            }
        });

        // eslint-disable-next-line no-unused-vars
        delegateEvent(".restrict-content__signup", "click", event => {
            if (document.querySelector(".is-app")) {
                window.location = "aramcolife://opensignup";
            } else {
                this.signUp();
            }
        });
    };

    initialize() {
        // Do we have any nodes with the data-restrict attribute?
        if (this.dom.restrictContentList.length) {
            fetcher(this.data.endpoint).then(({ data }) => {
                this.data.response = data;

                this.updateRestrictionText();

                // Only if we are NOT logged in as internal we setup the actual events
                if (!hasClass(document.body, "logged-in--internal")) {
                    this.setupEvents();
                }
            });
        }
    }
}
